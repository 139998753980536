import React from "react"
import styles from "./Footer.module.scss"
import { adminLogout } from "../../../services/admin/actions"
import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { HOME_PAGE } from "../../../core/constants"
import Clock from "../Clock/Clock"
interface FooterProps {
  admin: boolean
  adminLogout: () => void
}

const Footer: React.FC = (props: any & FooterProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div className={styles.Footer} data-testid="Footer">
      <img
        src="/images/logo_u.svg"
        height="40px"
        alt=""
        onClick={() => {
          dispatch({ type: "RESET_STORE" })
          navigate(HOME_PAGE)
        }}
      />
      <span style={{ textAlign: "center" }}>
        <Clock />
      </span>
    </div>
  )
}

export const mapStateToProps = (state: any) => {
  return {
    admin: !!state.client.clinic,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    adminLogout: () => dispatch(adminLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
