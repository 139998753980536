import React, { Component } from "react"
import styles from "./Clock.module.scss"
import { getTime } from "../../../lib/utils"

interface ClockState {
  date: Date
}
class Clock extends Component<any & ClockState> {
  state = {
    date: new Date(),
  }
  intervalID: any = null

  componentDidMount = () => {
    this.intervalID = setInterval(() => this.tick(), 60000)
  }

  tick = () => {
    this.setState({
      date: new Date(),
    })
  }
  render = () => (
    <div className={styles.Clock} data-testid="clock">
      {getTime(this.state.date)}
    </div>
  )
  componentWillUnmount = () => {
    if (this.intervalID) clearInterval(this.intervalID)
  }
}

export default Clock
