import React, { ComponentType, Fragment } from "react"
import { Card } from "antd"
import styles from "./Hocs.module.scss"

function withCard<T>(WrappedComponent: ComponentType<T>, title: string = "") {
  return (props: any) => (
    <Fragment>
      <Card className={styles.Card}>
        <WrappedComponent {...props} />
      </Card>
    </Fragment>
  )
}

export default withCard
