import React from "react"
import styles from "./../Errors.module.scss"
import { Layout, Button } from "antd"
import { buttonLayout } from "../../../styles/constants"
import { HOME_PAGE } from "../../../core/constants"
import { useNavigate } from "react-router"

const NotFound: React.FC<any> = () => {
  const navigate = useNavigate()
  return (<Layout
    style={{
      height: "100vh",
      backgroundColor: "#1b4f63",
      cursor: "pointer",
      textAlign: "center",
    }}
  >
    <div className={styles.NotFound} data-testid="Home">
      <img
        className={styles.Logo}
        src="/images/logo_u.svg"
        alt="Logo de Medadom"
      />
      <h1 className={styles.Title}>Oups - Cette page n'existe pas :S</h1>
      <Button {...buttonLayout} onClick={() => navigate(HOME_PAGE)}>
        Revenir
      </Button>
    </div>
  </Layout>
)
}

export default NotFound
