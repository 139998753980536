import { Modal as AntdModal, ButtonProps, Col, Row } from "antd"
import React, { useState } from "react"

import SecretaryPicto from "../../../assets/icons/picto_accueil.svg"
import mailPicto from "../../../assets/images/mail_picto.svg"
import redCross from "../../../assets/images/red_cross.svg"
import shieldSVG from "../../../assets/images/shield.svg"
import { languages } from "../../../lib/languages"
import { buttonLayout } from "../../../styles/constants"
import { Button } from "../Button"
import { Buttons } from "../Card/Card"
import PreviousButton from "../Previous/PreviousButton"
import { Title } from "../Title/Title"
import styles from "./Modal.module.scss"
import WarningPicto from '../../../assets/images/attention_picto.svg';

import DOMPurify from "dompurify";

export interface ModalProps {
  visible: boolean
  title?: any
  onAccept?: () => void
  onCancel?: () => void
  onClose?: () => void
  acceptText?: string
  cancelText?: string
  loading?: boolean
  closable?: boolean
  noFooter?: boolean
  children?: JSX.Element
  footer?: JSX.Element
  contentText?: string
  acceptButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  style?: any
}
export const Modal: React.FC<ModalProps> = (props): JSX.Element => {
  const title = props.closable ? (
    <div>
      <PreviousButton
        text={languages.close}
        onClick={() => {
          if (props.onClose) props.onClose()
          else if (props.onCancel) props.onCancel()
        }}
      />

      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Title text={props.title} />
        </Col>
      </Row>
    </div>
  ) : (
    <Title text={props.title} />
  )
  return (
    <AntdModal
      width="100%"
      className={styles.Modal}
      centered
      title={title}
      closable={false}
      open={props.visible}
      footer={
        props.footer ? (
          props.footer
        ) : !props.noFooter ? (
          <Buttons>
            {props.onCancel && (
              <Button
                wide="long"
                type="link"
                onClick={props.onCancel}
                {...props.cancelButtonProps}
              >
                {props.cancelText || languages.previous}
              </Button>
            )}
            {props.onAccept && (
              <Button
                wide="long"
                loading={props.loading}
                disabled={props.loading}
                onClick={props.onAccept}
                {...props.acceptButtonProps}
              >
                {props.acceptText || languages.confirm}
              </Button>
            )}
          </Buttons>
        ) : (
          <></>
        )
      }
    >
      {props.children}
    </AntdModal>
  )
}

const hightHeightModal = "1422px"

const NoEmailModalTitle = ({ onCancel }: { onCancel: Function }) => (
  <div>
    <div style={{ textAlign: "right" }}>
      <img
        src={redCross}
        alt={languages.close}
        onClick={() => onCancel()}
        className={`${styles.redCrossModal}`}
      />
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img
          src={mailPicto}
          alt="mail_picto"
          style={{ height: "90px", marginBottom: "50px" }}
        />
      </div>
      <div>
        <h2 className={`${styles.consentModalTitle}`}>
          {languages.noEmailTitleModal}
        </h2>
      </div>
    </div>
  </div>
)

export const NoEmailModal = ({
  visible,
  onCancel,
  onAccept,
}: {
  visible: boolean
  onAccept: Function
  onCancel: Function
}) => {
  const [accepted, setAccepted] = useState(false)
  return (
    <AntdModal
      width={hightHeightModal}
      style={{ marginBottom: "100px" }}
      className={styles.Modal}
      centered
      title={<NoEmailModalTitle onCancel={onCancel} />}
      open={visible}
      footer={
        <div>
          <div
            className={`card-contenant font-size-large ${styles.footerNoEmail}`}
          >
            <input
              className={`${styles.footerNoEmailInput}`}
              type="checkbox"
              id="noEmail"
              name="noEmail"
              required
              onClick={() => setAccepted(!accepted)}
            />
            <label htmlFor="noEmail" className={`${styles.noEmailLabel}`}>
              {languages.IHaveNoEmail}
            </label>
            <br />
            <span className={`${styles.noEmailSpan}`}>
              {languages.checkNoEmail}
            </span>
          </div>
          <div className={`${styles.acceptNoEmail}`}>
            <Button
              {...buttonLayout}
              danger
              type="primary"
              onClick={() => {
                if (accepted) {
                  onAccept()
                } else {
                  onCancel()
                }
              }}
            >
              {languages.continue}
            </Button>
          </div>
        </div>
      }
      closable={false}
    >
      <div
        className="card-contenant font-size-large noEmailModalBody"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(languages.noEmailContentModal, { USE_PROFILES: { html: true } }) }}
      ></div>
    </AntdModal>
  )
}

const ConsentModalTitle = ({ onCancel }: { onCancel: Function }) => (
  <div style={{ textAlign: "right" }}>
    <img
      src={redCross}
      alt={languages.close}
      onClick={() => onCancel()}
      className={`${styles.redCrossModal}`}
    />
  </div>
)

const mediumHeightModal = "1364px"

const InfoModalTitle = ({
  onCancel,
  title,
}: {
  onCancel: Function
  title: string
}) => (
  <div>
    <div style={{ textAlign: "right" }}>
      <img
        src={redCross}
        alt={languages.close}
        onClick={() => onCancel()}
        className={`${styles.redCrossModal}`}
      />
    </div>
  </div>
)

export const InfoModal = ({
  visible,
  onCancel,
  onAccept,
  acceptText,
  onAltAccept,
  altAcceptText,
  title = "",
  message = "",
  picto,
}: {
  visible: boolean
  onCancel: Function
  onAccept?: Function
  acceptText?: string
  onAltAccept?: Function
  altAcceptText?: string
  title?: string
  message?: string
  picto?: any
}) => (
  <AntdModal
    width={mediumHeightModal}
    title={<InfoModalTitle onCancel={onCancel} title={title} />}
    centered
    open={visible}
    footer={
      <div className={`card-contenant font-size-large ${styles.footerNoEmail}`}>
        <div className={`${styles.acceptInfoModal}`}>
          {onAccept && (
            <Button
              {...buttonLayout}
              style={{ width: "375px" }}
              type="primary"
              onClick={() => onAccept()}
            >
              {acceptText || languages.continue}
            </Button>
          )}
        </div>
        <div className={`${styles.acceptInfoModal}`}>
          {onAltAccept && (
            <span
              style={{
                textDecoration: "underline",
                textDecorationSkipInk: "none",
              }}
              className={`${styles.leaveLinkConsent}`}
              onClick={() => onAltAccept()}
            >
              {languages.iAmMinor}
            </span>
          )}
        </div>
      </div>
    }
    closable={false}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <h2 className={`${styles.consentModalTitle}`}>{title}</h2>
      </div>
    </div>{" "}
    <div className={styles.ModalInfoWrapper}>
      <img
        src={picto || SecretaryPicto}
        alt="secretary_picto"
        style={{ height: "177px" }}
      />
      <div
        className={"card-contenant font-size-large modal-info-content"}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message, { USE_PROFILES: { html: true } }) }}
      ></div>
    </div>
  </AntdModal>
)

export const openPrivacyModal = (type: string) => {
  const iframeSrc = type === 'cgu' ? "https://info.medadom.com/cgu" : "https://info.medadom.com/politique-de-confidentialite";
  AntdModal.info({
    content: (
      <div style={{ height: "500px" }}>
        <iframe src={iframeSrc} title={type} style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
      </div>
    ),
    title: type === 'cgu' ? "Conditions générales d'utilisation" : "Données personnelles",
    width: mediumHeightModal,
    bodyStyle: { height: "780px" },
  })
}

export const ConsentModal = ({
  visible,
  loading,
  onCancel,
  onAccept,
}: {
  loading: boolean
  visible: boolean
  onAccept: Function
  onCancel: Function
}) => (
  <AntdModal
    width={mediumHeightModal}
    title={<ConsentModalTitle onCancel={onCancel} />}
    className={styles.Modal}
    centered
    open={visible}
    footer={
      <div className={`${styles.consentModalWrappedButtons}`}>
        <a className={`${styles.leaveLinkConsent}`} onClick={() => onCancel()}>
          {languages.IRefuseConsent}
        </a>
        <Button
          loading={loading}
          disabled={loading}
          {...buttonLayout}
          type="primary"
          onClick={() => onAccept()}
        >
          {languages.IAcceptConsent}
        </Button>
      </div>
    }
    closable={false}
  >
    <div className={styles.FlexRowWrapper}>
      <h2 className={`${styles.consentModalTitle}`}>
        {languages.consentTitleModal}
      </h2>
      <div className={`${styles.consentModalBody}`}>
        <img src={shieldSVG} className={styles.ImgModal} alt="shield.svg" />
        <div className={`${styles.ContentModal} font-size-large`}>
          J'ai lu et j'accepte{" "}
          <a
            onClick={() => {
              AntdModal.info({
                content: (
                  <div style={{ height: "500px" }}>
                    <iframe src="https://info.medadom.com/cgu" title="conditions générales d'utilisation" style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
                  </div>
                ),
                title: "Condition générales d'utilisation",
                width: mediumHeightModal,
                bodyStyle: { height: "780px" },
              })
            }}
          >
            les conditions générales d'utilisation (CGU){" "}
          </a>
          <br /> et la{" "}
          <a onClick={() => openPrivacyModal('privacy')}>
            politique de gestion des données personnelles
          </a>{" "}
          de Medadom
        </div>
      </div>
    </div>
  </AntdModal>
)

export const GenericModal: React.FC<ModalProps> = (props): JSX.Element => (
  <Modal
    {...props}
    footer={
      props.footer ? (
        props.footer
      ) : !props.noFooter ? (
        <Buttons>
          {props.onCancel && (
            <Button
              // wide="long"
              {...props.cancelButtonProps}
              onClick={() => props.onCancel && props.onCancel()}
            >
              {props.cancelText || languages.cancel}
            </Button>
          )}
          {props.onAccept && (
            <Button
              // wide="long"
              danger
              type="primary"
              {...props.acceptButtonProps}
              onClick={() => props.onAccept && props.onAccept()}
            >
              {props.acceptText}
            </Button>
          )}
        </Buttons>
      ) : undefined
    }
  >
    <div className="modal-contenant font-size-large">
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.contentText as any, { USE_PROFILES: { html: true } }) || "",
        }}
      ></div>
      {props.children}
    </div>
  </Modal>
)

export const AddPseudoNameModal = (props: any) => {
  const TitleBlock = (
    <div className={styles.title__block}>
      <div style={{ textAlign: 'right', width: '100%' }}>
        <img
          src={redCross}
          alt={languages.close}
          className={`${styles.redCrossModal}`}
          onClick={props.onClose}
        />
      </div>
      <div className={styles.title__icon}>
        <img src={WarningPicto} alt="warning image" style={{ paddingTop: '20px' }} />

      </div>
      <h1 className={styles.title__text}
      >Vous pouvez ajouter un <b>{props.fieldName} utilisé au quotidien, différent</b> de votre <b>{props.fieldName} de naissance</b>
      </h1>
    </div>
  )
  return (
    <AntdModal
      closable={false}
      footer={<></>}
      centered
      width={"1180px"}
      bodyStyle={{ padding: 0 }}
      title={TitleBlock}
      open={props.visible}

    > <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', paddingTop: '15px' }}><Button type="link" onClick={props.onClose} ><u style={{ fontSize: '28px' }}> {languages.cancel} </u></Button> <Button onClick={props.onAccept}> Ajouter un {props.fieldName} utilisé</Button></div> </AntdModal>
  )
}