import { AUTH_STEP, AuthType } from "../../pages/Authentication/Auth"
import { AuthStore } from "../../types/types"
import { CREATE_ACCOUNT, CREATE_ACCOUNT_ERROR, CREATE_ACCOUNT_SUCCESS, MANUAL_AUTHENTICATION, RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR, RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE, RETRIEVE_ACCOUNT_BY_NAME_AND_NIR, RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE, RETRIEVE_ACCOUNT_ERROR, RETRIEVE_ACCOUNT_PREVIOUS_STEP, RETRIEVE_ACCOUNT_SUCCESS } from "./constants"

export interface AuthenticationStore extends AuthStore {
  authType: AuthType
  step: AUTH_STEP
  error?: {}
}
const initialState: AuthenticationStore = {
  success: false,
  loading: false,
  authType: { type: "UNKNOWN", value: "" },
  step: AUTH_STEP.BIRTHDATE,
}

const reducer = function adminReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  let newState = { ...state }
  switch (action.type) {
    case CREATE_ACCOUNT:
      return { ...newState, loading: true }
    case CREATE_ACCOUNT_SUCCESS:
    case CREATE_ACCOUNT_ERROR:
      return { ...newState, loading: false }
    case MANUAL_AUTHENTICATION:
      return { ...newState, authType: action.payload }
    case RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR:
    case RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE:
    case RETRIEVE_ACCOUNT_BY_NAME_AND_NIR:
    case RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE:
      return { ...newState, loading: true }
    case RETRIEVE_ACCOUNT_ERROR:
      let step = newState.step
      if (newState.step === AUTH_STEP.BIRTHDATE) step = AUTH_STEP.LASTNAME
      else if (newState.step === AUTH_STEP.LASTNAME)
        step = AUTH_STEP.AUTH_FAILED

      return {
        ...newState,
        step,
        error: action.payload,
        success: false,
        loading: false,
      }
    case RETRIEVE_ACCOUNT_PREVIOUS_STEP:
      if (newState.step === AUTH_STEP.LASTNAME)
        newState.step = AUTH_STEP.BIRTHDATE
      if (newState.step === AUTH_STEP.AUTH_FAILED)
        newState.step = AUTH_STEP.LASTNAME
      return { ...newState }
    case RETRIEVE_ACCOUNT_SUCCESS:
      return { ...newState, success: true, loading: false }
    default:
      return newState
  }
}

export default reducer
