export const SAVE_CONFIGURATION_ID = "SAVE_CONFIGURATION_ID"
export const SET_SOCKET = "SET_SOCKET"
export const SOCKET_CANCEL_CARD_VITALE_READER =
  "SOCKET_CANCEL_CARD_VITALE_READER"
export const SOCKET_ERROR_CARD_VITALE_READER = "SOCKET_ERROR_CARD_VITALE_READER"
export const SOCKET_EMIT_MESSAGE = "SOCKET_EMIT_MESSAGE"
export const SOCKET_INCOMING_PAYLOAD = "SOCKET_INCOMING_PAYLOAD"
export const SOCKET_IN_OPEN_STATE = "SOCKET_IN_OPEN_STATE"
export const SOCKET_LAUNCH_WORKFLOW = "SOCKET_LAUNCH_WORKFLOW"
export const SOCKET_LAUNCH_CARD_VITALE_READER =
  "SOCKET_LAUNCH_CARD_VITALE_READER"
export const SOCKET_REMOVE_CARD_VITALE_READER =
  "SOCKET_REMOVE_CARD_VITALE_READER"
export const SOCKET_REMOVED_CARD_VITALE_READER =
  "SOCKET_REMOVED_CARD_VITALE_READER"
export const SOCKET_RESET_CARD_VITALE_READER = "SOCKET_RESET_CARD_VITALE_READER"
export const SOCKET_RETRY_CONNECT_DECREMENT_RETRY =
  "SOCKET_RETRY_CONNECT_DECREMENT_RETRY"
export const SOCKET_RETRY_CONNECT_RESET = "SOCKET_RETRY_CONNECT_RESET"
export const SOCKET_SEND_CANCELED_CALL = "SOCKET_SEND_CANCELED_CALL"
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE"

export const SOCKET_SUBSCRIBE_CLIENT = "SOCKET_SUBSCRIBE_CLIENT"
export const SOCKET_INIT_CARD_VITALE_READER = "SOCKET_INIT_CARD_VITALE_READER"
export const SOCKET_NIR_READER_RESPONSE = "SOCKET_NIR_READER_RESPONSE"

export const SOCKET_ERROR_UNREACHABLE = "SOCKET_ERROR_UNREACHABLE"

export const SOCKET_LOG_TERMINAL_INFORMATION = "SOCKET_LOG_TERMINAL_INFORMATION"
export const SOCKET_SEND_TERMINAL_INFORMATION = "SOCKET_SEND_TERMINAL_INFORMATION"
