/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Row, Col, Input } from "antd"
import { useForm } from "antd/lib/form/Form"
import React, { useEffect, useState } from "react"
import { Subject } from "rxjs"

import { languages } from "../../lib/languages"
import { Gender, Prospect } from "../../types/types"
import { Button } from "../new/Button"
import { Buttons, Card } from "../new/Card/Card"
import { FormPage } from "../new/Form/Form"
import PreviousButton from "../new/Previous/PreviousButton"
import { InputNames } from "types/new/types/props"
import { labelFromName, getNameOfCurrentInput } from "components/new/Form/utils"
import { GetItemForm } from "../new/Form/Form"
import BtnLocked from '../../assets/icons/btn_locked.svg'
import BtnAdd from '../../assets/icons/add_btn.svg';
import BtnEdit from '../../assets/icons/pencil.png';
import { AddPseudoNameModal, GenericModal } from "components/new/Modal/Modal"
import { Keyboard } from "components/hocs/withKeyboard"
import { searchCityFullNameWithInseeCode } from '../Input/BirthLocation/Index';


interface FormReviewProps {
  initialValues: Partial<Prospect>
  onFinish: (values: any) => void
  onCancel: () => void
  error?: any
  loading: boolean
  modifyWarning?: any
  biRef: any
  onChange?: (vals: any) => {}
  // showWarning?: boolean
}



const ChangeIdentityInfoModal = ({ onClose, onAccept, visible, fieldName }: { onClose?: () => void, onAccept?: () => void, visible: boolean, fieldName: string }) => {
  return (
    <AddPseudoNameModal
      visible={visible}
      onClose={onClose}
      onAccept={onAccept}
      fieldName={fieldName}
    >
    </AddPseudoNameModal>
  )
}

const FormIdentityModal = ({ visible, formRef, onAccept, inputName, onClose, modify = false }: { visible: boolean, formRef: any, onAccept: () => void, inputName: string, onClose: () => void, modify?: boolean }) => {
  const [currentValue, setValue] = useState(formRef.getFieldValue(inputName));
  const keyboardEvent = new Subject<string>()
  return (
    <GenericModal visible={visible} onClose={onClose} closable title={<div style={{ marginBottom: '0px' }}> {modify ? "Modifier le" : "Ajouter un"} {languages[getNameOfCurrentInput(inputName)]}</div>}
    ><div>
        <Keyboard
          form={formRef}
          value={formRef.getFieldValue(inputName)}
          event={keyboardEvent}
          onChange={(value: string) => {
            formRef.setFieldValue(inputName, value);
            setValue(value)
          }}
          options={{
            type: getNameOfCurrentInput(inputName),
            inputName: getNameOfCurrentInput(inputName),
          }}
        ><GetItemForm name={inputName} formRef={formRef} visible /></Keyboard><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '45px' }} ><Button onClick={onAccept}>{modify ? "Modifier" : "Ajouter"}</Button></div></div></GenericModal>
  )
}

export const FormReview = (props: FormReviewProps): JSX.Element => {
  const [formRef] = useForm()
  const [nirDataConfirmed, setNirDataConfirmed] = useState(false)
  const [showModifyWarning, setShowModifyWarning] = useState(false)
  const [prospect, setProspect] = useState({} as Prospect)
  const [showModalInfoBirthLastname, setShowModalInfoBirthLastname] = useState(false);
  const [showModalModifyBirthLastname, setShowModalModifyBirthLastname] = useState(false);
  const [showModalInfoFirstBirthFirstName, setShowModalInfoFirstBirthFirstName] = useState(false);
  const [showModalModifyFirstBirthFirstName, setShowModalModifyFirstBirthFirstName] = useState(false);
  const [firstNamePseudoIsSet, setFirstNamePseudoIsSet] = useState(false)
  const [lastNamePseudoIsSet, setLastNamePseudoIsSet] = useState(false)

  const inputs = [
    {
      name: "email",
      required: true,
      label: languages.provideYourMail,
      default: {
        warning: true,
        value: "noemail@medadom.com",
        label: languages.byPassEmail,
      },
    },
    {
      name: "new-password",
      label: languages.provideYourPassword,
      required: true,
    },
  ]

  useEffect(() => {
    // should be updated after the patient edit a value
    const formatProspect = {
      ...props.initialValues,
      birthdate: props.initialValues?.birthdate?.replaceAll("/", ""),
      sex:
        props.initialValues.sex &&
          [Gender.MALE, Gender.FEMALE].includes(props.initialValues.sex)
          ? props.initialValues.sex
          : props.initialValues.gender &&
            [Gender.MALE, Gender.FEMALE].includes(props.initialValues.gender)
            ? props.initialValues.gender
            : Gender.MALE,
    }

    setProspect(formatProspect)
    formRef.setFieldsValue({
      ...formatProspect,
    })

    if (/^\d+$/.test(formatProspect.insee_code as string)) {
      searchCityFullNameWithInseeCode(formatProspect.insee_code as string, formatProspect.birthdate).then(
        res => {
          if (res && res.status === "ok") {
            const birthLocationDisplayedValue = `${res.locations[0].name} (${formatProspect.insee_code})`
            formRef.setFieldValue(InputNames.BIRTH_LOCATION, birthLocationDisplayedValue)
            setProspect(p => ({
              ...p,
              birth_location: res.locations[0].name
            }))
          } else {
            formRef.setFieldValue(InputNames.BIRTH_LOCATION, `${languages.unknown} (${formatProspect.insee_code})`);
            setProspect(p => ({
              ...p,
              birth_location: ""
            }))
          }
        }
      ).catch((error) => console.error(error, { path: "locations/public/search" }))
    }
  }, [props.initialValues])

  useEffect(() => {

  }, [])

  const closeWarningModal = () => {
    setShowModifyWarning(false)
  }
  props.biRef.closeWarning = closeWarningModal

  if (!nirDataConfirmed) {
    if (props.modifyWarning && showModifyWarning) return props.modifyWarning
    else
      return (
        <Card title={languages.verifyYourInformations}>
          <PreviousButton onClick={() => props.onCancel()} />
          <Form
            layout="vertical"
            style={{ width: "1200px" }}
            form={formRef}
            onFinish={(values) => {
              setProspect({ ...prospect, ...values })
              setNirDataConfirmed(true)
            }}
          >
            <Row gutter={[16, 16]} style={{ display: "flex", marginBottom: 20, minWidth: '884px' }}>
              <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                {`${languages.gender} :`}
              </Col>
              <Col span="16">
                <Input
                  disabled
                  value={`${formRef.getFieldValue('sex') === Gender.MALE ? languages.male.toUpperCase() : languages.female.toUpperCase()}`}
                  style={{ maxWidth: '870px', border: 0, color: "#7A7A7A" }}
                />
              </Col>
              <Col span="1">
                <div style={{ cursor: "pointer" }} >
                  <img height="70px" src={BtnLocked} alt="icon locked" />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
              <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                {`${labelFromName(InputNames.FIRST_BIRTH_FIRSTNAME)} :`}
              </Col>
              <Col span="16">
                <GetItemForm
                  name={getNameOfCurrentInput(InputNames.FIRST_BIRTH_FIRSTNAME)}
                  visible={true}
                  disabled
                  readOnly
                />
              </Col>
              <Col span="1">
                <img src={firstNamePseudoIsSet ? BtnLocked : BtnAdd} style={{ height: '70px', display: "flex", flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => setShowModalInfoFirstBirthFirstName(true)} alt="edit_button" />
              </Col>
              {
                showModalInfoFirstBirthFirstName &&
                <ChangeIdentityInfoModal visible={showModalInfoFirstBirthFirstName} onClose={() => setShowModalInfoFirstBirthFirstName(false)} onAccept={() => {
                  setShowModalInfoFirstBirthFirstName(false);
                  setShowModalModifyFirstBirthFirstName(true);
                }}
                  fieldName="prénom"
                />
              }
              {
                showModalModifyFirstBirthFirstName && <FormIdentityModal visible={showModalModifyFirstBirthFirstName} formRef={formRef} onAccept={() => { setShowModalModifyFirstBirthFirstName(false); setFirstNamePseudoIsSet(true); }} onClose={() => {
                  setShowModalModifyFirstBirthFirstName(false); setShowModalInfoFirstBirthFirstName(true);
                }} inputName={InputNames.FIRSTNAME} modify={firstNamePseudoIsSet} />
              }
            </Row>
            {firstNamePseudoIsSet && (
              <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
                <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                  {`${labelFromName(InputNames.FIRSTNAME)} :`}
                </Col>
                <Col span="16">
                  <GetItemForm
                    name={getNameOfCurrentInput(InputNames.FIRSTNAME)}
                    visible={true}
                    disabled
                    readOnly
                  />
                </Col>
                <Col span="1">
                  <div style={{ cursor: "pointer" }} >
                    <button style={{ height: '70px', display: "flex", flexDirection: 'row', alignItems: 'center', padding: '15px', gap: '15px', fontSize: '24px', color: "#4A4A48", fontWeight: '500' }} type="button" onClick={() => setShowModalModifyFirstBirthFirstName(true)}><img src={BtnEdit} alt="edit_button" /> MODIFIER</button>
                  </div>
                </Col>
              </Row>)
            }
            <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
              <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                {`${labelFromName(InputNames.BIRTH_LASTNAME)} :`}
              </Col>
              <Col span="16">
                <GetItemForm
                  name={getNameOfCurrentInput(InputNames.BIRTH_LASTNAME)}
                  visible={true}
                  readOnly
                  disabled
                />
              </Col>
              <Col span="1">
                <img src={lastNamePseudoIsSet ? BtnLocked : BtnAdd} style={{ height: '70px', display: "flex", flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => setShowModalInfoBirthLastname(true)} alt="edit_button" />
              </Col>
              {
                showModalInfoBirthLastname &&
                <ChangeIdentityInfoModal visible={showModalInfoBirthLastname} onClose={() => setShowModalInfoBirthLastname(false)} onAccept={() => {
                  setShowModalInfoBirthLastname(false);
                  setShowModalModifyBirthLastname(true);
                }}
                  fieldName="nom"

                />
              }
              {
                showModalModifyBirthLastname && <FormIdentityModal visible={showModalModifyBirthLastname} formRef={formRef} onAccept={() => { setShowModalModifyBirthLastname(false); setLastNamePseudoIsSet(true); }} onClose={() => {
                  setShowModalModifyBirthLastname(false); setShowModalInfoBirthLastname(true);
                }} inputName={InputNames.LASTNAME} modify={lastNamePseudoIsSet} />
              }
            </Row>
            {lastNamePseudoIsSet && (
              <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
                <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                  {`${labelFromName(InputNames.LASTNAME)} :`}
                </Col>
                <Col span="16">
                  <GetItemForm
                    name={getNameOfCurrentInput(InputNames.LASTNAME)}
                    visible={true}
                    readOnly
                    disabled
                  />
                </Col>
                <Col span="1">
                  <div style={{ cursor: "pointer" }} >
                    <button style={{ height: '70px', display: "flex", flexDirection: 'row', alignItems: 'center', padding: '15px', gap: '15px', fontSize: '24px', color: "#4A4A48", fontWeight: '500' }} type="button" onClick={() => setShowModalModifyBirthLastname(true)}><img src={BtnEdit} alt="edit_button" /> MODIFIER</button>                  </div>
                </Col>
              </Row>)
            }
            <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
              <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                {`${labelFromName(InputNames.BIRTHDATE)} :`}
              </Col>
              <Col span="16">
                <GetItemForm
                  name={getNameOfCurrentInput(InputNames.BIRTHDATE)}
                  visible={true}
                  disabled
                  readOnly
                />
              </Col>
              <Col span="1">
                <div style={{ cursor: "pointer" }} >
                  <img height="70px" src={BtnLocked} alt="icon locked" />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ display: "flex", minWidth: '884px' }}>
              <Col span="7" style={{ width: '480px', textAlign: 'end', color: '#27224F', fontWeight: 600, fontSize: '24px', alignSelf: 'center' }}>
                {`${labelFromName(InputNames.BIRTH_LOCATION)} :`}
              </Col>
              <Col span="16">
                <GetItemForm
                  name={getNameOfCurrentInput(InputNames.BIRTH_LOCATION)}
                  visible={true}
                  disabled
                  readOnly
                />
              </Col>
              <Col span="1">
                <div style={{ cursor: "pointer" }} >
                  <img height="70px" src={BtnLocked} alt="icon locked" />
                </div>
              </Col>
            </Row>
            <Buttons>
              <Button htmlType="submit">{languages.continue}</Button>
            </Buttons>
          </Form>
        </Card>
      )
  } else {
    return (
      <Card message={props.error}>
        <FormPage
          loading={props.loading}
          inputs={inputs}
          acceptButton={languages.register}
          onCancel={() => {
            setNirDataConfirmed(false)
          }}
          onFinish={(values) => {
            setProspect({ ...prospect, ...values })
            props.onFinish({ ...prospect, ...values })
          }}
        />
      </Card>
    )
  }
}
