import React from "react";
import config from "react-global-configuration"
import { Modal } from "antd"
import { languages } from "../../lib/languages"
import { useDispatch } from "react-redux"
import { HOME_PAGE } from "../../core/constants"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"

function warning(dispatch: any, navigate: any) {
    let Initialseconds = config.get("disconnectTimer.seconds") || 30
    let InitialMinutes = config.get("disconnectTimer.minutes") || 1
    let secondPassed = 0
    let minutePassed = 0

    const modal = Modal.warning({
      title: languages.inactivity,
      content: <div>{`${InitialMinutes} m ${Initialseconds} ...`}</div>,
      bodyStyle: { padding: 25, height: 400 },
      centered: true,
      width: 800,
      className: "inactivity-modal",
      okText: "Continuer",
      okButtonProps: { size: "large", type: "primary", shape: "round" },
    })

    const timer = setInterval(() => {
      const sec = Math.round(Initialseconds - secondPassed)
      const min = Math.round(InitialMinutes - minutePassed)
      modal.update({
        title: languages.inactivity,
        content: <div>{`${min} m ${sec} ...`} </div>,
        bodyStyle: { padding: 25, height: 400, maxHeight: "400px" },
        open: min === 0 && sec === 0 ? false : true,
        onOk: () => clearInterval(timer),
        okText: "Continuer",
        width: 800,
        centered: true,
        className: "inactivity-modal",
        okButtonProps: { size: "large", type: "primary", shape: "round" },
      })
      if (sec > 0) {
        secondPassed++
      } else if (min > 0) {
        Initialseconds = 59
        secondPassed = 0
        minutePassed++
      }
      if (sec === 0 && min === 0) {
        clearInterval(timer)
        dispatch({ type: "RESET_STORE" })
        navigate(HOME_PAGE)
      }
    }, 1000)
  }

const IdleTimer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const idleTimer = useIdleTimer({ onIdle: () => warning(dispatch, navigate), timeout: 60 * 1000 * config.get("timeoutInactivity.minutes") || 15 })
    return (<></>)
}

export default IdleTimer;