import React from "react"
import styles from "./Loading.module.scss"
import { Layout } from "antd"
import Loader from "../../components/generics/Loader/Loader"

const Loading: React.FC = () => (
  <Layout style={{ height: "100vh" }}>
    <div className={styles.Loading} data-testid="Loading">
      <Loader />
    </div>
  </Layout>
)

export default Loading
