import { Form, Select } from "antd"
import React from "react"

import { languages } from "../lib/languages"

export const formLayout: FormLayout = {
  size: "large",
  layout: "vertical",
}

interface FormLayout {
  labelCol?: any
  wrapperCol?: any
  size?: any
  labelAlign?: any
  layout?: any
}
interface FixLayout {
  [key: string]: any
}
export const fixLayout: FixLayout = {
  style: { fontSize: "25px", padding: "15px" },
}

export const buttonLayout: ButtonLayout = {
  shape: "round",
  size: "large",
  style: { minWidth: "250px" },
}

interface ButtonLayout {
  shape?: any
  size?: any
  style?: Object
}
