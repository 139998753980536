import { ComponentType, Fragment, useRef } from "react"
import React from "react"
import { Header, Footer } from "../generics"
import { Layout } from "antd"
import styles from "./Hocs.module.scss"
import IdleTimer  from "../../components/IdleTimer/IdleTimer"

function withHeaderAndFooter<T>(WrappedComponent: ComponentType<T> ) {
  return (props: T) => {
    return (
      <Fragment>
        <Layout className={styles.Layout}>
          <Layout.Header className={styles.Header}>
            <Header />
          </Layout.Header>
          <IdleTimer />
          <Layout.Content className={styles.Content}>
            <WrappedComponent {...props as any} />
          </Layout.Content>
          <Layout.Footer className={styles.Footer}>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Fragment>
    )
  }
}

export default withHeaderAndFooter
