// In order to perform an action of type LOGIN_REQUESTING
import {
  ADMIN_LOGIN_REQUESTING,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
} from "./constants"
import { ResponseError } from "../../types/types"

export const adminLoginRequest = ({
  email,
  password,
}: {
  email: string
  password: string
}) => ({
  type: ADMIN_LOGIN_REQUESTING,
  email,
  password,
})

export const adminLoginError = (
  error: string
): { type: string; payload: ResponseError } => ({
  type: ADMIN_LOGIN_ERROR,
  payload: { message: error },
})

export const adminLoginSuccess = (payload: any) => ({
  type: ADMIN_LOGIN_SUCCESS,
  payload,
})

export const adminLogout = () => ({
  type: ADMIN_LOGOUT,
})
