import { MAINTENANCE } from "../core/constants"
import { ERROR_READER_SOCKET } from "../types/new/types/error"
// because Fetch doesn't recognize error responses as

import { ERROR_API, PayloadError } from "../types/types"
import { languages } from "./languages"

// actual errors since it's technically completing the response...
export async function handleApiErrors(response: any) {
  if (!response.ok) {
    if (response.status === 503 && window.location.pathname !== MAINTENANCE)
      window.location.href = MAINTENANCE
    if (response.status === 429) throw languages.TooManyRequest
    try {
      return response.json().then(getApiError)
    } catch (e) {
      console.error(`unable to process error ${response?.status} : ${response?.statusText}`, {
        route: response?.url,
      })
      throw languages.defaultErrorForm;
    }
  }
  return response
}

export const getApiError = (responseError: PayloadError) => {
  switch (responseError.error) {
    case ERROR_API.MISMATCHING_INFORMATION:
      throw languages.mismatchingErrorForm
    case ERROR_API.PHONE_EXISTING_ERROR:
      throw languages.phoneExistingErrorForm
    case ERROR_API.PAYLOAD_ERROR:
      throw languages.payloadErrorForm
    case ERROR_API.EXISTING_ORDER:
      throw languages.existingOrderError
    case ERROR_API.NIR_LENGTH:
      throw languages.wrongNirLength
    case ERROR_API.NEXMO_ERROR:
      throw languages.nexmoError
    default:
      throw languages.defaultErrorForm
  }
}

export const handleGenericApiError = (response: any) => {
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 404
  ) {
    return response
  } else {
    return handleApiErrors(response)
  }
}

export const formatReaderSocketErrorByType = (errorType: string): string => {
  switch (errorType) {
    case ERROR_READER_SOCKET.GENERAL_ERROR:
      return languages.readerSocketGenericError
    case ERROR_READER_SOCKET.SOFT_CARD_READ_ERROR:
    case ERROR_READER_SOCKET.PCSC_READER_ERROR:
    case ERROR_READER_SOCKET.UNKNOWN_ERROR:
    case ERROR_READER_SOCKET.MUTED_OR_UNKNOWN_CARD_ERROR:
      return languages.readerSocketReadError
    default:
      return languages.readerSocketGenericError
  }
}
