// NIR
export const AUTHENTICATE_FROM_RAW_NIR = "AUTHENTICATE_FROM_RAW_NIR"
export const NIR_ERROR = "NIR_ERROR"
export const FROM_NIR_REQUESTING = "FROM_NIR_REQUESTING"

export const SET_RAW_NIR = "SET_RAW_NIR"
// ADDED FROM KIOSK
export const NIR_READER_RESPONSE = "NIR_READER_RESPONSE"
export const RESET_NIR_READER = "RESET_NIR_READER"
export const REQUEST_NIR_READER = "REQUEST_NIR_READER"
export const CANCEL_NIR_READER = "CANCEL_NIR_READER"

export const SET_NIR_READER_STATE = "SET_NIR_READER_STATE"
export const SET_NIR_READER_LOADING = "SET_NIR_READER_LOADING"
export const SET_NIR_READER_MESSAGE = "SET_NIR_READER_MESSAGE"
export const CALL_API_NIR_READER = "CALL_API_NIR_READER"
