import Types from "./constants";
import { ActionsType } from "./types";

const reducer=(
    manager = {
        consultations: [],
        doctors: [],
    },
    action: ActionsType
)=>{
    switch (action.type) {
        case Types.CLEAN_STORE:
            return {
                ...manager,
                consultations: [],
                doctors: [],
            }
        case Types.SET_CONSULTATIONS:
            return {
              ...manager,
              consultations: action?.payload,
            }
        case Types.DOCTOR_LIST_SUCCESS:
            return {
                ...manager,
                doctors: action.payload
            }
        default:
      return {...manager}
    }

}

export default reducer;