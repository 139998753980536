import React, { useEffect, useState } from "react"
import styles from "./FatButton.module.scss"

interface FatButtonProps {
  onClick: (e?: any) => void
  clicked?: boolean
  img?: string
  type?: string
  smallSize?: boolean
  big?: boolean
  disabled?: boolean
  children?: JSX.Element | JSX.Element[] | string
  subtext?: string
  style?: any
}

export const getStyleText = (text: string) => {
  if (text.length > 35) return styles.LongText
  else if (text.length > 10) return styles.MediumText
  else return styles.SmallText
}

const FatButton = (props: FatButtonProps) => {
  // default style :
  // small : 52px
  // medium : 40px
  // large : 38px
  const [className, setClassName] = useState<string | undefined>()
  useEffect(() => {
    if (typeof props.children === "string") {
      setClassName(getStyleText(props.children))
    }
  }, [props.children])

  const ContainerStyles = `${styles.FatButton} ${
    props.img ? styles.Pictured : null
  }
    ${props.big ? styles.Big : null}
    ${props.clicked ? styles.Clicked : null} ${
    props.type
      ? props.type === "primary"
        ? styles.Primary
        : styles.Accent
      : null
  } `
  return (
    <div
      className={ContainerStyles}
      onClick={props.disabled ? undefined : props.onClick}
      data-testid="FatButton"
      style={props.style}
    >
      {props.img ? <img src={props.img} alt="" /> : null}
      {typeof props.children === "string" ? (
        props.subtext ?
        <>
          <span className={className}>{props.children}</span>
          {props.subtext && <span className={styles.Doctor_speciality}>{props.subtext}</span>}
        </> : <span className={className}>{props.children}</span>
      ) : (
          props.children
      )}
    </div>
  )
}
export default FatButton
