const getApiPrefix = () => "/clinic"
const getApiVersion = () => "/v1"
const getRouteRelatives = () => "/relatives"
const getRouteDoctors = () => "/doctors"
const getRouteConsultations = () => "/consultations"
const getRouteCustomers = () => "/customers"
const getPublicPrefix = () => "/public"

const signUpRoutes = (subPath, parent = "/signup") =>
  `${getPublicPrefix()}${getApiPrefix()}${getApiVersion()}${getRouteCustomers()}${parent}${subPath}`

const config = {
  clinic: {
    routes: {
      authentication: {
        by_nir: {
          check: `/public/clinic/v1/check/nir`,
          raw: `/public/clinic/v1/authentication/nir/raw`,
          with_birthdate: `/public/clinic/v1/authentication/nir/birthdate/manual`,
          with_lastname: `/public/clinic/v1/authentication/nir/manual`,
        },
        by_phone: {
          check: `/public/clinic/v1/check/phone`,
          with_birthdate: `/public/clinic/v1/authentication/phone/birthdate/manual`,
          with_lastname: `/public/clinic/v1/authentication/phone/manual`,
        },
      },

      relatives: `${getPublicPrefix()}${getApiPrefix()}${getApiVersion()}${getRouteRelatives()}`,
      putRelative: `/public/clinic/v1/relatives`,
      doctors: `${getPublicPrefix()}${getApiPrefix()}${getApiVersion()}${getRouteDoctors()}`,
      consultations: `${getPublicPrefix()}${getApiPrefix()}${getApiVersion()}${getRouteConsultations()}`,
      signup: {
        default: signUpRoutes(""),
        cancel: signUpRoutes("/cancel"),
        next: signUpRoutes("/next"),
        verify: signUpRoutes("/verify"),
        manual: signUpRoutes("/verify", "/manual"),
      },
      customers: `${getPublicPrefix()}${getApiPrefix()}${getApiVersion()}${getRouteCustomers()}`,
    },
  },
  locations: "/locations/public/search",
  terminals: {
    get: "/pharmacies/terminals/by-jwt",
    post: "/pharmacies/terminals/by-jwt",
    patch: "/pharmacies/terminals",
  },
  theme: {
    input: {
      default: "font-size: 35px",
    },
    alert: {
      default: "font-size: 35px",
    },
  },
  retry: {
    validation: {
      lastname: 2,
      phone: 2,
    },
  },
  timeoutNirReaderInS: 120,
  timeoutInactivity: {
    minutes: 5,
  },
  refreshAppEveryInMs: 3600000,
  disconnectTimer: {
    minutes: 1,
    seconds: 30,
  },
  finalTimer: 10,
  maintenancePingIntervalInS: 60,
}

export default config
