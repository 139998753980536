import { languages } from "../lib/languages"

const KEYBOARD_ALL_BUTTONS =
  "˜ ´ Ć Č ć č ¨ § € % ° Ø Ō Õ Ú Ū Ē Ę Ė Ã Ā Å Ì Í Į Ī Ù ú ū ø ō õ į ī ē ę ė å ª ã ā ò ó Ò Ó ù Ù æ Œ É È Ê Ë À Â Ä Î Ï Ô Ö Û Ü Æ Ç é è ê ë à â ä î ï ô ö û ü œ ç {char} {char-upper} {accent-upper} {accent} {open-keyboard} ( ) , ; : [ ] {back-space} {bksp-digit} {double-quote} A Z E R T Y U I O P Q S D F G H J K L M W X C V B N a z e r t y u i o p q s d f g h j k l m w x c v b n 1 2 3 4 5 6 7 8 9 0 {bksp} {enter} {shift-accent} {shift-accent-upper} {shift} {alt} . @gmail .fr @sfr @orange @hotmail .com @ ' {space} ! # $ % & * + - / = ? ^ _ ` { | } ~ é è ê ë à â ä í ï ì "
const KEYBOARD_ALL_UPPER_BUTTONS =
  "Ć Č Ō Õ Ú Ū Ē Ę Ė Ã Ā Å Ì Í Į Ī Ò Ó Ù Œ É È Ê Ë À Â Ä Î Ï Ô Ö Û Ü Æ Ç A Z E R T Y U I O P Q S D F G H J K L M W X C V B N"

export const keyboardLayout = {
  theme: "",
  buttonTheme: [
    {
      class: "disabled-keyboard-button",
      buttons: " ",
    },
    {
      class: "bksp-keyboard-button",
      buttons:
        "{shift} {accent-upper} {shift-accent} {shift-accent-upper} {accent} {char-upper} {char} {alt} {bksp-digit} {bksp} {open-keyboard}",
    },
    {
      class: "space-keyboard-button",
      buttons: "{space}",
    },
    {
      class: "large-keyboard-button",
      buttons: "{bksp} @gmail @orange @sfr @hotmail",
    },

    {
      class: "keyboard-button",
      buttons: KEYBOARD_ALL_BUTTONS,
    },
    {
      class: "keyboard-upper-button",
      buttons: KEYBOARD_ALL_UPPER_BUTTONS,
    },
    {
      class: "medium-keyboard-button",
      buttons: ".com @",
    },
  ],
  display: {
    "{double-quote}": '"',
    "{bksp}": "⌫",
    "{bksp-digit}": "⌫",
    "{space}": " espace ",
    "{shift}": "↑",
    "{alt}": "Alt",
    "{accent}": "àéï",
    "{shift-accent}": "↑",
    "{accent-upper}": "ÀÉÏ",
    "{shift-accent-upper}": "↑",
    "{char}": "aei",
    "{char-upper}": "AEI",
    "{back-space}": "\\",
    "{open-keyboard}": languages.openKeyboard,
  },
}

export const docNameKeyboard = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "a z e r t y u i o p",
    "q s d f g h j k l m",
    "{shift} w x c v b n . _",
    "    {space}    ",
  ],
  shift: [
    "A Z E R T Y U I O P {bksp}",
    "Q S D F G H J K L M ",
    "{shift} W X C V B N . _ ",
  ],
}

export const numericKeyboard = {
  default: [
    "    1 2 3 {bksp}  ",
    "    4 5 6    ",
    "    7 8 9    ",
    "     0     ",
  ],
}

export const codeKeyboard = {
  default: ["   1 2 3 4 5 {bksp} ", "   6 7 8 9 0   "],
}

export const nirKeyboard = {
  default: [
    "    1 2 3 {bksp}  ",
    "    4 5 6    ",
    "    7 8 9    ",
    "    A 0 B    ",
  ],
}

export const emailKeyboard = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "a z e r t y u i o p + -",
    "q s d f g h j k l m @ .com",
    "{shift} w x c v b n . _ .fr",
    "@sfr @hotmail {space} @gmail @orange",
  ],
  shift: [
    "# $ % & ' * / = ? ^ { | } ~ {bksp}",
    "A Z E R T Y U I O P ! `",
    "Q S D F G H J K L M @ .com",
    "{shift} W X C V B N . _ .fr",
    "@sfr @hotmail {space} @gmail @orange",
  ],
}

export const locationKeyboard = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 ' - {bksp}",
    "a z e r t y u i o p",
    "q s d f g h j k l m",
    "w x c {space} v b n _",
  ],
}

const accentKeyboard = {
  accent: [
    "ä â à æ a å ª ã ā ` ´ {bksp}",
    "ç œ ê ë è é æ ē ę ė ^ ¨",
    "ć č ï î ì í į ī ù û ú ū ˜",
    "{shift-accent-upper} {char} o œ ô ö ò ó ø ō õ",
    " {space} ",
  ],
  "accent-upper": [
    "Ä Â À Æ A Å ª Ã Ā ` ´ {bksp}",
    "Ç Œ Ê Ë È É Æ Ē Ę Ė ^ ¨",
    "Ć Č Ï Î Ì Í Į Ī Ù Û Ú Ū ˜",
    "{shift-accent} {char-upper} O Œ Ô Ö Ò Ó Ø Ō Õ",
    " {space} ",
  ],
}

export const passwordKeyboard = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 - _ € % ° {bksp}",
    "a z e r t y u i o p . *",
    "q s d f g h j k l m ' {double-quote}",
    "{shift} {accent} w x c v b n , ; /",
    " {space} ",
  ],
  shift: [
    "& ~ {double-quote} # { ( [ | {back-space} @ ] ) } = {bksp}",
    "A Z E R T Y U I O P § $",
    "Q S D F G H J K L M % +",
    "{shift} {accent-upper} W X C V B N ? : !",
    " {space} ",
  ],
  ...accentKeyboard,
}
export const noLayoutKeyboard = { default: [] }
export const textKeyboard = {
  default: [
    "é è ê ë à â ä î ï ô ö û ü œ ç {bksp}",
    "a z e r t y u i o p",
    "q s d f g h j k l m '",
    "{shift} w x c v b n -",
    " {space} ",
  ],
  shift: [
    "É È Ê Ë À Â Ä Î Ï Ô Ö Û Ü Æ Ç {bksp}",
    "A Z E R T Y U I O P",
    "Q S D F G H J K L M '",
    "{shift} W X C V B N -",
    " {space} ",
  ],
}
