import { languages } from "../lib/languages"

export const CGU_PAGE = `/${languages.page_cgu}`
export const HOME_PAGE = `/${languages.page_home}`
export const WELCOME_PAGE = `/${languages.page_welcome}`
export const REGISTER_PAGE = `/${languages.page_register}`
export const LOGIN_PAGE = `/${languages.page_login}`
export const ADMIN_PAGE = `/${languages.page_admin}`
export const NOT_AUTHORIZED_PAGE = `/${languages.page_not_authorized}`
export const NOT_FOUND_PAGE = `/${languages.page_not_found}`
export const VERIFY_PAGE = `/${languages.page_verify}`
export const INSERT_RELATIVE_PAGE = `/${languages.page_insert_relative}`
export const NIR_PAGE = `/${languages.page_nir}`
export const LOBBY_PAGE = `/${languages.page_lobby}`
export const CHECK_PHONE = `/${languages.page_check_phone}`
export const CHECK_LASTNAME = `/${languages.page_check_lastname}`
export const CHECK_PASSWORD = `/${languages.page_check_password}`
export const REGISTER_EMAIL = `/${languages.page_register_mail}`
export const REGISTER_PERSONAL_DATA = `/${languages.page_register_personal_data}`
export const VERIFY_PHONE = `/${languages.page_verify_phone}`
export const FINALE_PAGE = `/final`
export const SURVEY_PAGE = "/anamnese"
export const CURRENT_CONSULTATIONS_PAGE = "consultations"
export const RETRIEVE_ACCOUNT = `/${languages.page_retrieve_account}`
export const MAINTENANCE = `/${languages.page_maintenance}`
export const IS_THIS_YOUR_ACCOUNT = `/${languages.page_is_this_your_account}`
export const SET_RELATIVE_PAGE = `${languages.page_update_relative}`;
export const AFTER_CONSULT_UPDATE="cUpdate"