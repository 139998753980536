import { ResponseDoctorList } from "../../types/types"
import {
  DOCTOR_LIST_ERROR,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_REQUESTING,
} from "./constants"

const initialState: any = {
  success: false,
  loading: false,
}

interface DoctorAction {
  type: string
  payload: ResponseDoctorList | any
}

const reducer = function doctorReducer(
  state = initialState,
  action: DoctorAction
) {
  let newState = { ...state }
  switch (action.type) {
    case DOCTOR_LIST_ERROR:
    case DOCTOR_LIST_REQUESTING:
    case DOCTOR_LIST_SUCCESS:
      return newState
    default:
      return newState
  }
}

export default reducer
