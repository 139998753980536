import React, { useEffect, useState } from "react"
import styles from "./Home.module.scss"
import { Navigate } from "react-router-dom"
import { SURVEY_PAGE } from "../../core/constants"
import { Layout } from "antd"
import { languages } from "../../lib/languages"
import { useDispatch } from "react-redux"
import { logTerminalInformation } from "../../services/socket/actions"

const Home: React.FC<unknown> = ({}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logTerminalInformation())
  }, [])
  const [redirect, setRedirect] = useState(false);
  return (
    redirect ? (
      <Navigate to={SURVEY_PAGE} replace />
    ) : (
      <Layout
        onClick={() => setRedirect(true)}
        style={{
          height: "100vh",
          backgroundColor: "#211452",
          cursor: "pointer",
        }}
      >
        <div className={styles.Home} data-testid="Home">
          <div className={styles.PartnerBox}>
            <img
              className={styles.Logo}
              src="/images/mediksante.svg"
              alt={languages.medikSante}
            />
            <span className={styles.Partner}>
              {languages.partnerOf}
              <img src="/images/logo_medadom_u.svg" alt="Logo Medadom" />
            </span>
          </div>

          <h1 className={styles.SubTitle}>{languages.touch_screen}</h1>
        </div>
      </Layout>
    )
  )
}

export default Home
