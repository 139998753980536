export type UiView = "carousel" | "checkbox" | "selector"
export type UiPosition = "left" | "right" | "middle"
export type UiStyle = "primary" | "default" | "accent" | "warning" | "info"
export type Value = string | number | boolean | undefined

export enum ACTION {
  default = "default",
  subquestion = "subquestion",
  redirect = "redirect",
  nothing = "nothing"
}

export interface UiSurvey {
  view?: UiView
  position?: UiPosition
}
export interface UiSlideProps {
  items: Reply[]
  selected: Value[]
  onSelect: (r: Reply) => void
  unSelect: (r: Reply) => void
}

export interface Question {
  title: string
  subtitle?: string
  id: string
  subquestion?: Question
  multipleReplies: boolean
  replies: Reply[]
  defaultReply?: Reply
  ui?: UiSurvey
  confirmModal?: {
    trigger: string | "default" | "allways" | "subquestion" | "nodefault"
    onAccept?: () => void
    onCancel?: () => void
    title?: string
    message?: string
  }
}

export interface Reply {
  disabled?: boolean
  title: string
  img?: string
  imgUrl?: string
  value: Value
  action?: ACTION
  ui?: { style: UiStyle, type?: any, view?: any }
  subtitle?: string
  additionalActions?: any
}

export type Status = "processing" | "answered" | "to_ask"

export interface Answer {
  value: Value[]
  questionId: string
}

export interface SurveyProps extends SurveyStore {
  answer: Value[]
  redirect: boolean
  loading: boolean
  previous: () => void
  next: () => void
  setSaga: (r: Reply[]) => void
  set: (r: Reply[]) => void
  unset: (r?: Reply[]) => void
  cancel: () => void
  submit: (a: Answer[]) => void
  history: any
}

export interface SurveyStore {
  questions: Question[]
  current?: Question
  path?: string[]
  answers: Answer[]
  status: "on_going" | "finish" | "cancel"
}

export interface ConsultationPayload {
  chosen_doctor_id?: Value | null
  chosen_clinic_id?: Value | null
  relative_id?: Value | undefined
  start_date?: Value | undefined
  medical?: {
    illness?: Value
    illness_details?: Value
    allergies?: Value
    allergies_details?: Value
    operations?: Value
    pregnant_or_breastfeeding?: Value
    drugs?: Value
    reason?: Value
  }
}

interface Ui {
  style?: UiStyle
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed"
  view?: UiView
}
export interface ReplyAction {
  value: Value
  title: string
  action?: ACTION
  imgUrl?: string
  icon?: JSX.Element
  ui?: Ui
  preselect?: boolean
}
