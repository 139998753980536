import { Button, Space } from "antd"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Navigate } from "react-router-dom"
import TextTransition from "react-text-transition"

import { REGISTER_PAGE, SURVEY_PAGE } from "../../core/constants"
import { languages } from "../../lib/languages"
import {
  cancelNirReader,
  requestNirReader,
  resetNirReader,
} from "../../services/nir/actions"
import { buttonLayout } from "../../styles/constants"
import { SocketStore } from "../../types/new/types/store"
import { ReaderState } from "../../types/sesam"
import { AuthStore, Patient } from "../../types/types"
import PreviousButton from "../new/Previous/PreviousButton"
import Nir from "./Nir/Nir"
import styles from "./NirReader.module.scss"

interface NirReaderProps extends AuthStore {
  nirRequest: () => void
  nirCancel: () => void
  nirReset: () => void
  previous: () => void
  readerState: ReaderState
  nirReaderResponse: Partial<Patient>
}

interface ParentProps {
  onFinish: (patient: Partial<Patient>) => void
  hasNir: (hasNir: boolean) => void
}

const UiReader = (props: {
  title: string
  loading?: boolean
  readerCancel?: () => void
  readerInit?: () => void
  readerReset?: () => void
  buttonText?: string
  hideButtons?: boolean
}) => {
  return (
    <div className={styles.NirReader} data-testid="NirReader">
      <PreviousButton
        text={languages.cancel}
        onClick={() => props.readerReset && props.readerReset()}
      />

      <h1 className="title-card">
        <TextTransition
          className="align-center"
          text={props.title}
          springConfig={{ mass: 1, tension: 1000, friction: 100 }}
        />
      </h1>
      <img src="/images/vital_card_reader.svg" alt="" />
      {props.hideButtons ? null : (
        <div className="wrapped-buttons vertical">
          <Space
            size={45}
            direction="vertical"
            style={{
              textAlign: "center",
            }}
          >
            <Button
              {...buttonLayout}
              type="primary"
              disabled={props.loading}
              loading={props.loading}
              onClick={() => props.readerInit && props.readerInit()}
            >
              {props.buttonText}
            </Button>
            <Button
              onClick={() => {
                props.readerCancel && props.readerCancel()
              }}
              {...buttonLayout}
            >
              {languages.nirManualInput}
            </Button>
          </Space>
        </div>
      )}
    </div>
  )
}
class NirReader extends Component<NirReaderProps & ParentProps & any> {
  public componentDidUpdate = () => {
    if (this.props.success) {
      this.props.apiResponse(this.props.response)
    }
  }

  public checkNirReaderResponse(response: any): JSX.Element {
    if (response.isRegistered === false) {
      return <Navigate to={REGISTER_PAGE} replace />
    }

    return <Navigate to={SURVEY_PAGE} replace />
  }
  public componentDidMount = () => {
    if (
      [ReaderState.INITIALIZE, ReaderState.ERROR].includes(
        this.props.readerState
      )
    )
      this.props.nirRequest()
  }
  render = () => {
    if (this.props.unreachable) {
      return (
        <Nir
          defaultNir={this.props.nirReaderResponse?.nir}
          hasNir={(value: boolean) => {
            this.props.hasNir(value)
          }}
          onFinish={({ nir }: { nir: string }) => {
            this.props.onFinish({ nir })
          }}
        />
      )
    }
    if (
      [ReaderState.INITIALIZE, ReaderState.ERROR].includes(
        this.props.readerState
      )
    ) {
      return (
        <UiReader
          title={languages.ReaderStopped}
          loading={false}
          buttonText={languages.tryAgain}
          readerCancel={this.props.nirCancel}
          readerInit={this.props.nirRequest}
          readerReset={this.props.previous}
        />
      )
    } else if (this.props.readerState === ReaderState.INSERT) {
      return (
        <UiReader
          title={languages.insertYourNir}
          loading={true}
          buttonText={languages.currentlyReadingNir}
          readerCancel={this.props.nirCancel}
          readerInit={this.props.nirRequest}
          readerReset={this.props.nirReset}
        />
      )
    } else if (this.props.readerState === ReaderState.REMOVE) {
      return <UiReader title={languages.removeYourNir} hideButtons={true} />
    } else if (this.props.readerState === ReaderState.REMOVED) {
      if (this.props.loading === false) {
        const nirReaderResponse: Partial<Patient> = this.props.rawNir
        this.props.onAuthenticate({ ...nirReaderResponse })
      }
      return <UiReader title={languages.removeYourNir} hideButtons={true} />
    } else if (ReaderState.CANCELED) {
      return (
        <Nir
          defaultNir={this.props.nirReaderResponse?.nir}
          hasNir={(value: boolean) => {
            this.props.hasNir(value)
          }}
          onFinish={({ nir }: { nir: string }) => {
            this.props.onFinish({ nir })
          }}
        />
      )
    } else {
      this.props.nirReset()
      return <div>RESET</div>
    }
  }
}

const mapStateToProps = ({
  nir,
  socket,
}: {
  nir: NirReaderProps
  socket: SocketStore
}): NirReaderProps => {
  return {
    ...nir,
    ...socket,
  }
}
const mapDispatchToProps = (dispatch: Function) => {
  return {
    nirRequest: () => dispatch(requestNirReader()),
    nirCancel: () => dispatch(cancelNirReader()),
    nirReset: () => dispatch(resetNirReader()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NirReader)
