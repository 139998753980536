import { Rule } from "antd/lib/form"
import { capitalize } from "lodash"
import {
  dateValidator,
  codeValidator,
  passwordValidator,
  phoneValidator,
  nirValidator,
  nirLengthValidator,
} from "../../../lib/form"
import { languages } from "../../../lib/languages"

export const typeFromName = (name?: string): string => {
  switch (name) {
    case "email":
      return name
    case "birthdate":
    case "date":
      return "date"
    case "gender":
    case "sex":
    case "patient":
      return "checkbox"
    case "nir":
      return "nir"
    case "phone":
      return "phone"
    case "new-password":
    case "password":
      return "password"
    case "code":
      return "code"
    default:
      return "string"
  }
}
export const getNameOfCurrentInput = (name: string): string => {
  return name === "new-password" ? "password" : name
}

export const labelFromName = (name?: string): string => {
  switch (name) {
    case "insee_code":
      return languages.code_insee
    case "birth_location":
        return languages.birth_location
    case "email":
      return languages.email
    case "phone":
      return languages.phone
    case "password":
    case "new-password":
      return languages.password
    case "nir":
      return languages.nir
    case "firstname":
      return languages.firstname
    case "first_birth_firstname":
      return languages.first_birth_firstname;
    case "birth_lastname":
      return languages.birth_lastname;
    case "lastname":
      return languages.lastname
    case "birthdate":
      return languages.birthdate
    case "gender":
    case "sex":
      return languages.gender
    case "card":
      return languages.creditCard
    case "code":
      return "code"
    default:
      return ""
  }
}

export const rules = (name: string, required: boolean): Rule[] => {
  const label = labelFromName(name)
  const type = typeFromName(name)

  const rules: Rule[] = [
    {
      required,
      message: capitalize(languages.inputYour + label),
    },
  ]
  if (type === "date") {
    rules.push(dateValidator)
  } else if (type === "code") {
    rules.push(codeValidator)
  } else if (type === "password") {
    rules.push(passwordValidator)
  } else if (type === "phone") {
    rules.push(phoneValidator)
  } else if (type === "nir") {
    rules.push(nirValidator)
    rules.push(nirLengthValidator)
  } else if (type === "string") {
    rules.push({
      type,
      message: capitalize(`${languages.incorrectInput} ${label}`),
    })
  } else if (type === "email") {
    rules.push({ type: "email" })
  }
  return rules
}
