import {
  Action,
  ActionWithoutPayload,
  Message,
} from "../../types/new/types/redux"
import { ReaderState } from "../../types/sesam"
import { Patient } from "../../types/types"
import {
  AUTHENTICATE_FROM_RAW_NIR,
  CANCEL_NIR_READER,
  FROM_NIR_REQUESTING,
  NIR_ERROR,
  NIR_READER_RESPONSE,
  REQUEST_NIR_READER,
  RESET_NIR_READER,
  SET_NIR_READER_LOADING,
  SET_NIR_READER_MESSAGE,
  SET_NIR_READER_STATE,
  SET_RAW_NIR,
} from "./constants"

// Propre à CLINIC
export const authenticateByNirRaw = (payload: any) => ({
  type: AUTHENTICATE_FROM_RAW_NIR,
  payload,
})
export const fromNirRequest = (nir: string) => {
  return {
    type: FROM_NIR_REQUESTING,
    payload: { nir },
  }
}

export function setRawNir({ payload }: any) {
  return { type: SET_RAW_NIR, payload }
}

// Fin propre à clinic
export const nirReaderResponse = (patient: Partial<Patient>) => ({
  type: NIR_READER_RESPONSE,
  payload: { ...patient },
})

export const nirError = (error: string) => ({
  type: NIR_ERROR,
  error,
})
export const requestNirReader = (): ActionWithoutPayload => ({
  type: REQUEST_NIR_READER,
})

export const resetNirReader = (): ActionWithoutPayload => ({
  type: RESET_NIR_READER,
})

export const cancelNirReader = (): ActionWithoutPayload => ({
  type: CANCEL_NIR_READER,
})
export const setNirReaderLoading = (payload: boolean): Action<boolean> => ({
  type: SET_NIR_READER_LOADING,
  payload,
})
export const setNirReaderMessage = (payload: Message): Action<Message> => ({
  type: SET_NIR_READER_MESSAGE,
  payload,
})

export const setNirReaderState = (
  payload: ReaderState
): Action<ReaderState> => ({
  type: SET_NIR_READER_STATE,
  payload,
})
