import { InputProps, Select } from "antd"
import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import React, { useEffect, useState } from "react"

import { languages } from "../../../../lib/languages"
import { DATE_FORMAT } from "../../../../lib/utils"
import styles from './DateInput.module.scss'

dayjs.extend(customParseFormat)

const { Option } = Select
const getDayOptions = () => {
  return [...Array(31)].map((value, key) => (
    <Option key={key} value={(key + 1).toString().padStart(2, "0")}>
      {(key + 1).toString().padStart(2, "0")}
    </Option>
  ))
}

const getYearOptions = () => {
  let currentYear = new Date().getFullYear()
  const oldestYear = 1900
  return [...Array(currentYear - oldestYear)].map(() => (
    <Option
      key={(currentYear -= 1)}
      value={currentYear.toString().padStart(4, "0")}
    >
      {currentYear.toString().padStart(4, "0")}
    </Option>
  ))
}
const months = [
  languages.jan,
  languages.feb,
  languages.mar,
  languages.apr,
  languages.may,
  languages.jun,
  languages.jul,
  languages.aug,
  languages.sep,
  languages.oct,
  languages.nov,
  languages.dec,
]

const getMonthOptions = () => {
  return months.map((value, key) => (
    <Option key={key + 1} value={(key + 1).toString().padStart(2, "0")}>
      {value}
    </Option>
  ))
}

export interface DateProps extends Omit<InputProps, "onChange" | "value"> {
  value?: Dayjs | string
  initialValue?: Dayjs | string
  onChange?: (e: Dayjs | string) => void
}

const DateSelector: React.FC<DateProps> = ({
  value = undefined,
  disabled,
  onChange,
}): JSX.Element => {
  const [dateValues, setDate] = useState<{
    day: string
    month: string
    year: string
  }>({
    day: languages.DD,
    month: languages.MM,
    year: languages.YYYY,
  })
  useEffect(() => {
    let date: Dayjs | undefined = undefined

    if (typeof value === "string") date = dayjs(value, "DDMMYYYY")
    else date = value
    if (date && date.isValid()) {
      const newDateValues = {
        day: date.get("date").toString().padStart(2, "0"),
        month: (date.get("month") + 1).toString().padStart(2, "0"),
        year: date.get("year").toString().padStart(4, "0"),
      }
      setDate(newDateValues)
    }
  }, [value])

  const handleChange = (event: string, value: string) => {
    const newDateValues = { ...dateValues, [event]: value }
    setDate(newDateValues)
    onChange &&
      onChange(
        dayjs(
          `${newDateValues.day}/${newDateValues.month}/${newDateValues.year}`,
          DATE_FORMAT,
          true
        )
      )
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        <Select
          disabled={disabled}
          onChange={(e) => handleChange("day", e)}
          className={`left-selector ${disabled ? styles.OptionBold : null}`}
          size="large"
          defaultValue={dateValues.day || languages.selectDayBirthdate}
          value={dateValues.day}
        >
          {getDayOptions()}
        </Select>
        <Select
          disabled={disabled}
          className={`${disabled ? styles.OptionBold : null}`}
          onChange={(e) => handleChange("month", e)}
          style={{ flex: 1 }}
          size="large"
          defaultValue={dateValues.month || languages.selectMonthBirthdate}
          value={dateValues.month}
        >
          {getMonthOptions()}
        </Select>
        <Select
          className={`right-selector ${disabled ? styles.OptionBold : null}`}
          disabled={disabled}
          onChange={(e) => handleChange("year", e)}
          style={{ flex: 1 }}
          size="large"
          defaultValue={dateValues.year || languages.selectYearBirthdate}
          value={dateValues.year}
        >
          {getYearOptions()}
        </Select>
      </div>
    </>
  )
}

export default DateSelector
