import {
  DOCTOR_LIST_REQUESTING,
  DOCTOR_LIST_ERROR,
  DOCTOR_LIST_SUCCESS,
} from "./constants"
import { Doctor } from "../../types/types"

export const doctorListRequest = () => ({
  type: DOCTOR_LIST_REQUESTING,
})

export const doctorListError = (error: string) => ({
  type: DOCTOR_LIST_ERROR,
  payload: { error },
})

export const doctorListSuccess = (payload: Doctor[]) => {
  return {
    type: DOCTOR_LIST_SUCCESS,
    payload,
  }
}
