import React, { useState } from "react"
import { Input } from "antd"
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"

const PasswordInput = (props: any) => {
  const [visible, setVisilibity]: any = useState(props.visible ?? true)

  return visible ? (
    <Input
      {...props}
      suffix={
        <EyeOutlined
          style={{ fontSize: "25px", marginRight: "15px"}}
          onClick={() => {
            setVisilibity(false)
          }}
        />
      }
    />
  ) : (
    <Input.Password
      {...props}
      iconRender={(visible) =>
        visible ? <EyeOutlined style={{ fontSize: "25px", marginRight: "15px"}} /> : <EyeInvisibleOutlined style={{ fontSize: "25px", marginRight: "15px"}} />
      }
    />
  )
};

export { PasswordInput }
