import { put, call, all, takeLeading } from "redux-saga/effects"
import { v4 as uuidv4 } from "uuid"

import { INSTALLATION_REQUESTING, LOGOUT } from "./constants"
import { InstallationInfos, ResponseInstallation } from "../../types/types"

import { handleApiErrors } from "../../lib/api-errors"
import {
  installationSuccess,
  installationError,
  setClinic,
  setPatient,
  setRelatives,
} from "./actions"
import Types from "pages/currentConsultations/services/constants"

const installationUrl: string = "/api/installations"

async function installationApi(): Promise<ResponseInstallation> {
  let identifier: string = localStorage.getItem("identifier") || uuidv4()
  localStorage.setItem("identifier", identifier)
  const params: InstallationInfos = {
    installation_identifier: identifier,
    app_version: "3.0.0",
    device_type: "Web",
    device_model: navigator.userAgent,
    os_version: "",
    badge: "0",
    locale_identifier: "en",
    time_zone: "Europe/Paris",
    is_active: true,
    is_release: true,
    bundle: "",
  }

  return fetch(installationUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then(handleApiErrors)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      throw error
    })
}

function* installationFlow() {
  try {
    const response: ResponseInstallation = yield call(installationApi)
    sessionStorage.setItem("X-USER-Token", response.jwt)
    yield put(installationSuccess(response))
    if (response.pharmacy) yield put(setClinic(response.pharmacy))
  } catch (error) {
    // error? send it to redux
    yield put(installationError(error))
  }
}
function* logoutFlow() {
  yield all([
    put({ type: "RESET_STORE" }),
    put({ type: Types.CLEAN_STORE}),
    put(setPatient(null)),
    put(setRelatives(null)),
  ])
}

function* clientWatcher() {
  yield takeLeading(INSTALLATION_REQUESTING, installationFlow)
  // PATIENT LOGOUT
  yield takeLeading(LOGOUT, logoutFlow)
}

export default clientWatcher
