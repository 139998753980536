import config from "react-global-configuration"
import { w3cwebsocket as W3CWebSocket } from "websocket"

import { Action } from "../../types/new/types/redux"
import { SocketStore } from "../../types/new/types/store"
import {
  SAVE_CONFIGURATION_ID,
  SET_SOCKET,
  SOCKET_ERROR_UNREACHABLE,
  SOCKET_INIT_CARD_VITALE_READER,
  SOCKET_RESET_CARD_VITALE_READER,
  SOCKET_RETRY_CONNECT_DECREMENT_RETRY,
  SOCKET_RETRY_CONNECT_RESET,
} from "./constants"

const initialstate: Partial<SocketStore> = {
  numberOfRetryLeft: 0,
  unreachable: false,
}

const reducer = function socketReducer(
  state = {
    ...initialstate,
  },
  action: Action<
    | W3CWebSocket
    | {
        [x: string]: unknown
      }
    | number
  >
): Partial<SocketStore> {
  const newState = { ...state }
  switch (action.type) {
    case SET_SOCKET: {
      return { ...newState, client: action.payload as W3CWebSocket }
    }
    case SOCKET_ERROR_UNREACHABLE: {
      return { ...newState, unreachable: true }
    }
    case SOCKET_RETRY_CONNECT_DECREMENT_RETRY:
      const actualRetryNumber =
        (state.numberOfRetryLeft as number) - (action.payload as number)
      return {
        ...newState,
        numberOfRetryLeft: actualRetryNumber,
      }
    case SOCKET_INIT_CARD_VITALE_READER:
      return {
        ...newState,
        stop: undefined,
        numberOfRetryLeft: config.get("timeout.nb_socket_try") as number,
      }
    case SOCKET_RESET_CARD_VITALE_READER:
      return {
        ...newState,
        stop: true,
      }
    case SOCKET_RETRY_CONNECT_RESET:
      return {
        ...newState,
        numberOfRetryLeft: config.get("timeout.nb_socket_try") as number,
      }
    case SAVE_CONFIGURATION_ID:
      return {
        ...newState,
        ...(action.payload as {
          [x: string]: unknown
        }),
      }
    default:
      return { ...newState }
  }
}

export default reducer
