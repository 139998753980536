class Prospect {
  birthdate: string | ""
  firstname: string | ""
  lastname: string | ""
  gender: number
  nir: string | ""
  phone: string | ""
  sex: string | ""
  email: string | ""
  constructor({
    birthdate,
    email,
    firstname,
    lastname,
    gender,
    nir,
    phone,
    sex,
  }: any) {
    this.birthdate = birthdate || ""
    this.email = email || ""
    this.firstname = firstname || ""
    this.lastname = lastname || ""
    this.gender = gender | sex
    this.nir = nir || ""
    this.phone = phone || ""
    this.sex = gender
  }

  getPersonnalData = () => ({
    firstname: this.firstname,
    lastname: this.lastname,
    sex: this.sex,
    nir: this.nir,
    birthdate: this.birthdate,
    email: this.email,
  })

  getLoginData = () => ({
    phone: this.phone,
  })

  arePersonnalDataSet = () => {
    return this.firstname !== "" && this.lastname !== "" && this.nir !== ""
  }
}

export default Prospect
