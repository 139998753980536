import React from "react"
import { Space, SpaceProps } from "antd"
import Message from "../Message/Message"
import { Title, Subtitle } from "../Title/Title"
import styles from "./styles.module.scss"

type MessageType = "error" | "success" | "warning" | "info"
interface Message {
  text: string
  type: MessageType
}

export const Card: React.FC<{
  title?: string
  subtitle?: string
  message?: Message
}> = (props): JSX.Element => {
  return (
    <div className={styles.Card}>
      {props.title && <Title text={props.title} />}
      {props.subtitle && <Subtitle text={props.subtitle} />}
      {props.message && <Message message={props.message} />}
      <div className={styles.Content}>{props.children}</div>
    </div>
  )
}

export const Buttons: React.FC<SpaceProps> = ({
  children,
  ...rest
}): JSX.Element => {
  if (rest.direction === "vertical")
    return (
      <Space direction="vertical" className={`${styles.Buttons}`} {...rest}>
        {children}
      </Space>
    )
  return (
    <Space
      direction="horizontal"
      size={60}
      style={{ paddingTop: "50px", justifyContent: "center", width: "100%" }}
    >
      {children}
    </Space>
  )
}
