import { Button as AntdButton, ButtonProps } from "antd"
import React, { forwardRef, Ref } from "react"
import { omit } from "lodash"
import styles from "./button.module.scss"

const Button: React.FC<
  ButtonProps & { ref?: Ref<HTMLElement> } & {
    wide?: "long" | "default" | "short"
    htmlType?: string
  }
> = forwardRef(({ children, ...rest }, ref) => {
  const style = {
    ...rest.style,
    paddingLeft: rest.wide === "long" ? "100px" : "30px",
    paddingRight: rest.wide === "long" ? "100px" : "30px",
  }
  return (
    <AntdButton
      className={styles.Button}
      size="large"
      shape="round"
      htmlType="submit"
      type={rest.type ? rest.type : "primary"}
      {...omit(rest, "style")}
      style={style}
      ref={ref}
    >
      {children || null}
    </AntdButton>
  )
})
export default Button
