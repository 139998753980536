import {
  INSERT_RELATIVE_REQUESTING,
  INSERT_RELATIVE_ERROR,
  INSERT_RELATIVE_SUCCESS,
  RESET_RELATIVE_STORE,
} from "./constants"
import { LOGOUT } from "../client/constants"
import { AuthStore } from "../../types/types"
import { ADMIN_LOGOUT } from "../admin/constants"

export const initialState: AuthStore = {
  success: false,
  loading: false,
}

interface RelativeAction {
  type: string
  payload: any // response relatives
}

const reducer = function relativeReducer(
  state = initialState,
  action: RelativeAction
) {
  let newState = { ...state }
  switch (action.type) {
    case INSERT_RELATIVE_REQUESTING:
      return { ...newState, loading: true, success: false }
    case INSERT_RELATIVE_SUCCESS:
      return {
        ...newState,
        success: true,
        loading: false,
      }

    case INSERT_RELATIVE_ERROR:
      return {
        ...newState,
        success: false,
        loading: false,
        message: "Erreur lors de l'envoi du relative",
      }
    case LOGOUT:
    case ADMIN_LOGOUT:
    case RESET_RELATIVE_STORE:
      return initialState
    default:
      return newState
  }
}

export default reducer
