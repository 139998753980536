import React, { createRef, useEffect } from "react"
import styles from "./NirInput.module.scss"
import InputMask from "react-input-mask"
import { Form, FormInstance, Input } from "antd"
import { languages } from "../../../lib/languages"
import { formatNirFromMask } from "../../../lib/utils"
import { nirValidator } from "../../../lib/form"
import withKeyboard, { KeyboardChildrenProps } from "../../hocs/withKeyboard"

interface NirInputProps {
  id: string
  required: boolean | false
  label: string | null
}
const NIR_MASK = `${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar} ${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar} ${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar} ${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar} ${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar} ${languages.nirAllowedChar}${languages.nirSpacer}${languages.nirAllowedChar} ${languages.nirAllowedChar}`

const NirInput: React.FC<any> = (
  props: (NirInputProps & KeyboardChildrenProps) | any
) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const nir: string = formatNirFromMask(e.target.value)
    nir.length === 15 && e.target.blur()
    props.onChange && props.onChange(nir)
  }

  return (
    <Form.Item
      name="nir"
      label={props.label ? props.label : null}
      rules={[
        props.required
          ? {
              required: true,
              message: "Veuillez entrer votre carte vitale",
            }
          : {},
        nirValidator,
        () => ({
          validator(rule, value) {
            const nir: string = formatNirFromMask(value)
            return nir.length > 0 && nir.length < 15
              ? Promise.reject(languages.nirLengthError)
              : Promise.resolve()
          },
        }),
      ]}
    >
      <InputMask
        readOnly={props.readOnly}
        disabled={props.disabled}
        id={props.name}
        name={props.name}
        onChange={onChange}
        value={props.value as string}
        alwaysShowMask
        className={styles.NirInput}
        mask={NIR_MASK}
      />
    </Form.Item>
  )
}

export default NirInput
