import { languages } from "./languages"
import {
  ACTION_REPLY,
  ANAMNESE_QUESTION_STEP,
  OrderQuestion,
  ORDER_QUESTION_STATUS,
  RegisterRequest,
  ReplyOrderValue,
} from "../types/types"
import { last } from "lodash"
import dayjs from "dayjs"

export const DATE_FORMAT = "DD/MM/YYYY"
export const API_DATE_FORMAT = "YYYY-MM-DD"

const GENERALIST = 1;
const NURSE = 4;
const SECRETARY = 8;
const MIDWIFE = 9;

export const DOCTOR_CATEGORY_LABEL: { [x: number]: string } = {
  [GENERALIST]: languages.generalist,
  [NURSE]: languages.nurse,
  [SECRETARY]: languages.secretary,
  [MIDWIFE]: languages.midwife
}

const splitSentenceByCharsLength = (
  text: string,
  maxLengthByLine: number,
  words: string[] = []
): string[] => {
  const wordsFromText = text.split(" ")
  const nextWord = wordsFromText.shift()
  const newText = wordsFromText.join(" ")

  if (nextWord) {
    const lastWords = last(words)
    if (lastWords && lastWords.length + nextWord.length <= maxLengthByLine) {
      const oldLastWords = words.pop()
      words.push(`${oldLastWords} ${nextWord}`)
    } else {
      words.push(nextWord)
    }
    return splitSentenceByCharsLength(newText, maxLengthByLine, words)
  } else return words
}

export const splitByChars = (
  textToSplit: string,
  maxLengthByLine: number = 12
): string[] => {
  return splitSentenceByCharsLength(textToSplit, maxLengthByLine)
}

export function getTime(date: Date) {
  var hr: any = date.getHours()
  var min: any = date.getMinutes()
  if (min < 10) min = "0" + min
  if (hr < 10) hr = "0" + hr
  return hr + ":" + min
}

export function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000)
}

export const getRoundHour = (date: Date = new Date()): Date => {
  // Return the next 1/4 of the current hour
  date.setMinutes(date.getMinutes() + (5 - (date.getMinutes() % 5)))
  date.setSeconds(0)
  return date
}

export const formatNirFromMask = (mask: string | null): string =>
  mask?.length
    ? mask.split(languages.nirSpacer).join("").split("_").join("")
    : ""

export const isValidNir = (mask: string): boolean => {
  let nir = formatNirFromMask(mask)
  if (nir.length !== 15) return false
  // @TODO (000000000000097)
  if (/2A/.test(nir)) {
    nir = nir.replace(/2A/, "19")
  }
  if (/2B/.test(nir)) {
    nir = nir.replace(/2B/, "18")
  }

  var num = parseInt(nir.substring(0, 13))
  var key = parseInt(nir.substring(13, 15))
  if (97 - (num % 97) !== key) return false
  return true
}
export const complyAnamnese = (anamnese: {
  [k in ANAMNESE_QUESTION_STEP]: ReplyOrderValue[]
}) => {
  const complied = {
    illness: Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.ILLNESS])
      ? !!anamnese[ANAMNESE_QUESTION_STEP.ILLNESS][0]
      : null,
    illness_details:
      Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.ILLNESS]) &&
        anamnese[ANAMNESE_QUESTION_STEP.ILLNESS][0]
        ? anamnese[ANAMNESE_QUESTION_STEP.ILLNESS].join(", ")
        : null,
    allergies: Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES])
      ? !!anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES][0]
      : null,
    allergies_details:
      Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES]) &&
        anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES][0]
        ? anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES].join(", ")
        : null,
    operations: Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.OPERATIONS])
      ? !!anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES][0]
      : null,
    pregnant_or_breastfeeding: Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.PREGNANT_OR_BREASTFEEDING])
      ? !!anamnese[ANAMNESE_QUESTION_STEP.ALLERGIES][0]
      : null,
    drugs: Array.isArray(anamnese[ANAMNESE_QUESTION_STEP.DRUGS])
      ? !!anamnese[ANAMNESE_QUESTION_STEP.DRUGS][0]
      : null,
  }
  return complied
}

export const complyPatient = (patient: RegisterRequest): RegisterRequest => {
  return {
    ...patient,
    firstname: patient.firstname && capitalize(patient.firstname),
    lastname: patient.lastname?.toUpperCase(),
    sex: Number(patient.gender),
    birthdate: patient.birthdate,
    email: patient.email?.toLowerCase(),
  }
}

export const initialAnamnese: {
  [k in ANAMNESE_QUESTION_STEP]: OrderQuestion
} = {
  [ANAMNESE_QUESTION_STEP.INITIALIZE]: {
    title:
      "le professionnel de santé a besoin de certaines informations médicales vous concernant",
    replies: [],
    required: false,
    multipleReplies: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
  [ANAMNESE_QUESTION_STEP.ILLNESS]: {
    title: languages.anamneseIllnessQuestion,
    subtitle: languages.anamneseIllnessSubtitle,
    replies: [
      {
        text: languages.yes,
        action: ACTION_REPLY.SUBQUESTION,
        value: true,
        type: "primary",
      },
      {
        text: languages.no,
        action: ACTION_REPLY.DEFAULT,
        value: false,
      },
    ],
    subQuestion: {
      title: languages.anamneseIllnessQuestionDetails,
      replies: [
        {
          text: languages.diabetes,
          action: ACTION_REPLY.DEFAULT,
          value: languages.diabetes,
        },
        {
          text: languages.hypertension,
          action: ACTION_REPLY.DEFAULT,
          value: languages.hypertension,
        },
        {
          text: languages.cancer,
          action: ACTION_REPLY.DEFAULT,
          value: languages.cancer,
        },
        {
          text: languages.respiratoryDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.respiratoryDisease,
        },
        {
          text: languages.heartDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.heartDisease,
        },
        {
          text: languages.kidneyDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.kidneyDisease,
        },
        {
          text: languages.digestiveDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.digestiveDisease,
        },
        {
          text: languages.infectiousDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.infectiousDisease,
        },
        {
          text: languages.otherDisease,
          action: ACTION_REPLY.DEFAULT,
          value: languages.otherDisease,
        },
      ],
      multipleReplies: true,
      carousel: false,
      status: ORDER_QUESTION_STATUS.QUERING,
      required: true,
    },
    required: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
  [ANAMNESE_QUESTION_STEP.OPERATIONS]: {
    title: languages.anamneseOperationsQuestion,
    replies: [
      {
        text: languages.yes,
        action: ACTION_REPLY.DEFAULT,
        value: true,
        type: "primary",
      },
      {
        text: languages.no,
        action: ACTION_REPLY.DEFAULT,
        value: false,
      },
    ],
    required: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
  [ANAMNESE_QUESTION_STEP.ALLERGIES]: {
    title: languages.anamneseAllergiesQuestion,
    subtitle: languages.anamneseAllergiesSubtitle,
    replies: [
      {
        text: languages.yes,
        action: ACTION_REPLY.SUBQUESTION,
        value: true,
        type: "primary",
      },
      {
        text: languages.no,
        action: ACTION_REPLY.DEFAULT,
        value: false,
      },
    ],
    subQuestion: {
      title: languages.anamneseAllergiesQuestionDetails,
      replies: [
        {
          text: languages.drugAllergy,
          action: ACTION_REPLY.DEFAULT,
          value: languages.drugAllergy,
        },
        {
          text: languages.pollenAllergy,
          action: ACTION_REPLY.DEFAULT,
          value: languages.pollenAllergy,
        },
        {
          text: languages.foodAllergy,
          action: ACTION_REPLY.DEFAULT,
          value: languages.foodAllergy,
        },
        {
          text: languages.otherAllergy,
          action: ACTION_REPLY.DEFAULT,
          value: languages.otherAllergy,
        },
      ],
      multipleReplies: true,
      carousel: false,
      status: ORDER_QUESTION_STATUS.QUERING,
      required: true,
    },
    required: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
  [ANAMNESE_QUESTION_STEP.PREGNANT_OR_BREASTFEEDING]: {
    title: languages.anamnesePregnantQuestion,
    replies: [
      {
        text: languages.yes,
        action: ACTION_REPLY.DEFAULT,
        value: true,
        type: "primary",
      },
      {
        text: languages.no,
        action: ACTION_REPLY.DEFAULT,
        value: false,
      },
    ],
    required: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
  [ANAMNESE_QUESTION_STEP.DRUGS]: {
    title: languages.anamneseDrugsQuestion,
    replies: [
      {
        text: languages.yes,
        action: ACTION_REPLY.DEFAULT,
        value: true,
        type: "primary",
      },
      {
        text: languages.no,
        action: ACTION_REPLY.DEFAULT,
        value: false,
      },
    ],
    required: true,
    status: ORDER_QUESTION_STATUS.QUERING,
  },
}

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

interface FormatPhone {
  phone: string
  prefixPhone?: string
}

export const formatPhone = ({ phone, prefixPhone = "+33" }: FormatPhone) => {
  const formattedNumber = phone
    .replace(/\s/g, "")
    .replace(/^\d/, prefixPhone)
  return formattedNumber
}

export const century = (yy: number): number => {
  const now = new Date()
  let currentYear = parseInt(now.getFullYear().toString().substr(2, 2))
  let y = yy
  var century = y >= currentYear ? 1900 : 2000
  return century + y
}

export const getAge = (dateString: string | undefined): number | undefined => {
  if (!dateString) return undefined
  const formattedDate: any = dayjs(dateString, DATE_FORMAT)
  const today = new Date()
  const birthDate = new Date(formattedDate)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}


export const formatDateToYYYYMMDD = (date: string) => `${date[4]}${date[5]}${date[6]}${date[7]}-${date[2]}${date[3]}-${date[0]}${date[1]}`

