import { Dayjs } from "dayjs";

export interface Doctor {
    id: string
    firstname: string
    lastname: string
    rpps: string
    category_id: number
  }

export interface Admin {
  email: string
  phone:string
  name:string
  created_at:Date
  updated_at:Date
  is_valid:boolean
}

export interface Relative {
  id: string 
  birthdate: string | Dayjs
  created_at?: Date
  firstname: string
  lastname: string
  nir: boolean | string
  sex?: Gender
  updated_at?: Date
}

export enum Gender {
  MALE = 0,
  FEMALE = 1,
}
export interface Patient {
  id: string
  birthdate: string
  created_at: string
  email: string
  firstname: string
  first_birth_firstname?: string
  is_verified: boolean
  lastname: string
  birth_lastname?: string
  birth_location?: string
  nir?: boolean | string
  phone: string
  sex: Gender | null
  updated_at: string
  insee_code?: string | number | null
  birth_country?: string
}
export type Customer = Patient

export interface Prospect extends Partial<Patient>{
  password?: string
}


export enum BirthPlace {
  FRANCE = 1,
  FOREIGN = 2,
  UNKNOWN = 3,
}