import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons"
import { ButtonProps, Checkbox, Col, Select, Space } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"


import { Button } from "components/new/Button"
import { FatButton } from "components/generics"
import { Buttons } from "components/new/Card/Card"
import { ITEMS_PER_CAROUSEL_PAGE } from "pages/Survey/models"
import { ACTION, Reply, ReplyAction } from "pages/Survey/types"
import { languages } from "lib/languages"
import { splitByChars } from "lib/utils"

import styles from "./styles.module.scss"
import { UiArrowProps, UiButtonProps, UiCheckboxesProps, UiCheckboxProps, UiFooterProps, UiGenericSlideProps, UiInlineProps, UiSelectorProps, UiSlideProps } from "./types"

export const UiGenericSlide: React.FC<UiGenericSlideProps> = (props) => {
  const items = [...props.items]

  return (
    <Space direction="horizontal" className={styles.ButtonSpacer} size={30}>
      {items.map((item) => item)}
    </Space>
  )
}

export const UiSlide = (props: UiSlideProps): JSX.Element => {
  const length = props.items.length
  const items = [...props.items]
  const addMore =
    length >= ITEMS_PER_CAROUSEL_PAGE / 2 && length < ITEMS_PER_CAROUSEL_PAGE
      ? ITEMS_PER_CAROUSEL_PAGE - length
      : 0
  if (addMore)
    Array.from(Array(addMore)).forEach(() =>
      items.push({ title: "", value: "", action: ACTION.nothing } as Reply)
    )
  const gap = length === 2 ? 90 : 30
  return (
    <Space direction="horizontal" className={styles.ButtonSpacer} size={gap}>
      {items.map((item, i) => {
        const selected = props.selected.includes(item.value)
        return item.ui?.view === "button" ? (
          <UiButton
            key={i}
            item={item}
            disabled={item.action === ACTION.nothing}
            onClick={() => {
              selected ? props.unSelect(item) : props.onSelect(item)
            }}
          />
        ) : (
          <UiFatButton
            key={i}
            disabled={item.action === ACTION.nothing}
            item={item}
            onClick={() => {
              selected ? props.unSelect(item) : props.onSelect(item)
            }}
            selected={selected}
          />
        )
      })}
    </Space>
  )
}

export const UiCheckboxes: React.FC<UiCheckboxesProps> = (
  props
): JSX.Element => {
  return (
    <Space
      direction="vertical"
      size={0}
      style={{
        width: ` ${100 / ITEMS_PER_CAROUSEL_PAGE}%`,
        textAlign: "left",
      }}
    >
      {props.items.map((item: any, i: number) => {
        const selected = props.selected.includes(item.value)
        return (
          <UiCheckbox
            key={i}
            item={item}
            onClick={() => {
              selected ? props.unSelect(item) : props.onSelect(item)
            }}
            selected={selected}
          />
        )
      })}
    </Space>
  )
}
const AdditionalActions: React.FC<ReplyAction & { onClick: () => void }> = (
  props
): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.action === ACTION.redirect) {
      navigate(props.value as string)
    }
  }
  return props.action === ACTION.nothing ? (
    <span className="font-size-small">{props.title}</span>
  ) : (
    <Button type="link" onClick={handleClick}>
      {props?.ui?.view as any === "icon" ? (
        props.icon
      ) : (
        <span className="font-size-small">{props.title}</span>
      )}
    </Button>
  )
}

export const UiFatButton: React.FC<UiButtonProps> = (props): JSX.Element => {
  return (
    <div style={{ position: "relative" }}>
      <FatButton
        onClick={() => props.onClick(props.item)}
        clicked={props.selected}
        type={props.item.ui?.type}
        img={props.item.imgUrl}
        disabled={props.disabled}
        // doNotFormat={true}
      >
        {props.item.title}
      </FatButton>
      {props.item.additionalActions && (
        <div className={styles.AdditionalActionsWrapper}>
          {props.item.additionalActions.map((additionalAction: any, key: any) => (
            <AdditionalActions
              onClick={() => props.onClick(additionalAction)}
              key={key}
              {...additionalAction}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export const UiButton: React.FC<
  Omit<ButtonProps, "onClick"> & { item: Reply; onClick: (item: Reply) => void }
> = (props): JSX.Element => {
  return (
    <Button
      wide="long"
      onClick={() => props.onClick(props.item)}
      type={props.item?.ui?.type}
      disabled={props.disabled}
    >
      {props.item.title}
    </Button>
  )
}
export const UiCheckbox: React.FC<UiCheckboxProps> = (props): JSX.Element => {
  return (
    <Checkbox
      style={{ width: "100%" }}
      checked={props.selected}
      onClick={() => props.onClick(props.item)}
    >
      {props.item.title}
    </Checkbox>
  )
}

export const UiArrow: React.FC<UiArrowProps> = (props): JSX.Element => {
  return (
    <Col
      className={`${styles.AlignMiddle} ${
        props.side === "left" ? styles.AlignRight : styles.AlignLeft
      }`}
      span={2}
    >
      {props.side === "left" ? (
        <CaretLeftOutlined
          className={styles.CarouselArrow}
          onClick={props.onClick}
          hidden={props.hidden}
        />
      ) : (
        <CaretRightOutlined
          className={styles.CarouselArrow}
          onClick={props.onClick}
          hidden={props.hidden}
        />
      )}
    </Col>
  )
}

export const UiSelector = (props: UiSelectorProps): JSX.Element => {
  return (
    <Select
      style={{ width: "100%" }}
      placeholder={languages.select_reason}
      defaultValue={props.selectedValue as string | undefined}
      onChange={(v: string) =>
        props.onSelect(props.items.find((item: any) => item.value === v) as Reply)
      }
    >
      {props.items.map((item: Reply, key: number) => (
        <Select.Option key={key} value={item.value as string}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  )
}

export const UiFooter: React.FC<UiFooterProps> = (props) => {
  return (
    <Buttons style={props.style}>
      {props.children}
      {props.nextVisible ? (
        <Button
          disabled={props.nextDisabled}
          onClick={props.onFinish}
          type="primary"
          wide="long"
        >
          {languages.next}
        </Button>
      ) : null}
    </Buttons>
  )
}

export const UiInline: React.FC<UiInlineProps> = (props): JSX.Element => {
  const texts = splitByChars(props.item.title, 40)
  return (
    <Buttons direction="vertical" style={{ marginTop: "0px" }}>
      <img src={props.imgUrl} alt={props.item.title} />
      <b className={styles.InlineText}>
        {texts.map((text, k) => (
          <div key={k}>{text}</div>
        ))}
      </b>
    </Buttons>
  )
}
