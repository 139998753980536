import React from "react";

import { useNavigate } from "react-router-dom"
import { Card } from "components/new/Card/Card"
import { FormPage } from "components/new/Form/Form"
import { languages } from "lib/languages"
import { capitalize } from "lib/utils"
import { SURVEY_PAGE } from "core/constants";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentSelectedPatientId, getCurrentPatientFirstName,
} from "pages/Survey/services/selector";
import { putRelativeRequest } from "services/relative/actions";
import surveyActions from "pages/Survey/services/actions"
import { withCard, withHeaderAndFooter } from "../../components/hocs"


const SetRelativeGender = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentSelectedRelativeId = useSelector(getCurrentSelectedPatientId) || props.relativeId
    const currentSelectedRelativeFirstName = useSelector(getCurrentPatientFirstName)
    const label = `${capitalize(languages.inputThe("gender"))} ${currentSelectedRelativeFirstName ? `de ${capitalize(currentSelectedRelativeFirstName)}` : ''}`
    const inputs = [
        {
          name: "gender",
          required: true,
          label: label,
          keepLabelFormat: true
        }
    ];
    return (
        <Card
        subtitle={languages.enterRelativeInformations}
      >
        <FormPage
          loading={false}
          inputs={inputs}
          onFinish={(values) => {
            dispatch(putRelativeRequest({...values, id: currentSelectedRelativeId} as any))
            navigate(SURVEY_PAGE)
          }}
          onCancel={() => {
            dispatch(surveyActions.previous())
            navigate(SURVEY_PAGE)
          }}
        />
      </Card>
    )
}

export default withHeaderAndFooter(withCard(SetRelativeGender))