import React, { useState } from "react"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { NirReader } from "../../components/generics"
import { Patient } from "../../types/types"
import { connect, useDispatch } from "react-redux"
import { languages } from "../../lib/languages"

import { Navigate } from "react-router-dom"
import { buttonLayout } from "../../styles/constants"
import { Button, Space } from "antd"
import styles from "../Finale/Finale.module.scss"
import { ReactNode } from "react"
import { CHECK_PHONE } from "../../core/constants"
import {
  authenticateByNirRaw,
  fromNirRequest,
} from "../../services/nir/actions"

export interface LobbyProps {
  checkPhone: () => void
  checkNir: (nir: string) => void
  // launchWebSocket: () => void
  // launchCardVitaleReader: () => void
  setProspect: (prospect: Partial<Patient>) => void
  children?: ReactNode
  authenticateByNir: (nirRaw: any) => void
}

enum Steps {
  WELCOME,
  NIR_AUTOREADER,
  NIR_MANUALINPUT,
  NIR_UNAVAILABLE,
}

const Lobby: React.FC<LobbyProps> = (props: LobbyProps) => {
  const [step, setStep] = useState(Steps.WELCOME)
  const handleAutoReader = () => setStep(Steps.NIR_AUTOREADER)
  const handleManualInput = () => setStep(Steps.NIR_MANUALINPUT)
  const dispatch = useDispatch();

  const handleNirFinish = (prospect: Partial<Patient>) => {
    prospect.nir && dispatch(fromNirRequest(prospect.nir as string))
  }

  const handleNirAvailable = (hasNir: Boolean) => {
    if (!hasNir) setStep(Steps.NIR_UNAVAILABLE)
  }

  return (
    <>
      {step === Steps.WELCOME && <Welcome onContinue={handleAutoReader} />}

      {step === Steps.NIR_AUTOREADER && (
        <NirReader
          hasNir={handleNirAvailable}
          nirCancel={handleManualInput}
          previous={() => setStep(Steps.WELCOME)}
          onFinish={handleNirFinish}
          onAuthenticate={(nirRaw: any) => dispatch(authenticateByNirRaw(nirRaw))}
        />
      )}
      {step === Steps.NIR_UNAVAILABLE && <Navigate to={CHECK_PHONE} state={{step: "PHONE", variant: "phone" }} replace />}
    </>
  )
}

interface NirManualProps {
  onPreviousClick: Function
  onNirAvailable: Function
  onNirResponse: Function
}

const Welcome = ({ onContinue }: any) => {
  return (
    <div className={styles.Finale} data-testid="Finale">
      <img src="/images/smiley.svg" className={styles.Img} alt="" />
      <h1 className="title-card">
        {languages.welcome_to_medadom}
        <br />
        {languages.registery_will_take_3_minutes}
      </h1>
      <div style={{ textAlign: "center" }}>
        <Space size={100}>
          <Button
            {...buttonLayout}
            style={{ float: "right" }}
            type="primary"
            shape="round"
            htmlType="submit"
            onClick={onContinue}
          >
            {languages.continue}
          </Button>
        </Space>
      </div>
    </div>
  )
}

// const LobbyPage = withHeaderAndFooter(withCard(Lobby))
// export default LobbyPage;

export default withHeaderAndFooter(withCard(Lobby));