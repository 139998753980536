import {
  take,
  fork,
  put,
  call,
  all,
  select,
  takeLatest,
} from "redux-saga/effects"
import { DOCTOR_LIST_REQUESTING } from "./constants"
import { doctorListSuccess, doctorListError } from "./actions"
import { request } from "../../lib/request"

import config from "react-global-configuration"
import { ResponseDoctorList } from "../../types/types"
import { getToken } from "../client/selector"
import { handleApiErrors } from "../../lib/api-errors"

async function doctorListApi(
  patientToken: string
): Promise<ResponseDoctorList> {
  const getDoctorsUrlPrefix = config.get("clinic.routes.doctors")
  return await fetch(`/api${getDoctorsUrlPrefix}/available`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": patientToken,
    },
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error
    })
}

function* doctorListFlow() {
  try {
    const patientToken = yield select(getToken)
    const response = yield call(doctorListApi, patientToken)
    yield put(doctorListSuccess(response.doctors))
  } catch (error) {
    // error? send it to redux
    yield put(doctorListError(error))
  }
}

function* doctorWatcher() {
  yield takeLatest(DOCTOR_LIST_REQUESTING, doctorListFlow)
}

export default doctorWatcher
