// In order to perform an action of type LOGIN_REQUESTING
import {
  ADD_ERROR,
  ADD_ONE_RETRY,
  BY_PASS_PHONE_VALIDATION,
  CANCEL_PHONE_VALIDATION,
  CHECK_ADMIN_PASSWORD,
  FIND_ACCOUNT_BY_PHONE_NUMBER,
  FLUSH_ERROR,
  RESEND_CODE,
  SEND_CODE_BY_PHONE_CALL,
  VALIDATE_SMS_CODE,
  VERIFY_REQUESTING,
  VERIFY_ERROR,
  VERIFY_SUCCESS,
  VERIFY_CALL_REQUEST,
  VERIFY_MODAL_PREVIOUS_STEP,
  VERIFY_MODAL_NEXT_STEP,
  VERIFY_MODAL_SET_LOADING,
  VERIFY_MODAL_RESET_STEP,
  SET_IS_PASSWORD_ADMIN_VALID,
} from "./constants"
import { VerifyInfos } from "../../types/types"
import { SET_PHONE } from "../client/constants"
import { Message } from "../../types/new/types/redux"

export const setIsPasswordAdminValid = (isPasswordAdminValid: boolean) => ({
  type: SET_IS_PASSWORD_ADMIN_VALID,
  isPasswordAdminValid,
})

// we need an email and password
export const verifyRequest = (verifyInfos: VerifyInfos) => {
  return {
    type: VERIFY_REQUESTING,
    payload: verifyInfos,
  }
}

export const verifyError = (error: string) => {
  return {
    type: VERIFY_ERROR,
    payload: { error: error },
  }
}

export const verifySuccess = (payload: any) => ({
  type: VERIFY_SUCCESS,
  payload,
})

export const sendCallRequest = () => ({ type: VERIFY_CALL_REQUEST })

export const findAccountByPhoneNumber = (phone: string) => ({
  type: FIND_ACCOUNT_BY_PHONE_NUMBER,
  phone,
})

export const setPhone = (phone: string) => ({
  type: SET_PHONE,
  phone,
})

export const addRetry = (retryType: string) => ({
  type: ADD_ONE_RETRY,
  retryType,
})

interface addErrorType {
  path: string
  error: Message
}

export const addError = (payload: addErrorType) => ({
  type: ADD_ERROR,
  payload,
})

export const flushError = (payload: { path: string }) => ({
  type: FLUSH_ERROR,
  payload,
})

export const cancelPhoneValidation = () => ({
  type: CANCEL_PHONE_VALIDATION,
})

export const validateSMSCode = (code: string) => ({
  type: VALIDATE_SMS_CODE,
  code,
})

export const sendCodeByPhoneCall = () => ({
  type: SEND_CODE_BY_PHONE_CALL,
})

export const resendCode = () => ({
  type: RESEND_CODE,
})

export const byPassPhoneValidation = ({
  phone,
  password,
}: {
  phone: string
  password: string
}) => {
  return {
    type: BY_PASS_PHONE_VALIDATION,
    phone,
    password,
  }
}

export const checkAdminPassword = (password: string) => ({
  type: CHECK_ADMIN_PASSWORD,
  password,
})

export const verifyModalPreviousStep = () => ({
  type: VERIFY_MODAL_PREVIOUS_STEP,
})

export const verifyModalResetStep = () => ({
  type: VERIFY_MODAL_RESET_STEP,
})

export const verifyModalNextStep = () => ({
  type: VERIFY_MODAL_NEXT_STEP,
})

export const setVerifyModalLoadingState = (loading: boolean) => ({
  type: VERIFY_MODAL_SET_LOADING,
  payload: { loading },
})
