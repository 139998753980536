import { FormItemProps, FormProps } from "antd"

import { Patient, Prospect, Relative } from "./entity"

export interface ItemProps extends FormItemProps {
  name?: string
  required?: boolean
  labelHidden?: boolean
  addFilledCondition?: (isFilled: boolean) => void
}

export interface InputObj {
  name: string
  type?: string,
  label?: string | JSX.Element
  required?: boolean
  autoSubmit?: boolean
  default?: {
    value: string
    label: string
    warning?: boolean
  }
  condition?: {
    label?: string,
    filled: InputObj,
    type?: string
  }
}

export interface FormPageProps extends FormProps {
  prospect?: Partial<Relative | Patient | Prospect>
  inputs: InputObj[]
  hidePrevious?: boolean
  onFinish: (values: Partial<Prospect | Relative | Patient>) => void
  onCancel: () => void
  onChange?: (val: any, inputName?: string) => void
  cancelText?: string
  loading?: boolean
  acceptButton?: string
  formRef?: any
  formStepReset?: boolean
  setStep?: number
}

export interface GetInputProps {
  name: string
  visible: boolean
  value?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  label?: string
  hiddenLabel?: boolean
  onChange?: (val: any) => void
  prospect?: Partial<Relative | Patient | Prospect>
  formRef?: any
  addFilledCondition?: (isFilled: boolean) => void
  changeTitle?: (newTitle: string | Element) => void
}

export const enum InputNames {
  BIRTH_COUNTRY = "birth_country",
  BIRTH_LASTNAME = "birth_lastname",
  BIRTH_LOCATION = "birth_location",
  BIRTHDATE = "birthdate",
  INSEE_CODE = "insee_code",
  FIRST_BIRTH_FIRSTNAME = "first_birth_firstname",
  FIRSTNAME = "firstname",
  LASTNAME = "lastname",
}