import { push } from "redux-first-history"
import config from "react-global-configuration"
import { all, put, takeLatest } from "redux-saga/effects"

import { CHECK_PHONE, RETRIEVE_ACCOUNT, SURVEY_PAGE, IS_THIS_YOUR_ACCOUNT, CURRENT_CONSULTATIONS_PAGE } from "../../core/constants"
import { handleGenericApiError } from "../../lib/api-errors"
import { VALIDATION_STEP } from "../../types/contants"
import { Action, GFlow, Message } from "../../types/new/types/redux"
import { NirReaderResponse } from "../../types/new/types/store"
import { ReaderState } from "../../types/sesam"
import { Consultation, ResponseLogin } from "../../types/types"
import { loginSuccess, manualRetrieveAccount } from "../authentication/actions"
import { setPatient, setRelatives, updateProspect } from "../client/actions"
import { doctorListRequest } from "../doctor/actions"
import { cancelSocketNirReader, requestSocketNirReader, resetSocketNirReader } from "../socket/actions"
import { SOCKET_CANCEL_CARD_VITALE_READER, SOCKET_ERROR_CARD_VITALE_READER, SOCKET_INIT_CARD_VITALE_READER, SOCKET_LAUNCH_CARD_VITALE_READER, SOCKET_NIR_READER_RESPONSE, SOCKET_REMOVE_CARD_VITALE_READER, SOCKET_REMOVED_CARD_VITALE_READER, SOCKET_RESET_CARD_VITALE_READER } from "../socket/constants"
import { nirError, nirReaderResponse, resetNirReader, setNirReaderLoading, setNirReaderMessage, setNirReaderState } from "./actions"
import { AUTHENTICATE_FROM_RAW_NIR, CANCEL_NIR_READER, FROM_NIR_REQUESTING, REQUEST_NIR_READER, RESET_NIR_READER } from "./constants"
import { getPatientsFromDataCarteVitale } from "lib/sesam"
import { getConsultations } from "services/authentication/saga"
import Types from "pages/currentConsultations/services/constants"

function* authenticateUserByRawNir(payload: any): any {
  const authHandlerRoute = config.get("clinic.routes.authentication.by_nir.raw")
  return yield fetch(`/api${authHandlerRoute}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      throw error
    })
}

function* nirFlow(nirInBaseResponse: any, manual = false) {
  try {
    if (nirInBaseResponse.statusCode === 404) {
      if (nirInBaseResponse.body.raw && nirInBaseResponse.body.raw !== "") {
        const { customer = {}, relatives = [] } = nirInBaseResponse.body.raw;
        yield put(updateProspect(customer))
        yield put(updateProspect({ relatives: relatives }))
        yield put(updateProspect({ fromNir: true }));
      }
      yield put(
        push(CHECK_PHONE, { step: VALIDATION_STEP.PHONE })
      )
    } else if (nirInBaseResponse.statusCode === 200) {
      if (manual) {
        yield all([
          put(
            manualRetrieveAccount({ type: "NIR", value: nirInBaseResponse.nir })
          ),
          put(push(RETRIEVE_ACCOUNT, {})),
        ])
      } else {
        const patientToken = nirInBaseResponse.headers["x-patient-token"]
        const consultations: Consultation[] = yield getConsultations(patientToken)
        let dataNir = nirInBaseResponse.body
        if (typeof nirInBaseResponse.body === "string") {
          dataNir = JSON.parse(nirInBaseResponse.body)
        }
        yield all([
          put(resetNirReader()),
          put(loginSuccess(dataNir as ResponseLogin)),
          put(setPatient({ ...dataNir.customer, token: patientToken })),
          put(doctorListRequest()),
          put(setRelatives(dataNir.relatives)),
          put({ type: Types.SET_CONSULTATIONS, payload: consultations }),
        ])
        // Check if NIR is from the main patient (exact===true) or from a relative of the main patient (exact===false)
        if (nirInBaseResponse.body.exact === false) { yield put(push(IS_THIS_YOUR_ACCOUNT)) }
        if (consultations.length) {
          yield put(push(CURRENT_CONSULTATIONS_PAGE))
        }
        else { yield put(push(SURVEY_PAGE)) }
      }
    }
  } catch (error) {
    throw error
  }
}

// TODO: types
function* handleManualyGrabNir(nir: string): any {
  const manualyCheckNirUrlPrefix = config.get(
    "clinic.routes.authentication.by_nir.check"
  )
  return yield fetch(`/api${manualyCheckNirUrlPrefix}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-USER-TOKEN": sessionStorage.getItem("X-USER-Token") || "",
      "X-API-TOKEN": `${process.env.REACT_APP_API_TOKEN}`,
    },
    body: JSON.stringify({ nir }),
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      throw error
    })
}

// TODO: types
function* fromNirInputFlow({ payload }: any): any {
  try {
    const nirInBddPayload = yield handleManualyGrabNir(payload.nir)
    yield put(updateProspect({ nir: payload.nir }))
    yield nirFlow({ ...nirInBddPayload, nir: payload.nir as string }, true)
  } catch (error) {
    yield put(
      nirError(typeof error === "string" ? error : "Erreur Réception nir")
    )
  }
}

function* fromRawNirFlow({ payload }: any): any {
  try {
    const nirInBddPayload = yield authenticateUserByRawNir(payload)
    yield nirFlow(nirInBddPayload)
  } catch (error) {
    yield put(nirError(typeof error === "string" ? error : "Erreur Raw nir"))
  }
}

function* cancelFlow(): GFlow<Action<boolean | ReaderState>> {
  yield all([
    put(cancelSocketNirReader()),
    put(setNirReaderState(ReaderState.CANCELED)),
    put(setNirReaderLoading(false)),
  ])
}

function* requestFlow(): GFlow<Action<NirReaderResponse>> {
  yield put(requestSocketNirReader())
}

function* resetFlow() {
  yield all([
    put(resetSocketNirReader()),
    put(setNirReaderState(ReaderState.INITIALIZE)),
  ])
}

function* socketFlow(
  action: Action<NirReaderResponse | Message | ReaderState | boolean | any>
) {
  switch (action.type) {
    case SOCKET_NIR_READER_RESPONSE:
      yield put(updateProspect({ nir: action.payload.patient.nir }))
      yield put(nirReaderResponse(action.payload as NirReaderResponse as any))
      break
    case SOCKET_REMOVE_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.REMOVE))
      break
    case SOCKET_LAUNCH_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.INSERT))
      break
    case SOCKET_CANCEL_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.CANCELED))
      break
    case SOCKET_INIT_CARD_VITALE_READER:
    case SOCKET_RESET_CARD_VITALE_READER:
      yield put(setNirReaderState(ReaderState.INITIALIZE))
      break
    case SOCKET_REMOVED_CARD_VITALE_READER:
      yield all([
        put(setNirReaderState(ReaderState.REMOVED)),
        put(setNirReaderLoading(false)),
      ])
      break
    case SOCKET_ERROR_CARD_VITALE_READER:
      yield all([
        put(setNirReaderState(ReaderState.ERROR)),
        put(setNirReaderMessage(action.payload as Message)),
      ])
      break
  }
}

function* nirWatcher(): any {
  yield takeLatest(FROM_NIR_REQUESTING, fromNirInputFlow)
  yield takeLatest(AUTHENTICATE_FROM_RAW_NIR, fromRawNirFlow)

  //
  yield takeLatest(RESET_NIR_READER, resetFlow)
  yield takeLatest(REQUEST_NIR_READER, requestFlow)
  yield takeLatest(CANCEL_NIR_READER, cancelFlow)
  yield takeLatest(
    [
      SOCKET_NIR_READER_RESPONSE,
      SOCKET_CANCEL_CARD_VITALE_READER,
      SOCKET_ERROR_CARD_VITALE_READER,
      SOCKET_INIT_CARD_VITALE_READER,
      SOCKET_LAUNCH_CARD_VITALE_READER,
      SOCKET_REMOVED_CARD_VITALE_READER,
      SOCKET_REMOVE_CARD_VITALE_READER,
      SOCKET_RESET_CARD_VITALE_READER,
    ],
    socketFlow
  )
}

export default nirWatcher
