import React from "react"
import styles from "./Loader.module.scss"

const Loader: React.FC = () => (
  <div className={styles.Loader} data-testid="Loader">
    <img src="/loaders/logo_gif.gif" alt="" width="100px" />
  </div>
)

export default Loader
