import React from "react"
import { createRoot } from 'react-dom/client';
import { createReduxHistoryContext } from "redux-first-history"
import { HistoryRouter } from "redux-first-history/rr6"
import { createBrowserHistory } from "history"
import config from "react-global-configuration"
import { Provider } from "react-redux"
import { applyMiddleware, compose, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from "redux-saga"

import configuration from "./config"
import Root from "./core/routing"
import createRootReducers from "./services/index.reducers"
import IndexSagas from "./services/index.sagas"
import * as serviceWorker from "./serviceWorker"

import "./index.scss"

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })

config.set(configuration)

// CREATE STORE, HISTORY AND SAGAS
const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error(error, {route: "saga::middleware"})
  },
})


const store = createStore(
  createRootReducers(routerReducer),
  compose(
    process.env.NODE_ENV !== "production"
    ? composeWithDevTools(
      applyMiddleware(sagaMiddleware),
      applyMiddleware(routerMiddleware)
      )
      : applyMiddleware(sagaMiddleware),
      applyMiddleware(routerMiddleware)
      )
)
sagaMiddleware.run(IndexSagas)

export const history = createReduxHistory(store)

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
    <React.StrictMode>
      <Root />
      </React.StrictMode>
    </HistoryRouter>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()