export const GUARD_IS_ADMIN = "GUARD_IS_ADMIN"
export const GUARD_IS_NOT_ADMIN = "GUARD_IS_NOT_ADMIN"

export const GUARD_IS_LOGGED_IN = "GUARD_IS_LOGGED_IN"
export const GUARD_IS_NOT_LOGGED_IN = "GUARD_IS_NOT_LOGGED_IN"

export const GUARD_IS_NOT_VERIFIED = "GUARD_IS_NOT_VERIFIED"
export const GUARD_IS_VERIFIED = "GUARD_IS_VERIFIED"

export const SECONDS_OF_TIMEOUT_NIR = 3
export const NUMBER_OF_TRY_NIR_CALL = 60 / SECONDS_OF_TIMEOUT_NIR

export enum VALIDATION_STEP {
  PHONE = "PHONE",
  NAME = "NAME",
  PASSWORD = "PASSWORD",
  VERIFY_PHONE = "VERIFY_PHONE",
  ENTER_PERSONAL_DATA = "ENTER_PERSONAL_DATA",
  ENTER_EMAIL= "ENTER_EMAIL",
  VERIFY_ACCOUNT_BY_BIRTHDATE = "VERIFY_ACCOUNT_BY_BIRTHDATE",
}