import React from "react"
import { Input as AntdInput, InputProps } from "antd"

const Input = (props: InputProps & any): JSX.Element => {
  return (
    <AntdInput
      {...props}
      style={{ fontSize: "35px", maxWidth: '-webkit-fill-available', color: props.disabled ? "#7A7A7A" : '#1b4f63' }}
      onKeyUp={() => {
        props.onChange && props.onChange(props.value as any)
      }}
    />
  )
}

export default Input
