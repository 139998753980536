import React from "react"
import { Input, InputProps } from "antd"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { useState } from "react"
import { isPasswordValid } from "../../../../lib/form"
import { languages } from "../../../../lib/languages"

const Details = ({ password }: { password: string }) => {
  return (
    <isPasswordValid.Container>
      <ul style={{ margin: 0 }}>
        <isPasswordValid.Contenant
          text={languages.height_char}
          valid={isPasswordValid.isMoreThanEightCharLength(password)}
        />
        <isPasswordValid.Contenant
          text={languages.a_lower_letter}
          valid={isPasswordValid.containOneMinusChar(password)}
        />
      </ul>
      <ul style={{ margin: 0 }}>
        <isPasswordValid.Contenant
          text={languages.a_upper_letter}
          valid={isPasswordValid.containOneCapsChar(password)}
        />
        <isPasswordValid.Contenant
          text={languages.a_number}
          valid={isPasswordValid.containOneNumeric(password)}
        />
      </ul>
    </isPasswordValid.Container>
  )
}
const NewPassword = (
  props: InputProps & { visible?: boolean }
): JSX.Element => {
  const password = (props.value as string) || ""
  const [visible, setVisilibity] = useState(props.visible ?? true)
  if (visible) {
    return (
      <>
        <Input
          {...props}
          suffix={
            <EyeOutlined
              style={{ fontSize: "25px", marginRight: "15px"}}
              onClick={() => {
                setVisilibity(false)
              }}
            />
          }
        />
        <Details password={password} />
      </>
    )
  }

  return (
    <>
      <Input.Password
        {...props}
        iconRender={(visible) =>
          visible ? <EyeOutlined style={{ fontSize: "25px", marginRight: "15px"}} /> : <EyeInvisibleOutlined style={{ fontSize: "25px", marginRight: "15px"}} />
        }
      />
      <Details password={password} />{" "}
    </>
  )
}

export default NewPassword
