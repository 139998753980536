import config from "react-global-configuration"
import { all, AllEffect, call, CallEffect, fork, put, PutEffect, select, SelectEffect, take, takeLatest } from "redux-saga/effects"

import { handleApiErrors, handleGenericApiError } from "../../lib/api-errors"
import { Gender, RelativeRequest, ResponseGetRelatives, ResponseInsertRelative } from "../../types/types"
import { setRelatives } from "../client/actions"
import { getToken } from "../client/selector"
import { getRelativesSuccess, insertRelativeError, insertRelativeSuccess } from "./actions"
import { GET_RELATIVES_REQUESTING, INSERT_RELATIVE_REQUESTING, UPDATE_RELATIVE } from "./constants"
import { request } from "lib/request"
import { displayPregnantQuestion } from "pages/Survey/services/saga"

// @TODO : autorise clinic to PUT secured Customer

// const insertRelativeUrl = "/api/relatives/create"
const getRelativesUrl = "/api/relatives"

function formatPayload(payload: any) {
  const { insee_code, birth_country, birth_location, old_password, ...rest } = payload;
  console.log('insee_code', insee_code)
  return {
    ...rest,
    birth_location: insee_code && insee_code.toString() || "99999",
    firstname: rest.firstname ? rest.firstname : rest.first_birth_firstname,
    lastname: rest.lastname ? rest.lastname : rest.birth_lastname,
  }
}

async function addRelative(relative: any, patientToken: string): Promise<ResponseGetRelatives> {
  const formattedAPIRelative = formatPayload(relative);
  const relativesUrl = config.get("clinic.routes.relatives")
  return await fetch(`api${relativesUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": patientToken,
    },
    body: JSON.stringify({
      ...formattedAPIRelative,
      sex: relative.gender,
      nir: relative.nir ? relative.nir : null,
    }),
  })
    .then(handleGenericApiError)
    .then((res) => {
      return res.json()
    })
    .catch((error) => console.error(error, {
      route: config.get("clinic.routes.relatives")
    }))
}

async function getRelativesApi(): Promise<ResponseGetRelatives> {
  return await fetch(getRelativesUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error
    })
}

function* getRelativesFlow() {
  try {
    const response: ResponseGetRelatives = yield call(getRelativesApi)
    yield put(getRelativesSuccess())
    yield put(setRelatives(response.relatives))
  } catch (error) {
    // error? send it to redux
    yield put(insertRelativeError(error as string))
  }
}
function* insertRelativeFlow({ payload: relative }: { payload: RelativeRequest, type: string }): Generator<SelectEffect | CallEffect | AllEffect<PutEffect> | PutEffect, void, ResponseInsertRelative> {
  const patientToken = yield select((getToken))
  try {
    const responses = yield call(addRelative as any, relative, patientToken)
    if (responses) {
      yield all([
        put(insertRelativeSuccess(responses)),
      ])
    }
  } catch (error) {
    // error? send it to redux
    yield put(insertRelativeError(error as string))
  }
}

function* changeRelativeApi({ payload }: any): any {
  const patientToken = yield select(getToken);
  if (payload.sex === Gender.MALE || payload.gender === Gender.MALE) {
    yield displayPregnantQuestion(Gender.MALE);
  } else {
    yield displayPregnantQuestion(Gender.FEMALE);
  }

  try {
    yield request(`${config.get("clinic.routes.putRelative")}/${payload.id}`, {
      method: "PUT",
      additionalHeaders: {
        "X-Patient-Token": patientToken,
      },
      payload: {
        sex: payload.gender
      },
    })
  } catch (error) {
    console.error(error, {
      route: config.get("clinic.routes.putRelative")
    });
  };
}

function* relativeWatcher() {
  yield takeLatest(INSERT_RELATIVE_REQUESTING, insertRelativeFlow)
  yield takeLatest(GET_RELATIVES_REQUESTING, getRelativesFlow)
  yield takeLatest(UPDATE_RELATIVE, changeRelativeApi)
}

export default relativeWatcher
