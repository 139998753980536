import { InputProps } from "antd"
import InputMask from "react-input-mask"
import React from "react"



import styles from "./Code.module.scss"
import { languages } from "lib/languages"

// CODE_MASK = 9 9 9 9
export interface CodeInputProps extends Omit<InputProps, "onChange"> {
  onChange?: (code: string) => void
  twoFactorAuth?: boolean;
}
const CODE_MASK = `${languages.codeAllowedChar}${languages.codeSpacer}${languages.codeAllowedChar}${languages.codeSpacer}${languages.codeAllowedChar}${languages.codeSpacer}${languages.codeAllowedChar}`

export const Code: React.FC<CodeInputProps> = (props): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = e.target.value.replaceAll("_", "")
    const code = unmaskedValue.replaceAll(languages.codeSpacer, "")
    props.onChange && props.onChange(code)
  }
  return (
    <InputMask
      className={styles.Input}
      alwaysShowMask
      mask={CODE_MASK}
      onChange={onChange}
      value={props.value}
      disabled
    />
  )
}
