import React, { ComponentType, Fragment } from "react"
import { Footer as FooterC } from "../generics"
import { Layout } from "antd"
import styles from "./Hocs.module.scss"

const { Footer, Content } = Layout

function withFooter<T>(WrappedComponent: ComponentType<T>) {
  return (props: T) => (
    <Fragment>
      <Layout className={styles.Layout}>
        <Content>
          <WrappedComponent {...props} />
        </Content>
        <Footer>
          <FooterC />
        </Footer>
      </Layout>
    </Fragment>
  )
}

export default withFooter
