import React, { useEffect } from "react"
import { Gender } from "../../types/types"
import dayjs from "dayjs"
import { DATE_FORMAT, formatNirFromMask, formatDateToYYYYMMDD } from "../../lib/utils"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { useDispatch, useSelector } from "react-redux"
import {
  insertRelativeRequest,
} from "../../services/relative/actions"
import { SURVEY_PAGE } from "../../core/constants"
import { useNavigate } from "react-router-dom"
import { languages } from "../../lib/languages"
import { FormPage } from "../../components/new/Form/Form"
import { Card } from "../../components/new/Card/Card"
import { InputObj } from "../../types/new/types/props"
import { resetRelativeStore } from '../../services/relative/actions'
import { InputNames } from "types/new/types/props"
import { BirthPlace } from "../../types/new/types/entity"



interface InsertRelativeProps {
  insert: (relative: {
    lastname: string
    firstname: string
    birthdate: string
    gender: Gender
  }) => void
  success: boolean
  loading: boolean
  history: any
}


const handleSubmit = ({ nir, birthdate, ...rest }: { nir?: string | boolean | undefined, birthdate?: any, rest?: any }, insert: any) => {
  const formattedBirthdate = dayjs(formatDateToYYYYMMDD(birthdate)).format(DATE_FORMAT)
  const formatNir = formatNirFromMask(nir as string);
  let formatted = {
    ...rest,
    nir: formatNir,
    birthdate: formattedBirthdate,
  }
  insert(formatted)
}

const InsertRelative = () => {
  const props = useSelector(({ relative }: { relative: InsertRelativeProps }) => relative)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.success === true) {
      dispatch(resetRelativeStore())
      navigate(SURVEY_PAGE)
    };
  }, [props.success])

  const inputs: InputObj[] = [
    { name: "gender", required: true, label: languages.inputRelativeGender },
    {
      name: InputNames.FIRST_BIRTH_FIRSTNAME, condition: {
        type: "checkbox", label: languages.relativeUseAnother('Prénom'),
        filled: { name: InputNames.FIRSTNAME, required: true, label: languages.inputRelativeFirstname }
      }, required: true, label: languages.first_birth_firstname_relative,
    },
    {
      name: InputNames.BIRTH_LASTNAME, condition: {
        label: languages.relativeUseAnother('Nom'), type: "checkbox",
        filled: { name: InputNames.LASTNAME, required: true, label: languages.inputRelativeLastname }
      }, label: languages.birth_lastname_relative
    },
    { name: InputNames.BIRTHDATE, required: true },
    {
      name: InputNames.BIRTH_COUNTRY, required: false, type: "hidden", default: {
        value: `${BirthPlace.FRANCE}`, label: "",
      }
    },
    { name: InputNames.BIRTH_LOCATION, required: true, label: languages.birthDateRelative },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: "nir",
      required: false,
      label: languages.inputRelativeNir,
      default: {
        value: "",
        warning: false,
        label: languages.byPassNir,
      },
    },
  ]
  return (
    <>
      <Card>
        <FormPage
          inputs={inputs}
          onFinish={(e) => handleSubmit(e, (relativeValue: any) => dispatch(insertRelativeRequest(relativeValue)))}
          acceptButton={languages.addRelative}
          onCancel={() => {
            navigate(SURVEY_PAGE)
          }}
        />
      </Card>
    </>
  )
}


const InsertRelativePage = withHeaderAndFooter(withCard(InsertRelative))

export default InsertRelativePage as any
