import { Button, Carousel, Checkbox, Col, Form, Row, Select, Space } from "antd"
import { first } from "lodash"
import React, { useEffect, useRef, useState } from "react"

import { FatButton } from "../../../components/generics"
import { languages } from "../../../lib/languages"
import { buttonLayout } from "../../../styles/constants"
import { ITEMS_PER_CAROUSEL_PAGE } from "../models"
import { Reply, UiSlideProps, UiSurvey, Value } from "../types"
import { get_index_of_position, splitCarouselPages } from "../utils"
import styles from "./../styles.module.scss"

export const UiSlide = (props: UiSlideProps): JSX.Element => {
  const length = props.items.length
  const items = [...props.items]
  if (length >= 3 && length % 2 && length < ITEMS_PER_CAROUSEL_PAGE) {
    // IF  =  2 < item.length < ITEMS_PER_CAROUSEL
    for (let i = length; i < ITEMS_PER_CAROUSEL_PAGE; i += 1) {
      items.push({ title: "", value: "", disabled: true } as Reply)
    }
  }
  return (
    <Space direction="horizontal" className={styles.ButtonSpacer} size={30}>
      {items.map((item, i) => {
        const selected = props.selected.includes(item.value)
        return (
          <UiButton
            big={length === 2}
            key={i}
            item={item}
            onClick={() => {
              selected ? props.unSelect(item) : props.onSelect(item)
            }}
            selected={selected}
          />
        )
      })}
    </Space>
  )
}

export const UiCheckboxes = (props: {
  items: Reply[]
  unSelect: (v: Reply) => void
  onSelect: (r: Reply) => void
  selected: Value[]
}) => {
  return (
    <Space direction="vertical" size={30}>
      {props.items.map((item, i) => {
        const selected = props.selected.includes(item.value)
        return (
          <UiCheckbox
            key={i}
            item={item}
            onClick={() => {
              selected ? props.unSelect(item) : props.onSelect(item)
            }}
            selected={selected}
          />
        )
      })}
    </Space>
  )
}

export const UiButton = (props: {
  item: Reply
  onClick: Function
  selected: boolean
  big: boolean
}) => {
  return (
    <FatButton
      big={props.big}
      onClick={() => props.onClick(props.item)}
      clicked={props.selected}
      type={props.item.ui?.style}
      img={props.item.img}
      disabled={props.item.disabled}
      subtext={props.item.subtitle}
    >
        {props.item.title && props.item.title}
    </FatButton>
  )
}

export const UiSelector = (props: {
  items: Reply[]
  onSelect: (v: Reply) => void
  selectedValue?: Value
}) => {
  return (
    <Select
      style={{ width: "100%" }}
      placeholder={languages.select_reason}
      defaultValue={props.selectedValue as string | undefined}
      onChange={(v: string) =>
        props.onSelect(props.items.find((item) => item.value === v) as Reply)
      }
    >
      {props.items.map((item: Reply) => (
        <Select.Option value={item.value as string}>{item.title}</Select.Option>
      ))}
    </Select>
  )
}

export const UiCheckbox = (props: {
  item: Reply
  onClick: Function
  selected: boolean
}) => {
  return (
    <Checkbox
      checked={props.selected}
      onClick={() => props.onClick(props.item)}
    >
      {props.item.title}
    </Checkbox>
  )
}

export const UiArrow = (props: {
  side: "left" | "right"
  onClick: () => void
  hidden: boolean
}) => {
  return (
    <Col
      className={`${styles.AlignMiddle} ${
        props.side === "left" ? styles.AlignRight : styles.AlignLeft
      }`}
      span={2}
    >
      <img
        src={`icons/arrow_${props.side}.svg`}
        alt=""
        className={styles.CarouselArrow}
        onClick={props.onClick}
        hidden={props.hidden}
      />
    </Col>
  )
}

export const UiFooter = (props: {
  nextVisible: boolean
  nextDisabled: boolean
  children: any
}) => {
  return (
    <div className="wrapped-buttons space-around">
      {props.children}
      {props.nextVisible ? (
        <Form.Item>
          <Button
            disabled={props.nextDisabled || false}
            {...buttonLayout}
            type="primary"
            htmlType="submit"
          >
            {languages.next}
          </Button>
        </Form.Item>
      ) : null}
    </div>
  )
}

const getSelectedItemPosition = (selected: Value[], pages: Array<Value[]>) => {
  if(selected.length > 0) {
    return pages.findIndex((e) => e.some((i: any) => i.value == selected[0]));
  }
  return 0;
}

export const UiCarousel = (props: {
  items: Reply[]
  selected: Value[]
  unSelect: (replies: Reply) => void
  onSelect: (replies: Reply) => void
  ui?: UiSurvey
}) => {
  const view = props.ui?.view || "carousel"
  const ref = useRef<any>()
  const [hide, setHide] = useState({ left: true, right: true })
  const pages = splitCarouselPages(props.items, ITEMS_PER_CAROUSEL_PAGE)
  let position = 
  props.selected?.length > 0 ? getSelectedItemPosition(props.selected, pages) :
  props.ui?.position
    ? get_index_of_position(props.ui?.position, pages.length)
    : 0

  const uiCheckboxes: JSX.Element[] = pages.map((page, key) => {
    return (
      <UiCheckboxes
        key={key}
        items={page}
        selected={props.selected}
        onSelect={props.onSelect}
        unSelect={props.unSelect}
      />
    )
  })

  const uiSelector: JSX.Element = (
    <UiSelector
      items={props.items}
      onSelect={props.onSelect}
      selectedValue={first(props.selected) as Value | undefined}
    />
  )

  const uiPages: JSX.Element[] = pages.map((page, key) => {
    return (
      <UiSlide
        key={key}
        items={page}
        selected={props.selected}
        onSelect={props.onSelect}
        unSelect={props.unSelect}
      />
    )
  })

  const previous = () => ref?.current?.prev()

  const next = () => ref?.current?.next()

  const onBeforeChange = (from: number, to: number): void => {
    let _hide = hide
    if (to <= 0) setHide((_hide = { ..._hide, left: true }))
    else setHide((_hide = { ..._hide, left: false }))
    if (to === pages.length - 1) setHide((_hide = { ..._hide, right: true }))
    else setHide((_hide = { ..._hide, right: false }))
  }

  useEffect(() => {
    if (Number(props.items.length) <= ITEMS_PER_CAROUSEL_PAGE)
      setHide({ left: true, right: true })
    else setHide({ left: true, right: false })
  }, [props])

  return (
    <>
      {view === "carousel" ? (
        <Row>
          <UiArrow side="left" onClick={previous} hidden={hide.left} />
          <Col span={20}>
            <Carousel
              beforeChange={onBeforeChange}
              ref={ref}
              dots={false}
              initialSlide={position}
            >
              {uiPages}
            </Carousel>
          </Col>
          <UiArrow side="right" onClick={next} hidden={hide.right} />
        </Row>
      ) : view === "checkbox" ? (
        <div className={styles.CheckboxColumn}>{uiCheckboxes}</div>
      ) : view === "selector" ? (
        <>{uiSelector}</>
      ) : (
        <></>
      )}
    </>
  )
}
