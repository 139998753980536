import {
  INSTALLATION_REQUESTING,
  INSTALLATION_SUCCESS,
  INSTALLATION_ERROR,
  SET_CLINIC,
  SET_PATIENT,
  SET_PROSPECT,
  SET_RELATIVES,
  LOGOUT,
  UPDATE_PROSPECT,
  UPDATE_PATIENT_SAGA,
  UPDATE_PATIENT_STORE,
} from "./constants"
import {
  ResponseInstallation,
  Clinic,
  Patient,
  Relative,
  Prospect,
  UpdatePatientPayloadType,
  Gender,
} from "../../types/types"
import { map } from "lodash"

export const fetchInstallation = () => {
  return { type: INSTALLATION_REQUESTING }
}

export const installationSuccess = (response: ResponseInstallation) => ({
  type: INSTALLATION_SUCCESS,
  payload: response,
})

export const installationError = (error: string) => ({
  type: INSTALLATION_ERROR,
  error,
})

export const setClinic = (clinic: Clinic | null) => ({
  type: SET_CLINIC,
  payload: { pharmacy: clinic },
})

export const setPatient = (patient: Patient | null) => ({
  type: SET_PATIENT,
  payload: { customer: patient },
})
export const setRelatives = (relatives: Relative[] | null) => {
  let formattedRelatives: Relative[] = map(relatives, (relative: Relative) => {
    const { sex, ...rest } = relative
    return {
      gender: sex,
      ...rest,
      birthdate: rest.birthdate.split("-").reverse().join("/"),
    }
  })
  return {
    type: SET_RELATIVES,
    payload: { relatives: formattedRelatives },
  }
}

export const updateProspect = (prospect: Prospect) => ({
  type: UPDATE_PROSPECT,
  payload: { prospect },
})

export const updatePatientStore = (payload: UpdatePatientPayloadType) => ({
  type: UPDATE_PATIENT_STORE,
  payload,
})

export const updatePatientSaga = (payload: UpdatePatientPayloadType) => ({
  type: UPDATE_PATIENT_SAGA,
  payload,
})

export const logout = () => {
  return {
    type: LOGOUT,
  }
}
