import React, { useState, useEffect } from "react"
import { Select } from "antd"
import { languages } from "../../../lib/languages"
import { DATE_FORMAT } from "../../../lib/utils"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(customParseFormat)

const { Option } = Select
const getDayOptions = () => {
  return Array.apply(null, Array(31)).map((value, key) => (
    <Option key={key} value={(key + 1).toString().padStart(2, "0")}>
      {(key + 1).toString().padStart(2, "0")}
    </Option>
  ))
}
const getYearOptions = () => {
  let currentYear = new Date().getFullYear()
  const oldestYear = 1900
  return Array.apply(null, Array(currentYear - oldestYear)).map(() => (
    <Option
      key={(currentYear -= 1)}
      value={currentYear.toString().padStart(4, "0")}
    >
      {currentYear.toString().padStart(4, "0")}
    </Option>
  ))
}
const months = [
  languages.jan,
  languages.feb,
  languages.mar,
  languages.apr,
  languages.may,
  languages.jun,
  languages.jul,
  languages.aug,
  languages.sep,
  languages.oct,
  languages.nov,
  languages.dec,
]

const getMonthOptions = () => {
  return months.map((value, key) => (
    <Option key={key + 1} value={(key + 1).toString().padStart(2, "0")}>
      {value}
    </Option>
  ))
}

const DateInput: React.FC<any> = ({
  initialValue = null,
  readOnly = false,
  onChange,
}: {
  required: boolean
  label: string | null
  value: string | null
  readOnly?: boolean
  initialValue: dayjs.Dayjs | null
  onChange: (e: dayjs.Dayjs) => void
}) => {
  const [dateValues, setDate] = useState<{
    day: string
    month: string
    year: string
  }>({
    day: "JJ",
    month: "MM",
    year: "AAAA",
  })

  useEffect(() => {
    // to initiate value
    if (initialValue && initialValue.isValid()) {
      const newDateValues = {
        day: initialValue.get("date").toString().padStart(2, "0"),
        month: (initialValue.get("month") + 1).toString().padStart(2, "0"),
        year: initialValue.get("year").toString().padStart(4, "0"),
      }
      setDate(newDateValues)
    }
  }, [initialValue])

  const handleChange = (event: string, value: string) => {
    const newDateValues = { ...dateValues, [event]: value }
    setDate(newDateValues)
    onChange(
      dayjs(
        `${newDateValues.day}/${newDateValues.month}/${newDateValues.year}`,
        DATE_FORMAT,
        true
      )
    )
  }
  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <Select
          disabled={readOnly}
          onChange={(e) => handleChange("day", e)}
          style={{ flex: 1 }}
          size="large"
          defaultValue={dateValues.day || languages.selectDayBirthdate}
          value={dateValues.day}
        >
          {getDayOptions()}
        </Select>
        <Select
          disabled={readOnly}
          onChange={(e) => handleChange("month", e)}
          style={{ flex: 1 }}
          size="large"
          defaultValue={dateValues.month || languages.selectMonthBirthdate}
          value={dateValues.month}
        >
          {getMonthOptions()}
        </Select>
        <Select
          disabled={readOnly}
          onChange={(e) => handleChange("year", e)}
          style={{ flex: 1 }}
          size="large"
          defaultValue={dateValues.year || languages.selectYearBirthdate}
          value={dateValues.year}
        >
          {getYearOptions()}
        </Select>
      </div>
    </>
  )
}

export default DateInput
