import { ResponseVerify, VerifyStore } from "../../types/types"
import { CREATE_ACCOUNT } from "../authentication/constants"
import { SET_PATIENT, SET_PHONE, UPDATE_PROSPECT } from "../client/constants"
import {
  ADD_ERROR,
  ADD_ONE_RETRY,
  FIND_ACCOUNT_BY_PHONE_NUMBER,
  FLUSH_ERROR,
  SET_IS_PASSWORD_ADMIN_VALID,
  VERIFY_ERROR,
  VERIFY_MODAL_NEXT_STEP,
  VERIFY_MODAL_PREVIOUS_STEP,
  VERIFY_MODAL_RESET_STEP,
  VERIFY_MODAL_SET_LOADING,
  VERIFY_REQUESTING,
  VERIFY_SUCCESS,
} from "./constants"

const initialState: VerifyStore = {
  success: false,
  loading: false,
  retry: {},
  error: {},
  isAdminPasswordValid: false,
  currentStep: 0,
  modal: {
    loading: false,
  },
}

interface VerifyAction {
  type: string
  payload: ResponseVerify | any
  phone: null
  retryType: string | ""
  isAdminPasswordValid: boolean
  currentStep: number
  modal: {
    loading: boolean
  }
}

const reducer = function verifyReducer(
  state = initialState,
  action: VerifyAction
): VerifyStore {
  let newState = { ...state }
  switch (action.type) {
    case VERIFY_MODAL_RESET_STEP:
      return {
        ...newState,
        currentStep: 0,
      }
    case VERIFY_MODAL_NEXT_STEP:
      return {
        ...newState,
        currentStep: newState.currentStep + 1,
      }
    case VERIFY_MODAL_PREVIOUS_STEP:
      return {
        ...newState,
        currentStep: newState.currentStep - 1,
      }
    case VERIFY_MODAL_SET_LOADING:
      return {
        ...newState,
        modal: {
          loading: action.payload.loading,
        },
      }
    case SET_IS_PASSWORD_ADMIN_VALID:
      return {
        ...newState,
        isAdminPasswordValid: action.isAdminPasswordValid,
      }
    case ADD_ONE_RETRY: {
      const actualRetryNumber = state.retry
        ? state.retry[action.retryType] || 0
        : 0
      return {
        ...newState,
        retry: {
          [action.retryType]: actualRetryNumber + 1,
        },
      }
    }
    case FLUSH_ERROR: {
      const { payload } = action
      return {
        ...newState,
        error: {
          [payload.path]: "",
        },
      }
    }
    case ADD_ERROR: {
      const { payload } = action
      return {
        ...newState,
        loading: false,
        error: {
          [payload.path]: payload.error,
        },
      }
    }
    case CREATE_ACCOUNT:
    case FIND_ACCOUNT_BY_PHONE_NUMBER:
    case VERIFY_REQUESTING:
      return { ...newState, loading: true }
    case UPDATE_PROSPECT:
      return { ...newState, loading: false }
    case VERIFY_SUCCESS:
      return {
        ...newState,
        success: true,
        loading: false,
      }
    case VERIFY_ERROR:
      return {
        ...newState,
        success: false,
        loading: false,
        message: action.payload.error,
      }
    case SET_PATIENT:
      return {
        ...newState,
        phone: action.payload?.customer ? action.payload.customer.phone : null,
      }
    case SET_PHONE:
      return {
        ...newState,
        phone: action.phone,
      }
    default:
      return { ...newState }
  }
}

export default reducer
