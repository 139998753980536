import { put, select, takeLatest } from "redux-saga/effects"
import Types from "./constants"
import { getToken } from "services/client/selector"
import { push } from "redux-first-history"
import { AFTER_CONSULT_UPDATE, FINALE_PAGE } from "core/constants"

interface UpdateConsultationProps {
  consultationId:number
  chosen_doctor_id:number
  cancel?: boolean 
  token:string
}

async function updateConsultationApi({consultationId, chosen_doctor_id, cancel, token}:UpdateConsultationProps){
  return fetch(`/api/consultations/customer/update/${consultationId}`, {
    method: "PUT",
    body: JSON.stringify({
      chosen_doctor_id,
      ...(cancel && {cancel})
    }),
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-PATIENT-Token": token || ""
    },
  })
    .then((res) => {
      return res.json()
    })
    .catch((error) => {
      throw error
    })
}

function* update({payload}: {type: string, payload: UpdateConsultationProps}): Generator<any, void, any> {
  const accessToken: string = yield select(getToken)
  payload.token = accessToken
  const response = yield updateConsultationApi(payload)
  if(response.status==="ok"){
    yield put(push(`${FINALE_PAGE}?${AFTER_CONSULT_UPDATE}`))
  }else{
    console.error('Update Consultation Error', response)
  }
}
/////////////////////////////////////////////////////////////
function* ConsultationsWatcher() {
  yield takeLatest(Types.UPDATE_CONSULTATION, update)
}

export default ConsultationsWatcher
