import React, { useEffect } from "react"
import { Message as MessageInterface } from "../../../types/new/types/redux"
import styles from "./Message.module.scss"
import { notification } from "antd"
import { Information } from "../Title/Title"
interface MessageProps {
  message?: MessageInterface
}

const openNotification = (message: MessageInterface) => {
  notification[message.type]({
    message: "", //getLabelType(message.type),
    className: styles.Message,
    description: <Information text={message.text} />,
  })
}
const Message: React.FC<MessageProps> = ({ message }) => {
  useEffect(() => {
    if (message) openNotification(message as MessageInterface)
  }, [message])
  return <></>
}

export default Message
