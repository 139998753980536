import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { adminLoginRequest } from "../../services/admin/actions"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { Navigate } from "react-router-dom"
import { HOME_PAGE } from "../../core/constants"
import { Input, Divider, Button, Form } from "antd"
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons"
import { formLayout, fixLayout } from "../../styles/constants"
import { languages } from "../../lib/languages"
interface AdminProps {
  success: boolean
  loading: boolean
  adminLogin: (values: { email: string; password: string }) => void
}

class Admin extends Component<any & AdminProps> {
  onLogin = (values: any) => {
    this.props.adminLogin(values)
  }
  onFinishFailed = () => {}
  render = () =>
    this.props.success ? (
      <Navigate to={HOME_PAGE} replace />
    ) : (
      <Fragment>
        <h1 className="title-card">Mon Compte administrateur</h1>
        <div className="card-contenant" data-testid="Admin">
          {this.props.message ? (
            <div
              className={
                !this.props.success ? "error-card" : "information-card"
              }
            >
              {this.props.message}
            </div>
          ) : (
            <div className="information-card">
              {languages.adminInformationWelcome}
            </div>
          )}
          <Divider />
          <Form
            {...formLayout}
            onFinish={this.onLogin}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={languages.email}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Entrez votre e-mail",
                },
                {
                  type: "email",
                  message: `${languages.incorrectInput} ${languages.email}`,
                },
              ]}
            >
              <Input prefix={<UserOutlined {...fixLayout} />} />
            </Form.Item>
            <Form.Item
              label={languages.password}
              name="password"
              rules={[{ required: true, message: "Entrez votre mot de passe" }]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone {...fixLayout} />
                  ) : (
                    <EyeInvisibleOutlined {...fixLayout} />
                  )
                }
              />
            </Form.Item>
            <div className="wrapped-buttons uniq">
              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  disabled={this.props.loading ? true : false}
                  loading={this.props.loading ? true : false}
                >
                  {languages.login}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Fragment>
    )
}

const mapStateToProps = (state: any) => {
  return state.admin
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    adminLogin: ({ email, password }: { email: string; password: string }) =>
      dispatch(adminLoginRequest({ email, password })),
  }
}

const AdminPage = withHeaderAndFooter(withCard(Admin))
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage as any)
