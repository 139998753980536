export const [
  UNSET_QUESTION,
  SET_QUESTION,
  SET_QUESTION_SAGA,
  NEXT_QUESTION,
  PREVIOUS_QUESTION,
  CREATE_QUESTION,
  REMOVE_QUESTION,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_ERROR,
  INIT_SURVEY,
  SET_CUSTOMER,
] = [
  "UNSET_QUESTION",
  "SET_QUESTION",
  "SET_QUESTION_SAGA",
  "NEXT_QUESTION",
  "PREVIOUS_QUESTION",
  "CREATE_QUESTION",
  "REMOVE_QUESTION",
  "SUBMIT_SURVEY_REQUEST",
  "SUBMIT_SURVEY_SUCCESS",
  "SUBMIT_SURVEY_ERROR",
  "INIT_SURVEY",
  "SET_CUSTOMER",
]

export const [
  QUESTION_DOCTORS,
  QUESTION_PATIENT,
  QUESTION_RELATIVES,
  QUESTION_SLOTS,
] = [
  "QUESTION_DOCTORS",
  "QUESTION_PATIENT",
  "QUESTION_RELATIVES",
  "QUESTION_SLOTS",
]
