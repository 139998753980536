import { first } from "lodash"
import { w3cwebsocket as W3CWebSocket } from "websocket"

export interface SocketPayload {
  type: string
  action: string
  body: {
    [x: string]: unknown
  }
}

export enum ReadyState {
  CLOSED = 3,
  OPEN = 1,
}

export const WEBSOCKET_OPEN_STATE = 1

export async function staging(
  client: W3CWebSocket
): Promise<boolean | unknown> {
  console.log("[ creating a new Websocket client...]", client)
  const clientState = new Promise((resolve) => {
    client.onerror = function () {
      return resolve(false)
    }
    client.onopen = function () {
      if (client.readyState === WEBSOCKET_OPEN_STATE) return resolve(true)
      else return resolve(false)
    }
    client.onclose = function () {
      return resolve(false)
    }
  })
  return await clientState
}

export const getWssHost = (): string => {
  return "wss://wss.medadom.com:8080";
}
