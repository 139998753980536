import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { LOBBY_PAGE } from "../../core/constants"
import { languages } from "../../lib/languages"
import { Message } from "../../types/new/types/redux"
import { Card } from "../new/Card/Card"
import { FormPage } from "../new/Form/Form"

interface FormatPhone {
  phone: string
  prefixPhone?: string
}

export interface FormInputPhoneProps {
  onSubmit(event: any): void
  prefilledData: { phone: string }
  error?: Message
  loading?: boolean
}

const FormInputPhone = ({
  onSubmit,
  prefilledData,
  error,
  loading,
}: FormInputPhoneProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const inputsObj = [{ name: "phone", required: true }]
  return (
    <Card message={error}>
      <FormPage
        loading={loading}
        prospect={prefilledData as any}
        inputs={inputsObj}
        acceptButton={languages.continue}
        onFinish={({ phone }: any) => {
          onSubmit(phone)
        }}
        cancelText={languages.cancel}
        onCancel={() => {
          dispatch({ type: "RESET_STORE" })
          navigate(LOBBY_PAGE)
        }}
      />
    </Card>
  )
}

export default FormInputPhone
