import { Message } from "../../types/new/types/redux"
import { NirReaderResponse } from "../../types/new/types/store"
import { ReaderState } from "../../types/sesam"
import { AuthStore } from "../../types/types"
import { ADMIN_LOGOUT } from "../admin/constants"
import { LOGOUT } from "../client/constants"

import {
  FROM_NIR_REQUESTING,
  NIR_READER_RESPONSE,
  RESET_NIR_READER,
  SET_NIR_READER_LOADING,
  SET_NIR_READER_MESSAGE,
  SET_NIR_READER_STATE,
  SET_RAW_NIR,
} from "./constants"

export interface NirStore extends AuthStore {
  // NIR object
  response?: any
  nirReaderResponse?: any
  readerState: ReaderState
  rawNir: any
}
const initialState: NirStore = {
  success: false,
  loading: false,
  readerState: ReaderState.INITIALIZE,
  rawNir: {},
}

interface ResponseAction {
  type: string
  payload: any
}

const reducer = function nirReducer(
  state = initialState,
  action: ResponseAction
) {
  let newState = { ...state }
  switch (action.type) {
    case NIR_READER_RESPONSE:
      return {
        ...state,
        message: undefined,
        response: action.payload as NirReaderResponse,
      }
    case SET_NIR_READER_STATE:
      return {
        ...state,
        message: undefined,
        readerState: action.payload as ReaderState,
      }
    case SET_NIR_READER_LOADING: {
      return {
        ...state,
        loading: action.payload as boolean,
      }
    }
    case SET_NIR_READER_MESSAGE: {
      return {
        ...state,
        message: action.payload as Message,
      }
    }
    case RESET_NIR_READER:
      return { ...initialState }

    // Propre à clinic
    case FROM_NIR_REQUESTING:
      return {
        ...newState,
        loading: true,
      }
    case SET_RAW_NIR:
      return {
        ...newState,
        loading: false,
        rawNir: action.payload,
      }
    case LOGOUT:
    case ADMIN_LOGOUT:
    default:
      return newState
  }
}

export default reducer
