import React, { useState } from "react"
import styles from "./Finale.module.scss"
import { withHeaderAndFooter, withCard } from "../../components/hocs"
import { connect } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AFTER_CONSULT_UPDATE, HOME_PAGE } from "../../core/constants"
import { logout } from "../../services/client/actions"
import config from "react-global-configuration"
import { languages } from "lib/languages"

interface FinaleProps {
  logout: () => void
}

const Finale: React.FC<FinaleProps> = (props: FinaleProps) => {
  const [searchParams] = useSearchParams();
  const hasParam = searchParams.has(AFTER_CONSULT_UPDATE);
  const finalTimer = config.get("finalTimer") || 10
  const [second, setSeconde] = useState<number>(finalTimer)
  const navigate = useNavigate()

  second === finalTimer && props.logout()

  const redirect = () => {
    navigate(HOME_PAGE)
  }

  const timer = setTimeout(() => {
    const newSecond = second - 1
    if (newSecond === 0) {
      clearTimeout(timer)
      redirect()
    } else setSeconde(newSecond)
  }, 1000)

  return (
    <div className={styles.Finale} data-testid="Finale">
      <h1 className="title-card">Merci !</h1>
      <h2 className="title-card">{hasParam? languages.change_has_been_made: languages.go_to_the_secretary}
      </h2>
      <img src="/images/mediksante_final.svg" alt="mediksante" />
    </div>
  )
}

const FinalePage = withHeaderAndFooter(withCard(Finale))

const mapDispatchToProps = (dispach: Function): FinaleProps => {
  return {
    logout: () => dispach(logout()),
  }
}

export default connect(null, mapDispatchToProps)(FinalePage as any)
