import { CaretLeftOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import React, { Component } from "react"
import { formatPhoneNumber } from "react-phone-number-input"
import { connect } from "react-redux"

import secretarySVG from "../../../assets/icons/secretary.svg"
import { languages } from "../../../lib/languages"
import { byPassPhoneValidation, cancelPhoneValidation, checkAdminPassword, flushError, resendCode, sendCodeByPhoneCall, verifyModalNextStep, verifyModalPreviousStep, verifyModalResetStep } from "../../../services/verify/actions"
import { Message } from "../../../types/new/types/redux"
import PreviousButton from "../../new/Previous/PreviousButton"
import FatButton from "../FatButton/FatButton"
import CheckAdminPassword from "./CheckPassword"
import PhoneStepContent from "./PhoneStepContent"
import styles from "./VerifyModal.module.scss"

const currentPath = `/${languages.page_verify_phone}-modal`

interface VerifyModalProps {
  error?: Message
  closeModal: () => void
  phone: string | ""
  onResendCode: () => void
  onAdminByPass: (e: any) => void
  onCheckAdminPassword: (password: string) => void
  onReEnterPhone: () => void
  onVoiceCallAsked: () => void
  retryNumber: number | 0
  visible: boolean
  flushError: (currentPath: string) => void
  isAdminPasswordValid: boolean
  currentStep: number
  nextStep: () => void
  previousStep: () => void
  loading: boolean
  resetStep: () => void
}

class VerifyModal extends Component<
  VerifyModalProps,
  { adminPassword?: string }
> {
  state: { adminPassword: string } = {
    adminPassword: "",
  }

  render = () => {
    const steps = [
      {
        content: (
          <div>
            <div className="title-card">
              {languages.verification_of} <br />
              <span className={styles.Phone}>
                {formatPhoneNumber(this.props.phone)}
              </span>
            </div>
            <div className="wrapped-buttons">
              {this.props.retryNumber === 0 && (
                <FatButton
                  big={true}
                  onClick={() => {
                    this.props.onResendCode()
                    this.props.closeModal()
                  }}
                >
                  {languages.resend_code}
                </FatButton>
              )}
              {this.props.retryNumber === 1 && (
                <FatButton
                  big={true}
                  onClick={() => {
                    this.props.onVoiceCallAsked()
                    this.props.closeModal()
                  }}
                >
                  {languages.ask_for_voiceCall}
                </FatButton>
              )}
              <FatButton big={true} onClick={() => this.props.onReEnterPhone()}>
                {languages.re_enter_phone}
              </FatButton>
            </div>
          </div>
        ),
        footer: (
          <div className={styles.FooterModal}>
            <span className={styles.ContactSecretary}>
              {languages.contact_secretary}
            </span>
          </div>
        ),
      },
      {
        content: (
          <CheckAdminPassword
            {...this.props}
            onSubmit={(adminPassword: string) => {
              this.setState({ adminPassword })
            }}
          />
        ),
        footer: null,
      },
      {
        content: (
          <PhoneStepContent
            {...this.props}
            adminPassword={this.state.adminPassword}
          />
        ),
        footer: null,
      },
    ]
    return (
      <Modal
        title={
          <div
            style={{
              display: "flex",
              alignContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <PreviousButton
                onClick={() => {
                  if (this.props.currentStep === 0) {
                    this.props.closeModal()
                  } else {
                    this.props.flushError(`${currentPath}`)
                    this.props.previousStep()
                  }
                }}
              />
            </div>
            {this.props.currentStep === 0 ? (
              <div onClick={() => this.props.nextStep()}>
                <img height="50" src={secretarySVG} alt="" />
              </div>
            ) : null}
          </div>
        }
        footer={steps[this.props.currentStep].footer}
        closable={false}
        open={this.props.visible}
        width={1000}
      >
        {steps[this.props.currentStep].content}
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  currentStep: state.verify.currentStep,
  error: state.verify.error?.[`${currentPath}`] || {},
  isAdminPasswordValid: state.verify.isAdminPasswordValid,
  loading: state.verify.modal.loading,
  phone: state.client?.prospect?.phone,
  retryNumber: state.verify.retry.phone || 0,
})

const mapDispatchToProps = (dispatch: Function) => ({
  onResendCode: () => dispatch(resendCode()),
  onVoiceCallAsked: () => {
    dispatch(sendCodeByPhoneCall())
  },
  onCheckAdminPassword: (password: string) => {
    dispatch(checkAdminPassword(password))
  },
  onAdminByPass: ({ phone, password }: { phone: string; password: string }) => {
    dispatch(byPassPhoneValidation({ phone, password }))
  },
  onReEnterPhone: () => {
    dispatch(cancelPhoneValidation())
  },
  nextStep: () => {
    dispatch(verifyModalNextStep())
  },
  previousStep: () => {
    dispatch(verifyModalPreviousStep())
  },
  resetStep: () => {
    dispatch(verifyModalResetStep())
  },
  flushError: (path: string) => {
    dispatch(flushError({ path }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyModal)
