import dayjs from "dayjs"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouterState } from "redux-first-history"

import PersonalDataForm from "../../components/Form/PersonalData"
import FormInputPhone, {
  FormInputPhoneProps,
} from "../../components/Form/Phone"
import VerifyComponent from "../../components/generics/Verify/Verify"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { DATE_FORMAT, formatDateToYYYYMMDD } from "../../lib/utils"
import {
  createAccountWorkflow,
  retrieveAccountByNameAndNir,
  retrieveAccountByNameAndPhone,
} from "../../services/authentication/actions"
import { updateProspect } from "../../services/client/actions"
import { findAccountByPhoneNumber } from "../../services/verify/actions"
import { validateSMSCode } from "../../services/verify/actions"
import { VALIDATION_STEP } from "../../types/contants"
import { PersonalDataFormProps, VerifyFormProps } from "../../types/Form"

const formSteps: FormSteps = {
  [VALIDATION_STEP.PHONE]: ({
    onSubmit,
    prefilledData,
    loading,
  }: FormInputPhoneProps) => (
    <FormInputPhone
      onSubmit={onSubmit}
      loading={loading}
      prefilledData={prefilledData}
    />
  ),
  [VALIDATION_STEP.VERIFY_PHONE]: ({
    phone,
    onCancel,
    onSubmit,
    error,
  }: VerifyFormProps) => (
    <VerifyComponent
      phone={phone}
      error={error}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  ),
  [VALIDATION_STEP.ENTER_PERSONAL_DATA]: ({
    onSubmit,
    prefilledData,
    error,
    loading,
  }: PersonalDataFormProps) => (
    <PersonalDataForm
      onSubmit={onSubmit}
      loading={loading}
      prefilledData={prefilledData}
      error={error}
    />
  ),
}

interface AnonymousFunc {
  (props: any): JSX.Element
}

// TODO : (FIX) > any is set otherwise typescript can't call function as not all item are callable
interface FormSteps {
  [x: string]: JSX.Element | AnonymousFunc | any
}

const RetrieveStepProps = (
  step: VALIDATION_STEP,
  pathname: string,
  variant: "phone" | "nir"
) => {
  const dispatch = useDispatch()

  const prefilledData = useSelector((state: any) => state.client.prospect || {})
  const error = useSelector((state: any) => state.verify.error?.[pathname])
  const loading = useSelector((state: any) => state.verify.loading)
  const phone = useSelector((state: any) => state.client.prospect?.phone || "")
  const retryType = step === VALIDATION_STEP.NAME ? "lastname" : "phone"
  const retryNumber = useSelector(
    (state: any) => state.verify.retry[retryType] || 0
  )
  if (step === VALIDATION_STEP.ENTER_PERSONAL_DATA) {
    return {
      error,
      loading,
      prefilledData,
      onSubmit: (event: any) => {
        let payload = event
        const { birthdate } = event
        const formattedBirthdate = formatDateToYYYYMMDD(birthdate)
        payload = {
          ...payload,
          birthdate: dayjs(formattedBirthdate).format(DATE_FORMAT),
        }
        dispatch(
          updateProspect({
            ...event,
            birthdate: dayjs(formattedBirthdate).format(DATE_FORMAT),
          })
        )
        dispatch(createAccountWorkflow(payload))
      },
    }
  }

  if (step === VALIDATION_STEP.PHONE) {
    return {
      loading,
      prefilledData,
      onSubmit: (phone: string) => {
        dispatch(findAccountByPhoneNumber(phone))
      },
    }
  }

  if (step === VALIDATION_STEP.NAME) {
    return {
      error,
      phone,
      loading,
      retryNumber,
      variant,
      prefilledData,
      onSubmit: (event: any) => {
        if (variant === "phone") dispatch(retrieveAccountByNameAndPhone(event))
        if (variant === "nir") dispatch(retrieveAccountByNameAndNir(event))
      },
    }
  }
  if (step === VALIDATION_STEP.VERIFY_PHONE) {
    return {
      phone: phone,
      error: error,
      loading,
      retryNumber,
      prefilledData,
      onSubmit: (code: string) => {
        dispatch(validateSMSCode(code))
      },
    }
  }
}

interface Router {
  pathname: string
  state: {
    step: VALIDATION_STEP
    variant: "phone" | "nir"
  }
}

const Validator = (props: any) => {
  const { pathname, state } = useSelector(
    ({ router }: { router: RouterState }) => router?.location
  ) as Router
  let step = VALIDATION_STEP.PHONE;
  let variant : 'phone' | 'nir' = 'phone';
  if(state) {
    ({ step = VALIDATION_STEP.PHONE, variant } = state)
  }

  let componentProps = { pathname }
  const additionalProps = RetrieveStepProps(step, pathname, variant)
  componentProps = { ...componentProps, ...additionalProps }
  return (
    <Fragment>
      {typeof formSteps[step] === "function"
        ? formSteps[step](componentProps)
        : formSteps[step]}
    </Fragment>
  )
}

export default withHeaderAndFooter(withCard(Validator))
