import React from "react"
import { TitleProps } from "antd/lib/typography/Title"
import TextTransition from "react-text-transition"
import styles from "./Title.module.scss"

const style = (type: string): string => {
  switch (type) {
    case "error":
      return styles.Error
    case "information":
      return styles.Information
    case "warning":
      return styles.Warning
    case "success":
      return styles.Success
    default:
      return ""
  }
}

export const Title: React.FC<{
  text?: JSX.Element | string
  type?: string
  size?: "small" | "medium" | "large"
}> = (props): JSX.Element => (
  <h1
    className={`${styles.Title} ${style(props?.type || "")} ${
      props.size
        ? props.size === "small"
          ? styles.Small
          : styles.Medium
        : null
    }`}
  >
    {props.text}
  </h1>
)
export const Subtitle: React.FC<{
  text: string
  style?: any
  className?: string
}> = (props): JSX.Element => (
  <Information
    style={props.style}
    className={styles.Subtitle}
    text={
      <TextTransition
        className={props.className}
        text={props.text}
        springConfig={{ mass: 1, tension: 1000, friction: 100 }}
      />
    }
  />
)

export const Important: React.FC<{
  text: string | JSX.Element
  shape?: boolean
}> = (props): JSX.Element => (
  <h1
    className={`${styles.Important} ${
      props.shape ? styles.ImportantShape : ""
    }`}
  >
    {props.text}
  </h1>
)

export const Information: React.FC<
  {
    text: string | JSX.Element
    shape?: boolean
  } & TitleProps
> = (props): JSX.Element => (
  <h1
    className={`${styles.Information} ${
      props.shape ? styles.InformationShape : ""
    }`}
  >
    {props.text}
  </h1>
)
