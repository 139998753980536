import { Space } from "antd"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import icon_info from "../../assets/icons/info_icon.svg"
import icon_kid_alt from "../../assets/images/kid_badass.svg"
import icon_kid from "../../assets/images/kid_quiet.svg"
import { LOBBY_PAGE } from "../../core/constants"
import { languages } from "../../lib/languages"
import { formatDateToYYYYMMDD } from "../../lib/utils"
import styles from "../../pages/Finale/Finale.module.scss"
import { buttonLayout } from "../../styles/constants"
import { PersonalDataFormProps } from "../../types/Form"
import { BirthPlace, Prospect } from "../../types/new/types/entity"
import { Button } from "../new/Button"
import { Card } from "../new/Card/Card"
import { FormPage } from "../new/Form/Form"
import useOurForm from "../new/Form/useForm"
import { ConsentModal, InfoModal } from "../new/Modal/Modal"
import { FormReview } from "./FormReview"
import { isBirthdateBetween } from "../../lib/form"
import { InputNames } from "types/new/types/props"

const PersonalDataForm = ({
  onSubmit,
  prefilledData,
  error,
  loading,
}: PersonalDataFormProps) => {
  const MINOR_AGE = 16
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [prospect, setProspect] = useState({})
  const [consentModalVisible, setConsentModalVisible] = useState(false)
  const [ageWarningVisible, setAgeWarningVisible] = useState(false)
  const [stepReset, setFormStepReset] = useState(false)
  const [nirFormReview, setNirFormReview] = useState(false)
  const [step, setStep] = useState(0)
  const formRef = useOurForm()

  useEffect(() => {
    if (prefilledData && !loading) {
      if (prefilledData?.firstname && prefilledData?.lastname) {
        setNirFormReview(true)
      }
    }
  }, [prefilledData])
  const saveProspect = (prospect: Prospect) => setProspect(prospect)
  const askConsent = () => setConsentModalVisible(true)

  const inputsObj = [
    { name: "gender", required: true, autoSubmit: true, },
    {
      name: InputNames.FIRST_BIRTH_FIRSTNAME, condition: {
        type: "checkbox", label: languages.useAnother('Prénom'),
        filled: { name: InputNames.FIRSTNAME, required: true }
      }, required: true,
    },
    {
      name: InputNames.BIRTH_LASTNAME, condition: {
        label: languages.useAnother('Nom'), type: "checkbox",
        filled: { name: InputNames.LASTNAME, required: true }
      },
      required: true
    },
    { name: InputNames.BIRTHDATE, required: true },
    {
      name: InputNames.BIRTH_COUNTRY, required: false, type: "hidden", default: {
        value: `${BirthPlace.FRANCE}`, label: "",
      }
    },
    { name: InputNames.BIRTH_LOCATION, required: true },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: "email",
      required: true,
      label: languages.provideYourMail,
      default: {
        warning: true,
        value: "noemail@medadom.com",
        label: languages.byPassEmail,
      },
    },
    {
      name: "new-password",
      label: languages.provideYourPassword,
      required: true,
    },
  ]

  const biRef = {
    closeWarning: () => { },
  }

  const closeWarning = () => {
    biRef.closeWarning()
  }
  return (
    <Card message={error}>
      {nirFormReview ? (
        <FormReview
          biRef={biRef}
          loading={loading}
          error={error}
          onCancel={() => {
            dispatch({ type: "RESET_STORE" })
            navigate(LOBBY_PAGE)
          }}
          initialValues={{ ...(prefilledData as any) }}
          onFinish={(values) => {
            saveProspect(values as Prospect)
            askConsent()
          }}
          modifyWarning={
            <div className={styles.Finale} data-testid="Finale">
              <img
                src={icon_kid}
                style={{ marginTop: "80px", marginBottom: "20px" }}
                alt=""
              />
              <h1 className="title-card" style={{ marginBottom: "30px" }}>
                {languages.signupKidWarning}
              </h1>
              <div className={styles.WarningMessage}>
                <img
                  src={icon_info}
                  alt=""
                  width="40px"
                  style={{ marginRight: ".25em" }}
                />
                <span>{languages.signupKidInfo}</span>
              </div>
              <br />
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                <Space size={100}>
                  <Button
                    {...buttonLayout}
                    style={{ float: "right" }}
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    onClick={closeWarning}
                  >
                    {languages.doSignup}
                  </Button>
                </Space>
              </div>
            </div>
          }
        />
      ) : (
        <>
          <FormPage
            loading={loading}
            inputs={inputsObj}
            formStepReset={stepReset}
            formRef={formRef}
            acceptButton={languages.register}
            setStep={step}
            onCancel={() => {
              dispatch({ type: "RESET_STORE" })
              navigate(LOBBY_PAGE)
            }}
            onFinish={(event) => {
              saveProspect(event as Prospect)
              askConsent()
            }}
            onChange={(vals: any, inputName: any) => {
              if (inputName === "birthdate" && vals.length === 8) {
                const formatedDate = formatDateToYYYYMMDD(vals)
                if (isBirthdateBetween(dayjs(formatedDate), 0, MINOR_AGE)) {
                  dayjs.extend(relativeTime)
                  setAgeWarningVisible(true)
                }
              }
            }}
          />
          <InfoModal
            visible={ageWarningVisible}
            title={languages.minorSignupTitle}
            onCancel={() => setAgeWarningVisible(false)}
            onAccept={() => {
              setAgeWarningVisible(false)
              formRef.reset()
              // setFormStepReset(!stepReset)
            }}
            acceptText={languages.understood}
            altAcceptText={languages.imAmMinor}
            onAltAccept={() => {
              setStep(4)
              setAgeWarningVisible(false)
            }}
            picto={icon_kid_alt}
            message={languages.minorSignupText}
          />
        </>
      )}
      <ConsentModal
        loading={loading}
        visible={consentModalVisible}
        onCancel={() => {
          setConsentModalVisible(false)
        }}
        onAccept={() => {
          if (prospect) onSubmit(prospect)
          setConsentModalVisible(false)
        }}
      />
    </Card>
  )
}

export default PersonalDataForm
