export const ADD_ERROR = "ADD_ERROR"
export const ADD_ONE_RETRY = "ADD_ONE_RETRY"
export const BY_PASS_PHONE_VALIDATION = "BY_PASS_PHONE_VALIDATION"
export const CANCEL_PHONE_VALIDATION = "CANCEL_PHONE_VALIDATION"
export const CHECK_ADMIN_PASSWORD = "CHECK_ADMIN_PASSWORD"
export const FIND_ACCOUNT_BY_PHONE_NUMBER = "FIND_ACCOUNT_BY_PHONE_NUMBER"
export const FLUSH_ERROR = "FLUSH_ERROR"
export const RESEND_CODE = "RESEND_CODE"
export const SEND_CODE_BY_PHONE_CALL = "SEND_CODE_BY_PHONE_CALL"
export const VALIDATE_SMS_CODE = "VALIDATE_SMS_CODE"
export const VERIFY_REQUESTING = "VERIFY_REQUESTING"
export const VERIFY_SUCCESS = "VERIFY_SUCCESS"
export const VERIFY_ERROR = "VERIFY_ERROR"
export const VERIFY_CALL_REQUEST = "VERIFY_CALL_REQUEST"
export const VERIFY_MODAL_NEXT_STEP = "VERIFY_MODAL_NEXT_STEP"
export const VERIFY_MODAL_PREVIOUS_STEP = "VERIFY_MODAL_PREVIOUS_STEP"
export const VERIFY_MODAL_RESET_STEP = "VERIFY_MODAL_RESET_STEP"
export const VERIFY_MODAL_SET_LOADING = "VERIFY_MODAL_SET_LOADING"
export const SET_IS_PASSWORD_ADMIN_VALID = "SET_IS_PASSWORD_ADMIN_VALID"
