import { Button, Space } from "antd"
import Form from "antd/lib/form"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { isValidPhoneNumber } from "react-phone-number-input"

import PhoneInput from "../../new/Input/Phone/Phone"

import { languages } from "../../../lib/languages"
import { buttonLayout, formLayout } from "../../../styles/constants"
import { PhoneStepContentProps } from "../../../types/Form"

const PhoneStepContent = (props: PhoneStepContentProps) => {
  const [doesPhoneMatchUserPhone, setDoesPhoneMatchUserPhone] = useState(true)
  const userPhone = useSelector((state: any) => state.client?.prospect.phone)
  const hasError = props.error?.text ? true : false
  const error = !doesPhoneMatchUserPhone
    ? languages.phoneMismatch
    : props.error?.text
  const handleReEnterPhoneFn = () => {
    props.onReEnterPhone()
    props.resetStep()
  }

  return (
    <div>
      <div className="title-card">{languages.ask_for_phone_re_enter}</div>
      {error && <div className="error-card">{error}</div>}
      <br />
      <Form
        {...formLayout}
        onFinish={(event) => {
          const { phone } = event
          if (userPhone === phone) {
            props.onAdminByPass({
              phone,
              password: props.adminPassword,
            })
          }
        }}
      >
        <Form.Item name="phone">
          <PhoneInput
            // extra={languages.reEnterPreviousPhone}
            onChange={(value?: string | "") => {
              if (value && isValidPhoneNumber(value)) {
                if (value !== userPhone) {
                  setDoesPhoneMatchUserPhone(false)
                } else {
                  setDoesPhoneMatchUserPhone(true)
                }
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Space size={100}>
              {hasError || !doesPhoneMatchUserPhone ? (
                <Button
                  {...buttonLayout}
                  type="primary"
                  onClick={handleReEnterPhoneFn}
                >
                  {languages.re_enter_phone}
                </Button>
              ) : (
                <Button {...buttonLayout} type="primary" htmlType="submit">
                  {languages.continue}
                </Button>
              )}
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PhoneStepContent
