export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR"
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE =
  "RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE"
export const RETRIEVE_ACCOUNT_BY_NAME_AND_NIR =
  "RETRIEVE_ACCOUNT_BY_NAME_AND_NIR"
export const RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE =
  "RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE"
export const RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR =
  "RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR"

export const MANUAL_AUTHENTICATION = "MANUAL_AUTHENTICATION"
export const RETRIEVE_ACCOUNT_SUCCESS = "RETRIEVE_ACCOUNT_SUCCESS"
export const RETRIEVE_ACCOUNT_ERROR = "RETRIEVE_ACCOUNT_ERROR"
export const RETRIEVE_ACCOUNT_PREVIOUS_STEP = "RETRIEVE_ACCOUNT_PREVIOUS_STEP"
