import { cloneDeep, concat, first, flatten, uniq } from "lodash"
import React from "react"

import { getStyleText } from "../../../components/generics/FatButton/FatButton"
import stylesFatButton from "../../../components/generics/FatButton/FatButton.module.scss"
import { languages } from "../../../lib/languages"
import { Doctor, Patient, Relative } from "../../../types/types"
import { NUMBER_OF_SLOTS_AFTER_NOW, NUMBER_OF_SLOTS_BEFORE_NOW, PATH, PATH_DEEP, QUESTIONS, surveyLanguages } from "../models"
import { ACTION, Answer, Question, Reply, SurveyStore, Value } from "../types"
import { get_complete_path, getSlotsReplies, is_deep_path, recursive_get, replace_question, update_answers, update_path } from "../utils"
import { CREATE_QUESTION, INIT_SURVEY, NEXT_QUESTION, PREVIOUS_QUESTION, QUESTION_DOCTORS, QUESTION_PATIENT, QUESTION_RELATIVES, QUESTION_SLOTS, REMOVE_QUESTION, SET_CUSTOMER, SET_QUESTION, UNSET_QUESTION } from "./constants"
import { DOCTOR_CATEGORY_LABEL } from 'lib/utils'

//  path: ['patient','drug',
//   'drug:since_drug','operation','illness','illness:illness_details','pregnant_or_breastfeeding'
// ]
/*
 * function set(), unset(), next(), previous(), cancel(), finish(), create(), get(),
 * create question regarding job side [slots(), relatives(), doctors()]
 * function utils like init(store)
 *
 *
 *
 *
 */
const MANAGER: SurveyStore = {
  questions: QUESTIONS,
  current: undefined,
  path: undefined,
  status: "on_going",
  answers: [],
}

/*
 * init question regarding the path
 */
const init = (manager: SurveyStore = MANAGER) => {
  const parentIds: string[] = manager.questions.map(({ id }) => id)
  const current = first(manager.questions)
  return { ...manager, path: parentIds, answers: [], current }
}
const reducer = (
  manager = init(MANAGER),
  action: { type: string; payload: any }
) => {
  const _path: string[] = manager.path || ["unknow-current"]
  const _questions: Question[] = manager.questions
  const _answers: Answer[] = manager.answers
  const _current: Question | Partial<Question> = manager.current || {
    id: "unknow-current",
    subquestion: undefined,
  }
  const payload = action.payload

  const customer = (customer: "patient" | "relative") => {
    const new_questions = _questions.map((question) => {
      const questionLanguages: any = surveyLanguages.find(
        ({ id }) => id === question.id
      )

      const title =
        questionLanguages && questionLanguages[customer]
          ? questionLanguages[customer]?.title
          : question.title

      const titleDefaultReply =
        questionLanguages &&
          questionLanguages[customer] &&
          questionLanguages[customer]?.defaultReply
          ? questionLanguages[customer]?.defaultReply
          : question.defaultReply?.title

      let defaultReply = question.defaultReply
        ? {
          ...question.defaultReply,
          title: titleDefaultReply,
        }
        : undefined

      return {
        ...question,
        title,
        defaultReply,
      }
    })

    return { ...manager, questions: new_questions }
  }
  /*
   * get question from pathid
   */
  const get = (path_id: string): Question | undefined => {
    const complete_path = get_complete_path(path_id)
    let question: Question | undefined = _questions.find(({ id }) =>
      complete_path.includes(id)
    )
    if (complete_path.length === 1) return question
    return question ? recursive_get(question, complete_path) : undefined
  }

  /*
   * init question regarding the path
   */
  const set = (replies: Reply[], currentId: string) => {
    let answers: Answer[] = cloneDeep(_answers)

    let freshValues: Value[] = replies.map(({ value }) => value)

    answers = update_answers(answers, {
      questionId: currentId,
      value: freshValues,
    })

    return {
      ...manager,
      answers,
      path: update_path(
        replies as Reply[],
        _path as string[],
        _current as Question
      ),
    }
  }

  /*
   * Unset question regarding the path
   */
  const unset = (unReplies: Reply[] | undefined, currentId: string) => {
    if (!unReplies) return { ...manager, answers: [] }

    const toRemove: Value[] = unReplies.map(({ value }) => value)

    let answers: Answer[] = cloneDeep(_answers).map(
      ({ questionId, value }: Answer) => {
        if (questionId === currentId) {
          return {
            questionId,
            value: value?.filter((v) => !toRemove.includes(v)) || [],
          }
        } else return { questionId, value }
      }
    )

    let path = cloneDeep(_path)

    unReplies.map((reply) => {
      if (
        reply.action === ACTION.subquestion &&
        _current?.subquestion?.id &&
        path.includes(_current?.subquestion?.id)
      )
        path.splice(path.indexOf(_current.subquestion.id), 1)
    })

    return { ...manager, answers, path }
  }

  /*
   * Next question regarding the path
   */
  const next = () => {
    const indexOfCurrent = _path.indexOf(_current.id || "")
    const next: string | undefined = _path[indexOfCurrent + 1]
    const current = next ? get(next) : undefined
    if (current) return { ...manager, current }
    return finish()
  }

  /*
   * Retourne la question d'avant
   */
  const previous = () => {
    const indexOfCurrent = _path.indexOf(_current.id || "")

    const newPath = _path.filter(
      (p) => p !== _current.id || !_current.id.includes(PATH_DEEP)
    )

    const newAnswers = _answers.filter(
      (answer) => _current.id !== answer.questionId
    )

    const previous: string | undefined = _path[indexOfCurrent - 1]

    const newCurrent = previous ? get(previous) : undefined

    if (newCurrent)
      return {
        ...manager,
        current: newCurrent,
        path: newPath,
        answers: newAnswers,
      }
    return cancel()
  }

  /*
   * Créer la question "quel proche ?"
   */
  const relatives = ({ relatives }: { relatives: Relative[] }) => {
    let relativeQuestion: Question = get(PATH.relative) as Question

    relativeQuestion.replies = relatives.map((r: Relative) => {
      const reply: Reply = {
        title: `${r.firstname} ${r.lastname}`,
        value: r.id,
      }
      return reply
    })
    return create(relativeQuestion as Question)
  }

  /*
   * Créer la question docteur
   */
  const doctors = ({ doctors }: { doctors: Doctor[] }) => {
    let doctorQuestion: Question = get(PATH.doctor) as Question
    doctorQuestion.replies = doctors.map((doctor) => {
      return {
        value: doctor.id,
        title: `${doctor.category_id === 1 ? "Dr" : doctor.firstname?.[0].toUpperCase()}. ${doctor.lastname} `,
        action: ACTION.subquestion,
        subtitle: DOCTOR_CATEGORY_LABEL[doctor.category_id || 1]
      }
    })
    return create(doctorQuestion)
  }
  /*
   * Créer la question parent
   */
  const patient = ({ patient }: { patient: Patient }) => {
    let patientQuestion: Question = get(PATH.patient) as Question

    patientQuestion.replies = [
      {
        title: (
          <>
            <span className={stylesFatButton.SmallText}>{languages.forMe}</span>
            <span
              className={getStyleText(
                `(${patient.firstname} ${patient.lastname})`
              )}
            >
              ({patient.firstname} {patient.lastname})
            </span>
          </>
        ) as any,
        value: 0,
        ui: { style: "accent" },
      },
      {
        value: -1,
        title: (
          <span className={stylesFatButton.SmallText}>
            {languages.forRelative}
          </span>
        ) as any,
        action: ACTION.subquestion,
      },
    ]

    return create(patientQuestion)
  }
  /*
   * Créer la question slot
   */
  const slots = () => {
    let slotsQuestion: Question = get(PATH.slot) as Question

    slotsQuestion.replies = getSlotsReplies(
      NUMBER_OF_SLOTS_BEFORE_NOW,
      NUMBER_OF_SLOTS_AFTER_NOW
    )

    return create(slotsQuestion)
  }

  /* Supprime une question
   * uniquement une question parent
   * par exemple pregnant_or_breastfeeding , pour un patient courant HOMME
   */
  const remove = (pathToRemove: string) => {
    const questions = _questions.filter(
      ({ id }: { id: string }) => id !== pathToRemove
    )

    const path = _path.filter((keyPath) => keyPath !== pathToRemove)

    return { ...manager, questions, path }
  }

  /* Création de question (ou remplacement d'une question existante)
   * Pour l'instant ne créé qu'une question parent ou sous question parent
   * Il remplace la question dans le store si jamais la question.id existait déjà
   * impossible d'ajouter par exemple patient:relative:old
   * on peut modifier patient:relative pour lui donner :old, par contre
   */
  const create = (new_question: Question) => {
    let questions: Question[] = _questions
    let path = _path
    let exists = get(new_question.id)

    if (!exists && !is_deep_path(new_question.id)) {
      /*
       * Ici la question est une question parent et n'existe pas
       */
      questions.push(new_question)
      path.push(new_question.id)
    } else if (exists && !is_deep_path(new_question.id)) {
      /*
       * Ici la question est parent et existe. on replace
       */
      questions = replace_question(questions, new_question)
    } else if (is_deep_path(new_question.id)) {
      /*
       * Ici la questions sera ("patient:relative") par exemple
       */
      questions = questions.map((q) => {
        if (new_question.id.includes(q.id)) q.subquestion = new_question
        return q
      })
    }
    if (is_deep_path(new_question.id)) return { ...manager, questions }

    return { ...manager, questions, path }
  }

  const finish = () => {
    return { ...manager, status: "finish" }
  }
  const cancel = () => {
    return init({ ...MANAGER, status: "cancel" })
  }

  const _id: string = manager.current?.id || "unknow-current"

  switch (action.type) {
    case QUESTION_PATIENT:
      return patient({ patient: payload })
    case QUESTION_DOCTORS:
      return doctors({ doctors: payload })
    case QUESTION_RELATIVES:
      return relatives({ relatives: payload })
    case QUESTION_SLOTS:
      return slots()
    case CREATE_QUESTION:
      return create(payload)
    case REMOVE_QUESTION:
      return remove(payload)
    case UNSET_QUESTION:
      return unset(payload, _id)
    case SET_QUESTION:
      return set(payload, _id)
    case NEXT_QUESTION:
      return next()
    case PREVIOUS_QUESTION:
      return previous()
    case INIT_SURVEY:
      return init(MANAGER)
    case SET_CUSTOMER:
      return customer(payload)
    default:
      return manager
  }
}
export default reducer
