import { Button } from "antd"
import React, { Component, useEffect, useState } from "react"
import { formatPhoneNumber } from "react-phone-number-input"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"

import { VerifyModal } from ".."
import { languages } from "../../../lib/languages"
import { flushError } from "../../../services/verify/actions"
import { buttonLayout } from "../../../styles/constants"
import { useSelector } from "react-redux";
import { Card } from "../../new/Card/Card"
import { FormPage } from "../../new/Form/Form"
import useOurForm from "../../new/Form/useForm"
import styles from "./Verify.module.scss"

const VerifyComponent = (props: any): any => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const formRef = useOurForm()
  const location = useLocation()
  const error = useSelector((state: any) => state?.verify?.error);
  console.log('Code error message : ', error);
  const onSubmit = (event: any) => {
    console.log('VerifyComponent / onSubmit', location.pathname, event.code);
    props.flush(location.pathname)
    props.onSubmit(event.code)
  }

  const showModal = () => {
    props.flush(location.pathname)
    setModalVisible(true)
  }
  const label = (
    <div>
      {languages.writeVerifyCode()}{" "}
      <span className={styles.Phone}>{formatPhoneNumber(props.phone)}</span>
    </div>
  )

  useEffect(() => {
    if (error && Object.keys(error).length) {
      formRef.reset(["code"]);
    }
  }, [error])

  const inputsObj = [{ name: "code", required: true, label, autoSubmit: true }]
  return (
    <Card message={props.error?.[location.pathname] ? props.error[location.pathname].text?.message : props.error}>
      <FormPage
        formRef={formRef}
        hidePrevious
        inputs={inputsObj}
        loading={props.loading}
        acceptButton=""
        onFinish={(e: any) => {
          onSubmit(e)
          setTimeout(() => { formRef.reset(["code"]); }, 6000);
        }}
        onValuesChange={() => { }}
        onCancel={() => { }}
      />
      <div className="wrapped-buttons space-around" style={{ margin: 0 }}>
        <Button {...buttonLayout} onClick={showModal}>
          {languages.smsNotReceived}
        </Button>
      </div>
      <div
        className="wrapped-buttons space-around"
        style={{ marginTop: 25, marginBottom: 25 }}
      >
        <span className={styles.ContactSecretary}>
          {languages.contact_secretary}
        </span>
      </div>
      <VerifyModal
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </Card>
  )
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    flush: (location: string) => dispatch(flushError({ path: location })),
  }
}
export default connect(null, mapDispatchToProps)(VerifyComponent)
