import React, { useEffect, useState } from "react";
import { Carousel } from "components/Carousel/Index";

import { INSERT_RELATIVE_PAGE } from "core/constants";

import { InputNames } from "types/new/types/props";

import { BirthPlace } from "types/new/types/entity";

import Input from '../../new/Input/Input/Input';

import config from "react-global-configuration";
import FatButton from "../../generics/FatButton/FatButton"
import { languages } from "lib/languages";

const FOREIGN_LOCATION = ['foreign_territory'];

const FRENCH_LOCATION = ['city_district', 'municipality', 'oversea_community_municipality'];

interface locationsPayload {
  created_at: string,
  fullname: string,
  fullname_with_junction: string,
  fullname_with_suffix: string,
  insee_code: string,
  location_type: string,
  name: string,
}

const searchLocation = (searchedValue: string = "", birthDate: string, selectedBirthLocation = `${BirthPlace.FRANCE}`) => {
  return fetch(`/api${config.get("locations")}`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      date: birthDate && `${birthDate[4]}${birthDate[5]}${birthDate[6]}${birthDate[7]}-${birthDate[2]}${birthDate[3]}-${birthDate[0]}${birthDate[1]}`,
      capitalize_article: true,
      name: searchedValue,
      location_types: selectedBirthLocation === `${BirthPlace.FRANCE}` ? FRENCH_LOCATION : FOREIGN_LOCATION,
    })
  }).then((res: any) => {
    return res.json();
  })
    .then((r: { status: string, locations: Array<locationsPayload> }) => {
      if (r.status === "ok") {
        return r.locations;
      } else {
        return getDefaultLocalisationList(selectedBirthLocation);
      }
    })
    .catch((error) => {
      console.error(error, {
        route: `${config.get("locations")}`
      });
      return getDefaultLocalisationList(selectedBirthLocation);
    })
}

export const getTitleByCountry = (pathname: string, country: string) => {
  const isRelative = pathname === INSERT_RELATIVE_PAGE;
  switch (`${country}`) {
    case `${BirthPlace.FRANCE}`:
      return isRelative ? languages.inputYourRelativeBirthCity : languages.inputYourBirthCity;
    case `${BirthPlace.FOREIGN}`:
      return isRelative ? languages.inputYourRelativeBirthCountry : languages.inputYourBirthCountry;
    case `${BirthPlace.UNKNOWN}`:
      return languages.unknownBirthPlace;
    default:
      return languages.unknownBirthPlace;
  }
}

const UNKNOWN_INSEE_CODE = "99999";

const getUnknownLabel = (country = `${BirthPlace.FRANCE}`) => country === `${BirthPlace.FRANCE}` ? "Inconnu" : "Inconnu";


const getDefaultLocalisationList = (birth_location = `${BirthPlace.FRANCE}`) => ([
  {
    fullname_with_suffix: getUnknownLabel(birth_location), insee_code: UNKNOWN_INSEE_CODE
  }
]);

const getSelectedCountry = (formRef: any) => formRef && formRef.getFieldValue(InputNames.BIRTH_COUNTRY) || `${BirthPlace.FRANCE}`

const onLocationClicked = (inseeCode: string, fullname_with_suffix: string, props: any) => {
  props.formRef.setFieldsValue({ [InputNames.BIRTH_LOCATION]: fullname_with_suffix, [InputNames.INSEE_CODE]: inseeCode })
  props.onChange(fullname_with_suffix);
}

const setSelectedCountry = (props: any, country: string) => {
  props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_COUNTRY, country);
  props.changeTitle(getTitleByCountry(window.location.pathname, country));
  if (country === `${BirthPlace.UNKNOWN}`) {
    props.formRef.setFieldsValue({ [InputNames.BIRTH_LOCATION]: getUnknownLabel(country), [InputNames.INSEE_CODE]: UNKNOWN_INSEE_CODE })
  }
};

export const searchCityFullNameWithInseeCode = (inseeCode: string, birthdate: string) => {
  const location_type = inseeCode.slice(0, 2) == "99" ? FOREIGN_LOCATION : FRENCH_LOCATION;

  return fetch(`/api${config.get("locations")}`, {
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      date: birthdate && `${birthdate[4]}${birthdate[5]}${birthdate[6]}${birthdate[7]}-${birthdate[2]}${birthdate[3]}-${birthdate[0]}${birthdate[1]}`,
      capitalize_article: true,
      insee_code: inseeCode,
      location_types: location_type,
    })
  }).then((res: any) => {
    return res.json();
  })
}

const BirthLocation = (props: any) => {
  const [locationList, setLocationList] = useState(getDefaultLocalisationList(props.formRef && props.formRef.getFieldValue(InputNames.BIRTH_COUNTRY)));
  useEffect(() => {
    // Si le code INSEE est pré-remplis depuis la carte vitale
    // Nous allons chercher la ville associée
    // Et injecter sa valeur dans l'input
    const inseeCode = props.formRef && props.formRef.getFieldValue(InputNames.INSEE_CODE);
    if (inseeCode === UNKNOWN_INSEE_CODE) {
      props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, getUnknownLabel(props.formRef && props.formRef.getFieldsValue(InputNames.BIRTH_COUNTRY)));
    } else if (inseeCode) {
      try {
        searchCityFullNameWithInseeCode(inseeCode, props.formRef && props.formRef.getFieldValue(InputNames.BIRTHDATE))
          .then((res: any) => {
            if (res && res.status === "ok") {
              if (inseeCode.slice(0, 2) == "99") {
                setSelectedCountry(props, `${BirthPlace.FOREIGN}`)
              }
              props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, res?.locations?.[0]?.fullname_with_suffix);
            } else {
              props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, getUnknownLabel(props.formRef && props.formRef.getFieldsValue(InputNames.BIRTH_COUNTRY)));
            }
            setLocationList([]);
          })
        return;
      } catch (error) {
        console.error(error, {
          route: config.get("locations")
        });
        props.formRef && props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, getUnknownLabel(props.formRef && props.formRef.getFieldsValue(InputNames.BIRTH_COUNTRY)));
      }
    }
  }, []);

  useEffect(() => {
    // DO NOT TRIGGER SEARCH AFTER CITY SELECTION
    if (/[(][0-9]{1,}[)]/.test(props.value)) {
      return;
    }
    let launchRequest = setTimeout(() => {
      if (!props.value || props.value === "") {
        setLocationList(getDefaultLocalisationList(props.formRef && props.formRef.getFieldValue(InputNames.BIRTH_COUNTRY)));
        props.formRef && props.formRef.setFieldsValue({ [InputNames.INSEE_CODE]: "" })
      }
      props.value && searchLocation(props.value, props.formRef && props.formRef.getFieldValue(InputNames.BIRTHDATE), getSelectedCountry(props.formRef)).then((cityFound: any) => {
        setLocationList(cityFound);
      })
    }, 500);
    return () => clearTimeout(launchRequest);
  }, [props.value]);

  useEffect(() => {
    if (!props.value || props.value === "") {
      setLocationList(getDefaultLocalisationList(props.formRef && props.formRef.getFieldValue(InputNames.BIRTH_COUNTRY)));
      props.formRef && props.formRef.setFieldsValue({ [InputNames.INSEE_CODE]: "" })
    }

  }, [props.formRef && props.formRef.getFieldValue(InputNames.BIRTH_COUNTRY)])

  return (
    props.disabled ?
      <Input  {...(props as unknown as any)} value={!props.value ? languages.not_informed_singular : props.value} /> :
      <div className="birth-location-carousel-container" style={{ width: "1025px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div><input style={{ width: "1100px" }} type="text" name="birth_location" value={props.value} /> </div>
        </div>
        {locationList.length > 0 && (
          <Carousel
            itemsPerPage={2}
            items={locationList.map((location) => (
              <FatButton style={{ fontSize: "20px", height: "75px" }} onClick={() => onLocationClicked(location.insee_code, location.fullname_with_suffix, props)}>
                <div><span
                  style={{
                    textOverflow: "ellipsis",
                    maxWidth: "250px",
                  }}>{location.fullname_with_suffix}</span></div></FatButton>
            ))}
            infinite={false}
            style={{ height: "auto", width: "auto" }}
          />
        )}
      </div>
  )
}


export default BirthLocation;