import { Button, Form } from "antd"
import React, { useState } from "react"
import { connect } from "react-redux"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import Loading from "../Loading/Loading"
import actions from "./services/actions"
import { ACTION, Answer, Reply, SurveyProps, SurveyStore, Value } from "./types"

import { buttonLayout, formLayout } from "../../styles/constants"
import { Title } from "../../components/generics"
import { Subtitle } from "../../components/new/Title/Title"
import PreviousButton from "../../components/new/Previous/PreviousButton"

import { FINALE_PAGE, HOME_PAGE } from "../../core/constants"
import { Navigate, useNavigate } from "react-router-dom"
import { UiCarousel, UiFooter } from "./generics/Ui"
import { languages } from "../../lib/languages"
import { ITEMS_PER_CAROUSEL_PAGE, PATH } from "./models"

import { InfoModal } from "../../components/new/Modal/Modal"

const DefaultReply = (props: { reply: Reply; onSelect: () => void }) => {
  return (
    <Button {...buttonLayout} type="primary" onClick={props.onSelect}>
      {props.reply.title}
    </Button>
  )
}

const Index = (props: SurveyProps) => {
  const [showModal, setShowModal] = useState(false)
  const { current: question, answer: selectedItems, status } = props
  const navigate = useNavigate()
  if (!question) return <Loading />
  switch (status) {
    case "finish":
      props.submit(props.answers)
      return <Navigate to={FINALE_PAGE} replace />
    case "cancel":
      props.cancel()
      // RESET_STORE
      return <Navigate to={HOME_PAGE} replace />
    case "on_going":
    default:
      const onSelect = (r: Reply) => {
        if (r.action === ACTION.redirect) {
          navigate(r.value as string)
          return
        }
        if (!question.multipleReplies) {
          const oldValues = question.replies.filter(
            (old) => old.value !== r.value
          )
          props.unset(oldValues)
          props.setSaga([r])
          return
        }
        props.set([r])
      }

      const unSelect = (r: Reply) => {
        if (!question.multipleReplies) {
          const oldValue = question.replies.filter(
            (old) => old.value === r.value
          )
          if (oldValue) {
            props.unset([r])
            props.setSaga([r])
          }
        } else {
          props.unset([r])
        }
      }

      const longTitle = question.replies.length >= ITEMS_PER_CAROUSEL_PAGE

      return (
        <div className="card-contenant max-large-card">
          <PreviousButton
            text={question.id === PATH.patient && languages.logout}
            onClick={props.previous}
          />

          {question.title && (
            <Title longTitle={longTitle} value={question.title}></Title>
          )}
          {question.subtitle && (
            <Subtitle
              text={question.subtitle}
              className="anamnese-subtitle align-center"
            />
          )}
          {showModal && (
            <InfoModal
              visible={showModal}
              title={question.confirmModal?.title}
              message={question.confirmModal?.message}
              onCancel={() => setShowModal(false)}
              onAccept={() => setShowModal(false)}
            />
          )}
          <Form {...formLayout} onFinish={props.next}>
            <UiCarousel
              items={question.replies}
              selected={selectedItems}
              onSelect={onSelect}
              unSelect={unSelect}
              ui={question.ui}
            />
            <UiFooter
              nextVisible={!!question.multipleReplies}
              nextDisabled={!selectedItems.length}
            >
              {question.defaultReply && (
                <DefaultReply
                  reply={question.defaultReply}
                  onSelect={() => {
                    if (
                      question.confirmModal &&
                      question.confirmModal.trigger === "default"
                    ) {
                      setShowModal(true)
                    } else {
                      // default reply pass on the next direct
                      onSelect(question.defaultReply as Reply)
                    }
                  }}
                />
              )}
            </UiFooter>
          </Form>
        </div>
      )
  }
}

const mapStateToProps = ({
  survey,
}: {
  survey: SurveyStore
}): Partial<SurveyProps> => {
  const current = survey.current
  const answer: Value[] =
    survey.answers.find(({ questionId }: Answer) => questionId === current?.id)
      ?.value || []

  return { current, answer, status: survey.status, answers: survey.answers }
}

const mapDispatchToProps = (dispatch: any): Partial<SurveyProps> => {
  return {
    set: (replies_to_add: Reply[]) => dispatch(actions.set(replies_to_add)),
    setSaga: (replies_to_add: Reply[]) =>
      dispatch(actions.setSaga(replies_to_add)),
    unset: (replies_to_remove?: Reply[]) =>
      dispatch(actions.unset(replies_to_remove)),
    next: () => dispatch(actions.next()),
    previous: () => dispatch(actions.previous()),
    cancel: () => dispatch({ type: "RESET_STORE" }),
    submit: (a: Answer[]) => dispatch(actions.submit(a)),
  }
}
const IndexPage = withHeaderAndFooter(withCard(Index))
export default connect(mapStateToProps, mapDispatchToProps)(IndexPage as any)
