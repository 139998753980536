import {
  INSERT_RELATIVE_ERROR,
  INSERT_RELATIVE_SUCCESS,
  GET_RELATIVES_SUCCESS,
  INSERT_RELATIVE_REQUESTING,
  GET_RELATIVES_REQUESTING,
  GET_RELATIVES_ERROR,
  RESET_RELATIVE_STORE,
  UPDATE_RELATIVE,
} from "./constants"
import {
  RelativeRequest,
  ResponseInsertRelative,
  ResponseGetRelatives,
  Gender,
  Relative,
} from "../../types/types"

//INSERT
export const insertRelativeRequest = (payload: RelativeRequest) => ({
  type: INSERT_RELATIVE_REQUESTING,
  payload,
})
export const insertRelativeSuccess = (payload: ResponseInsertRelative) => {
  const { sex = Gender.MALE, ...rest } = payload.relative
  const formattedRelative = {
    gender: sex,
    ...rest,
  }

  return {
    type: INSERT_RELATIVE_SUCCESS,
    payload: {
      ...payload,
      relative: {
        ...formattedRelative,
        birthdate: payload.relative.birthdate.split("-").reverse().join("/"),
      },
    },
  }
}

export const insertRelativeError = (error: string) => ({
  type: INSERT_RELATIVE_ERROR,
  error,
})
// GET
export const getRelativesSuccess = (
  payload: ResponseGetRelatives | null = null
) => {
  return {
    type: GET_RELATIVES_SUCCESS,
    payload,
  }
}
export const getRelativesRequest = () => ({
  type: GET_RELATIVES_REQUESTING,
})
export const getRelativesError = (error: string) => ({
  type: GET_RELATIVES_ERROR,
  error,
})

// PUT
export const putRelativeRequest = (payload: Relative) => ({
  type: UPDATE_RELATIVE,
  payload
});

export const putRelativeSuccess = () => ({})
export const putRelativeError = () => ({})

export const resetRelativeStore = () => ({ type: RESET_RELATIVE_STORE })
