
export interface PayloadError {
    code: number
    details: unknown
    error: ERROR_API
    status: string
}

export  enum ERROR_READER_SOCKET {
    API_LEC_OPEN_ERROR = "API_LEC_OPEN_ERROR",
    API_LEC_INIT_ERROR = "API_LEC_INIT_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    PCSC_READER_ERROR = "PCSC_READER_ERROR",
    SOFT_CARD_READ_ERROR = "SOFT_CARD_READ_ERROR",
    MUTED_OR_UNKNOWN_CARD_ERROR = "MUTED_OR_UNKNOWN_CARD_ERROR",
    GENERAL_ERROR = "GENERAL_ERROR",
}

export enum ERROR_API {
    MISMATCHING_INFORMATION = "MISMATCHING_INFORMATION",
    PHONE_EXISTING_ERROR = "PHONE_EXISTING_ERROR",
    PAYLOAD_ERROR = "PAYLOAD_ERROR",
    EXISTING_ORDER = "EXISTING_ORDER",
    NIR_LENGTH = "PAYLOAD_ERROR",
    NEXMO_ERROR = "NEXMO_ERROR",
    TIMEOUT_ERROR = "TIMEOUT_ERROR"
}