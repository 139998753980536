import {
  INSTALLATION_SUCCESS,
  INSTALLATION_ERROR,
  INSTALLATION_REQUESTING,
  SET_CLINIC,
  SET_PATIENT,
  SET_PROSPECT,
  SET_RELATIVES,
  LOGOUT,
  UPDATE_PROSPECT,
  UPDATE_PATIENT_STORE,
} from "./constants"
import {
  ResponseInstallation,
  ClientStore,
  Relative,
  Doctor,
} from "../../types/types"
import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
} from "../authentication/constants"

import { ADMIN_LOGOUT } from "../admin/constants"

import { INSERT_RELATIVE_SUCCESS } from "../relative/constants"

import { DOCTOR_LIST_SUCCESS } from "../doctor/constants"

const initialState: ClientStore = {
  success: false,
  loading: true,
  clinic: null,
  patient: null,
  relatives: null,
  doctors: [],
  prospect: {},
  createAccountState: {
    loading: false,
  },
}

interface ClientAction {
  type: string
  payload: ResponseInstallation | Doctor[]
}

const reducer = function clientReducer(
  state = initialState,
  action: Partial<ClientAction>
): ClientStore {
  let newState = { ...state }
  switch (action.type) {
    case CREATE_ACCOUNT:
      newState = {
        ...state,
        createAccountState: {
          loading: true,
        },
      }
      return newState
    case CREATE_ACCOUNT_SUCCESS:
    case CREATE_ACCOUNT_ERROR: {
      newState = {
        ...state,
        createAccountState: {
          loading: false,
        },
      }
      return newState
    }
    case INSTALLATION_REQUESTING:
      newState = {
        ...state,
        loading: true,
        success: false,
      }
      return newState
    case INSTALLATION_SUCCESS:
      newState = {
        ...state,
        loading: false,
        success: true,
        clinic: (action.payload as ResponseInstallation)?.pharmacy || null,
        // patient: (action.payload as ResponseInstallation)?.customer || null,
      }
      return newState
    case INSTALLATION_ERROR:
      newState = {
        ...state,
        loading: false,
        success: false,
        clinic: null,
        patient: null,
      }
      return newState
    case SET_CLINIC:
      newState = {
        ...state,
        clinic: (action.payload as ResponseInstallation)?.pharmacy || null,
      }
      return newState
    case SET_PROSPECT: {
      newState = {
        ...state,
        prospect: {
          ...((action.payload as ResponseInstallation)?.prospect || null),
        },
      }
      return newState
    }
    case UPDATE_PROSPECT: {
      newState = {
        ...state,
        prospect: {
          ...state.prospect,
          ...((action.payload as ResponseInstallation)?.prospect || {}),
        },
      }
      return newState
    }
    case UPDATE_PATIENT_STORE: {
      newState = {
        ...state,
        patient: {
          ...state.patient,
          ...(action.payload || {}),
        },
      }
      return newState
    }
    case SET_PATIENT:
      newState = {
        ...state,
        patient: (action.payload as ResponseInstallation)?.customer || null,
      }
      return newState
    case SET_RELATIVES:
      newState = {
        ...state,
        relatives: (action.payload as ResponseInstallation)?.relatives || null,
      }
      return newState
    case DOCTOR_LIST_SUCCESS:
      newState = {
        ...state,
        doctors: (action.payload as Doctor[]) || null,
      }
      return newState
    case INSERT_RELATIVE_SUCCESS:
      if (action.payload) {
        const newRelative: Relative | undefined = (
          action.payload as ResponseInstallation
        )?.relative
        if (newState.relatives && newRelative)
          newState.relatives.push(newRelative)
      }
      return newState
    case LOGOUT:
    case ADMIN_LOGOUT:
      return { ...state, relatives: null, patient: null }
    default:
      return { ...newState }
  }
}

export default reducer
