import React from "react"
import TextTransition from "react-text-transition"

const Title = ({
  longTitle,
  value,
}: {
  longTitle: boolean | false
  value: string
}) => {
  return (
    <h1 className={`${longTitle ? "long-title-card" : "title-card"}`}>
      <TextTransition
        className="align-center"
        text={value}
        springConfig={{ mass: 1, tension: 1000, friction: 100 }}
      />
    </h1>
  )
}

export default Title
