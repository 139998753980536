import { Button, Form, FormInstance } from "antd"
import React, { useState, useRef, useEffect, Dispatch, SetStateAction  } from "react"
import { useNavigate } from "react-router"
import { connect } from "react-redux"
import TextTransition from "react-text-transition"

import { languages } from "../../../lib/languages"
import { formatNirFromMask, isValidNir } from "../../../lib/utils"
import { requestNirReader } from "../../../services/nir/actions"
import { buttonLayout, formLayout } from "../../../styles/constants"
import { ResponseNirBeta } from "../../../types/types"
import NirInput from "../../generics/NirInput/NirInput"
import { Keyboard } from "../../hocs/withKeyboard"
import PreviousButton from "../../new/Previous/PreviousButton"
import {
  LOBBY_PAGE,
  SURVEY_PAGE,
} from "../../../../src/core/constants"
import styles from "./Nir.module.scss"

interface NirProps {
  defaultNir: string,
  loading: boolean
  success: boolean
  nirResponse?: ResponseNirBeta
  hasNir: (value: boolean) => false
  nirRequest: () => void
  onFinish: ({ nir }: { nir: string }) => void
  apiResponse: (response: ResponseNirBeta) => void
  response: ResponseNirBeta
  message: {
    text: string,
  },
}

const onLoginNir = (nir: string, onFinish: ({nir}: {nir: string}) => void) => {
    const format = formatNirFromMask(nir)
    if (format.length === 15) onFinish({ nir: format })
}

const onClickHasNir = (hasNir : boolean, setHasNir: Dispatch<SetStateAction<boolean>>) => {
    setHasNir(!hasNir)
}

const onFormChange = (nir: string, setDisabled: Dispatch<SetStateAction<boolean>>, formRef: any) => {
  const isValid = isValidNir(nir)
  if (isValid) {
    setDisabled(false)
  } else {
    setDisabled(true)
    if (nir.length === 15) formRef.current?.submit()
  }
}

// TODO : define props NirProps
const Nir = (props: any) => {
  const [hasNir, setHasNir] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const formRef = useRef<FormInstance>();
  const navigate = useNavigate();
  useEffect(() => {
    props.success && props.apiResponse(props.response)
  }, [props])
    return (
      <div className="card-contenant large-card" data-testid="Nir">
        <PreviousButton
          onClick={
            hasNir
              ? () => onClickHasNir(hasNir, setHasNir)
              : () => {
                  if (props.pathname === SURVEY_PAGE) {
                    navigate(-1)
                  }
                }
          }
          reset={props.pathname === LOBBY_PAGE}
        />
        <h1 className="title-card">
          <TextTransition
            className="align-center"
            text={hasNir ? languages.writeNir : languages.DoYouKnowYourNir}
            springConfig={{ mass: 1, tension: 1000, friction: 100 }}
          />
        </h1>
        <div className={`${styles.VitalCard} ${hasNir ? styles.Half : null}`} />
        <div hidden={hasNir}>
          <div className="wrapped-buttons space-around">
            <Button {...buttonLayout} onClick={() => props.hasNir(false)}>
              {languages.no}
            </Button>
            <Button
              {...buttonLayout}
              type="primary"
              onClick={() => onClickHasNir(hasNir, setHasNir)}
            >
              {languages.yes}
            </Button>
          </div>
        </div>
        <Form
          ref={formRef as any}
          hidden={!hasNir}
          onValuesChange={(e) => onFormChange(e.nir, setDisabled, formRef)}
          onFinish={(e) => onLoginNir(e.nir, props.onFinish)}
          {...formLayout}
        >
          <Keyboard
            form={formRef.current as FormInstance}
            options={{
              type: "nir",
              inputName: "nir",
              title: languages.writeNir,
            }}
            onChange={(nir: string) => {
              onFormChange(nir, setDisabled, formRef)
            }}
          >
            <NirInput readOnly={true} />
          </Keyboard>

          <div className="card-contenant">
            {props.message ? (
              <div className={!props.success ? "error-card" : ""}>
                {props.message.text}
              </div>
            ) : null}
          </div>
          <div className="wrapped-buttons space-around">
            <Form.Item>
              <Button
                {...buttonLayout}
                htmlType="submit"
                type="primary"
                disabled={disabled || props.loading}
                loading={props.loading}
              >
                {languages.loginNir}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    )
}

const mapStateToProps = (state: any): Partial<NirProps> & { pathname: string} => {
  return {...state.nir, pathname: state?.router?.location?.pathname }
}

const mapDispatchToProps = (dispatch: Function): Partial<NirProps> => ({
  nirRequest: () => dispatch(requestNirReader()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Nir)
