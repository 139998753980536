import { Carousel as AntdCarousel, Col, Row } from "antd"
import { CarouselRef } from "antd/lib/carousel"
import React from "react"
import { RefObject, useEffect, useRef, useState } from "react"

import styles from "./styles.module.scss"
import {
  UiArrow,
  UiGenericSlide,
} from "./Ui"

export function splitterArray<T>(array: T[], nbElement: number): T[][] {
    const chunk = []
    while (array.length > 0) chunk.push(array.splice(0, nbElement))
    return chunk
}

const ITEMS_PER_CAROUSEL_PAGE = 3;

export const Carousel: React.FC<any> = ({
    infinite = true,
    ...props
  }): JSX.Element => {
    const ref = useRef<CarouselRef>() as RefObject<CarouselRef>
    const [hide, setHide] = useState({ left: true, right: true })
    const [pages, setPages] = useState<JSX.Element[][]>([])
    const itemsPerPage = props.itemsPerPage ?? ITEMS_PER_CAROUSEL_PAGE
  
    // current index carousel position
    const position = props.position || 0
    // previous arrow carousel
    const previous = () => ref?.current?.prev()
    // next arrow carousel
    const next = () => ref?.current?.next()
    // before carousel change
    const onBeforeChange = (from: number, to: number): void => {
      if (props.onChange) {
        if (props.toSpread) props.onChange(props.toSpread[to])
        else props.onChange(props.items[to])
      }
      let _hide = hide
      if (to <= 0) setHide((_hide = { ..._hide, left: true }))
      else setHide((_hide = { ..._hide, left: false }))
      if (to === pages.length - 1) setHide((_hide = { ..._hide, right: true }))
      else setHide((_hide = { ..._hide, right: false }))
    }
  
    useEffect(() => {
      setPages(splitterArray(props.items, itemsPerPage))
    }, [props.items])
  
    return (
      <Row className={styles.SurveyBox}>
        <UiArrow side="left" onClick={previous} hidden={hide.left} />
        <Col
          span={20}
          style={{
            textAlign: "center",
            height: pages.length > 1 ? "550px" : "initial",
            verticalAlign: "middle",
            ...props.style,
          }}
        >
          <AntdCarousel
            infinite={infinite}
            beforeChange={onBeforeChange}
            ref={ref}
            dots={props.dots ? { className: "dots-carousel" } : false}
            initialSlide={position}
          >
            {pages.map((page, key) => {
              return <UiGenericSlide key={key} items={page} />
            })}
          </AntdCarousel>
        </Col>
        <UiArrow side="right" onClick={next} hidden={hide.right} />
      </Row>
    )
  }