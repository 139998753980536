import { ReloadOutlined } from "@ant-design/icons"
import { Col, FormInstance, Row } from "antd"
import React, { Component, ReactNode } from "react"
import KeyboardJs from "react-simple-keyboard"
import { Subject } from "rxjs"

import {
  codeKeyboard,
  docNameKeyboard,
  emailKeyboard,
  keyboardLayout,
  nirKeyboard,
  noLayoutKeyboard,
  numericKeyboard,
  passwordKeyboard,
  textKeyboard,
  locationKeyboard,
} from "../../styles/keyboard"
import styles from "./Keyboard.module.scss"

import { InputNames } from "types/new/types/props"

/*
  Ce clavier JS HOC nécéssite un type Form.Item en props si Form est utilisé
  afin de modifier la valeur
------------------------------------------------
    Par HOC -  déconseillé
    --------------
    options : KeyboardProps.options
    withKeyboard(wrappedComponent, options)
    -> le hoc identifiera le input grâce au options.inputName
    -> Children hérite de : 
    - onChange( value )
    - onClick ( initialValue )
------------------------------------------------
    Par JSXELEMENT 
    --------------
    <Keyboard props : KeyboardProps >
    Si le input ciblé a ses propriétés [onChange, onClick] overwrités, 
    ne pas oublié de faire :
    onChange={props.onChange}
    onClick={props.onClick}
    Sinon le clavier n'atteindra jamais son input cible
*/
const NAMES_MUST_BE_CAPITALIZED = [InputNames.FIRSTNAME, InputNames.LASTNAME, InputNames.FIRST_BIRTH_FIRSTNAME, InputNames.BIRTH_LASTNAME] as Array<string>

export interface KeyboardChildrenProps {
  value: string
  form?: FormInstance
  name?: string
  readOnly?: boolean
  onClick?: () => void
  onChange?: (value: React.ChangeEvent | string) => void
}
interface KeyboardProps {
  event?: any
  options?: {
    nextButton?: string
    title?: string
    inputName?: string
    type?: "nir" | "num" | "text" | "email" | "phone" | string
  }
  form?: FormInstance
  readOnly?: boolean
  children?: ReactNode
  value?: string
  onChange?: (s: string) => void
}
interface KeyboardState {
  value: string
  visible: boolean
  layout: { default: string[]; shift?: string[] }
  mode: string
}

const keyboards = {
  text: textKeyboard,
  nir: nirKeyboard,
  email: emailKeyboard,
  tel: numericKeyboard,
  num: numericKeyboard,
  password: passwordKeyboard,
  code: codeKeyboard,
  docname: docNameKeyboard,
  location: locationKeyboard,
}

const getLayoutByType = (
  type: string
): { default: string[]; shift?: string[] } => {
  switch (type) {
    case "gender":
      return noLayoutKeyboard
    case "num":
    case "numeric":
      return keyboards.num
    case "code":
    case "birthdate":
      return keyboards.code
    case "phone":
      return keyboards.tel
    case "password":
    case "new-password":
    case "old-password":
      return keyboards.password
    case "email":
      return keyboards.email
    case "nir":
      return keyboards.nir
    case InputNames.BIRTH_LOCATION:
      return keyboards.location
    case "docname":
      return keyboards.docname
    default:
    case "text":
      return keyboards.text
  }
}
interface KeyboardResetProps {
  onClick?: () => void
}
const KeyboardReset: React.FC<KeyboardResetProps> = (props): JSX.Element => {
  return (
    <div {...props} className={styles.KeyboardReset}>
      <ReloadOutlined />
    </div>
  )
}

type KeyboardReference = unknown & {
  replaceInput: any
  setInput: (string: string) => void
}
export class Keyboard extends Component<KeyboardProps, KeyboardState> {
  keyboard: KeyboardReference | undefined = undefined
  constructor(props: KeyboardProps) {
    super(props)
    this.event()

    const type = props.options?.type || "undefined"
    const layout = getLayoutByType(type)
    this.state = {
      value: props.value || "",
      mode: this.mustCapitalized() ? "shift" : "default",
      visible: true,
      layout,
    }
  }

  maxLength: number | undefined = undefined
  inputElement: HTMLInputElement | null = null

  setInput(name: string | undefined) {
    if (!name) this.inputElement = null
    const input = document.querySelector(`input[name='${name}']`)
    this.inputElement = input as HTMLInputElement
  }

  setLayout() {
    const type = this.props.options?.type || "undefined"
    const layout = getLayoutByType(type)
    const mode = this.mustCapitalized() ? "shift" : "default"
    if (layout !== this.state.layout)
      this.setState({
        ...this.state,
        layout,
        mode,
      })
  }

  event() {
    this.props.event?.subscribe((event: any) => {
      switch (event) {
        case "reset":
          this.keyboard?.replaceInput({})
          break
        default:
          break
      }
    })
  }

  setMaxLength() {
    const lengthFromMask = this.getMaxLengthOf(this.inputElement)
    if (lengthFromMask) this.maxLength = lengthFromMask
    else
      this.maxLength =
        Number(this.inputElement?.getAttribute("maxlength")) || undefined
  }

  mustCapitalized = () =>
    !this.inputElement?.value?.length &&
    NAMES_MUST_BE_CAPITALIZED.includes(this.props.options?.inputName ?? "")

  linkInput = (input?: string) => {
    this.setInput(input)
    this.setMaxLength()
    this.setLayout()
  }

  componentDidUpdate = (prevProps: Readonly<KeyboardProps>): void => {
    const inputChanged =
      prevProps.options?.inputName !== this.props.options?.inputName
    const valueChanged = prevProps.value !== this.props.value
    if (!this.inputElement || inputChanged) {
      this.linkInput(this.props.options?.inputName)
    }

    // Allow modification of injected default value
    if ([`${InputNames.FIRSTNAME}`, `${InputNames.LASTNAME}`].includes(this.props?.options?.inputName as string)) {
      this.keyboard?.setInput(this.props.value as string)
    }

    if (valueChanged) {
      this.onUserKeyboardChange({
        [this.props.options?.inputName as string]: this.props.value || "",
      })
    }
  }

  onValueChange = (inputs: { [x: string]: string }): void => {
    const value = inputs[this.props.options?.inputName as string]
    const mode = this.mustCapitalized() ? "shift" : "default"
    this.setState({ ...this.state, value, mode })
  }

  onUserKeyboardChange = (inputs: { [x: string]: string }) => {
    this.keyboard?.setInput(inputs[this.props.options?.inputName as string])
    this.onValueChange(inputs)
  }

  onJSKeyboardChange = (inputs: { [x: string]: string }) => {
    this.props.form &&
      this.props.form.setFieldsValue({
        [this.props.options?.inputName as string]:
          inputs[this.props.options?.inputName as string] || "",
      })
    this.props.onChange &&
      this.props.onChange(inputs[this.props.options?.inputName as string] || "")
    this.onValueChange(inputs)
  }

  handleAccent = (button: string): void => {
    if (button === "{accent}" || button === "{shift-accent}")
      this.setState({ ...this.state, mode: "accent" })
    else if (button === "{accent-upper}" || button === "{shift-accent-upper}")
      this.setState({ ...this.state, mode: "accent-upper" })
    else if (button === "{char}")
      this.setState({ ...this.state, mode: "default" })
    else if (button === "{char-upper}")
      this.setState({ ...this.state, mode: "shift" })
  }

  handleShift = (): void => {
    const newMode = this.state.mode === "default" ? "shift" : "default"
    this.setState({ ...this.state, mode: newMode })
  }

  onKeyPress = (button: string): void => {
    if (["{shift}"].includes(button)) this.handleShift()
    else if (
      [
        "{accent}",
        "{char}",
        "{char-upper}",
        "{shift-accent}",
        "{accent-upper}",
        "{shift-accent-upper}",
      ].includes(button)
    )
      this.handleAccent(button)
    else if (this.state.mode !== "default") this.handleShift()
  }

  getMaxLengthOf = (
    inputElement: HTMLInputElement | null
  ): number | undefined => {
    const maxLength =
      !inputElement?.maxLength || inputElement?.maxLength <= 0
        ? 0
        : inputElement.maxLength
    const x = this.removeMaskFromMaxLength(inputElement, maxLength)
    return x
  }

  removeMaskFromMaxLength = (
    input: HTMLInputElement | null,
    maxLength: number
  ): number => {
    const mask = input?.getAttribute("mask")?.split("") || []
    const numberOfSpacerInMask = mask.filter(
      (char) => !["#", "1"].includes(char)
    ).length
    return maxLength - numberOfSpacerInMask
  }

  render = (): JSX.Element => {
    const options = this.props.options
    const { value, layout, mode, visible } = this.state
    const resetable = !["gender"].includes(this.props.options?.type as string)

    const keyboardJsProps = {
      layout,
      disableButtonHold: true,
      value,
      layoutName: mode,
      inputName: options?.inputName || "undefined",
      maxLength: this.maxLength,
    }

    return (
      <div
        className={`${styles.InputContainer} ${layout === keyboards.code && styles.Gap50
          }`}
      >
        {resetable ? (
          <Row
            style={{ width: "100%", margin: "auto" }}
            justify="space-between"
            gutter={[20, 20]}
          >
            <Col span={2}>
              <KeyboardReset
                onClick={() => {
                  this.keyboard?.setInput("")
                  this.onJSKeyboardChange({
                    [this.props.options?.inputName as string]: "",
                  })
                }}
              />
            </Col>
            <Col span={22}>
              <div>{this.props.children}</div>
            </Col>
          </Row>
        ) : (
          <div style={{ textAlign: "center" }}>{this.props.children}</div>
        )}

        {visible && (
          <KeyboardJs
            {...keyboardJsProps}
            {...keyboardLayout}
            keyboardRef={(r: KeyboardReference) => (this.keyboard = r)}
            onKeyPress={this.onKeyPress}
            onChangeAll={this.onJSKeyboardChange}
            disableCaretPositioning={true}
          />
        )}
      </div>
    )
  }
}

const withKeyboard = (WrappedComponent: React.FC): React.FC<KeyboardProps> => {
  return (props: KeyboardProps) => (
    <Keyboard {...props}>
      <WrappedComponent />
    </Keyboard>
  )
}

export default withKeyboard
