import React from "react"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { VerifyComponent } from "../../components/generics"

const Verify: React.FC = (props: any) => (
  <div className="card-contenant" data-testid="Verify">
    <VerifyComponent {...props} />
  </div>
)

const VerifyPage = withHeaderAndFooter(withCard(Verify))
export default VerifyPage
