import { eventChannel } from "@redux-saga/core"
import { call, delay, put, select, take, takeLatest, takeLeading } from "@redux-saga/core/effects"
import config from "react-global-configuration"
import { EventChannel } from "redux-saga"
import { all } from "redux-saga/effects"
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket"

import { languages } from "../../lib/languages"
import { getPatientsFromDataCarteVitale } from "../../lib/sesam"
import { SocketSendMessage, WebSocketMessage } from "../../types/new/types/payload"
import { Action, ActionWithoutPayload, GFlow, GWatcher, Message, WebSocketAction } from "../../types/new/types/redux"
import { DataCarteVitale } from "../../types/new/types/sesam"
import { GlobalStore, NirReaderResponse, SocketStore } from "../../types/new/types/store"
import { setRawNir } from "../nir/actions"
import { decrementRetryBy, errorCardSocketNirReader, removeCardSocketNirReader, removedCardSocketNirReader, requestSocketNirReader, resetNumberOfRetry, resetSocketNirReader, socketNirReaderResponse, unreachableSocket } from "./actions"
import {   SOCKET_LOG_TERMINAL_INFORMATION,
  SOCKET_SEND_TERMINAL_INFORMATION, SOCKET_CANCEL_CARD_VITALE_READER, SOCKET_INIT_CARD_VITALE_READER, SOCKET_LAUNCH_CARD_VITALE_READER, SOCKET_RESET_CARD_VITALE_READER, SOCKET_SEND_MESSAGE, SOCKET_SUBSCRIBE_CLIENT } from "./constants"
import { getWssHost, ReadyState, staging, WEBSOCKET_OPEN_STATE } from "./utils"
import { request } from "../../lib/request";

import { TerminalInformation } from "../../types/new/types/payload"

let _client: W3CWebSocket | undefined = undefined

enum SOCKET_ERROR {
  TIMEOUT = "TIMEOUT",
  GENERIC = "GENERIC",
  UNLAUNCHABLE = "UNLAUNCHABLE",
}
function* handleSocketErrorMsg(errorType: SOCKET_ERROR): any {
  switch (errorType) {
    case SOCKET_ERROR.UNLAUNCHABLE:
      yield put(unreachableSocket())
      break
    case SOCKET_ERROR.TIMEOUT:
      yield put(errorCardSocketNirReader("La requête carte vitale a expirée."))
      break
    case SOCKET_ERROR.GENERIC:
    default:
      yield put(errorCardSocketNirReader(languages.readerSocketGenericError))
      break
  }
}

function* readerSocketManager(
  webSocketAction: WebSocketAction<
    Action<DataCarteVitale> | ActionWithoutPayload
  >
): GFlow<Action<NirReaderResponse | Message>> {
  const { type, result, payload } = webSocketAction
  console.log("%creaderSocketManager", "background : #03b2cb;", payload?.type)
  if (result === "KO") {
    yield handleSocketErrorMsg(SOCKET_ERROR.GENERIC)
  }
  if (payload.type === "REMOVE_TIMEOUT") {
    yield put(removedCardSocketNirReader())
  }
  if (result === "TIMEOUT") {
    yield handleSocketErrorMsg(SOCKET_ERROR.TIMEOUT)
  } else if (
    result === "OK" &&
    type === "get" &&
    (payload as Action<DataCarteVitale>).payload
  ) {
    yield put(setRawNir(payload as Action<DataCarteVitale>))
    const response = yield getPatientsFromDataCarteVitale(
      (payload as Action<DataCarteVitale>).payload
    ) as any
    yield put(socketNirReaderResponse(response as NirReaderResponse))
  } else {
    if (payload.type === "REMOVE") {
      yield put(removeCardSocketNirReader())
    } else if (payload.type === "REMOVED") {
      yield put(removedCardSocketNirReader())
    }
  }
}

function* incomingPayload(
  webSocketAction: WebSocketAction<
    Action<DataCarteVitale> | ActionWithoutPayload
  >
): GFlow<WebSocketAction<Action<DataCarteVitale> | ActionWithoutPayload>> {
  const { result, domain } = webSocketAction
  if(domain === "infos" && result === "OK" && webSocketAction.type === "all_infos") {
    yield put({type: SOCKET_SEND_TERMINAL_INFORMATION, payload: webSocketAction.payload })
  }

  if (domain === "carte_vitale") {
    yield readerSocketManager(webSocketAction) as any
  }
  if (result !== "OK") {
    yield 0 as any
  }
}

function* launchReaderCard(): any {
  const client: W3CWebSocket | undefined = yield call(initSocket)
  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )

  if (stopLookingForClient) return yield all([put(resetSocketNirReader())])
  if (!client) return yield handleSocketErrorMsg(SOCKET_ERROR.UNLAUNCHABLE)
  try {
    if (client.readyState !== ReadyState.OPEN) {
      console.log("[launchReaderCard] client not ready")
      yield yield handleSocketErrorMsg(SOCKET_ERROR.UNLAUNCHABLE)
    } else {
      send(client, {
        type: "carte_vitale",
        action: "get",
        body: {
          timeout: config.get("timeoutNirReaderInS"),
        },
      })
      yield put({ type: SOCKET_LAUNCH_CARD_VITALE_READER })
    }
  } catch (e) {
    console.log("[launchReaderCard] error", e)
    yield handleSocketErrorMsg(SOCKET_ERROR.UNLAUNCHABLE)
  }
}

function cancelReaderCard() {
  console.info("---- CANCEL vital card READER ----")
}

function* createSocketChannel(client: W3CWebSocket): any {
  return yield eventChannel((emitMessage) => {
    client.onmessage = (message: IMessageEvent) => {
      console.log(
        "%c ON MESSAGE",
        "background: #bee8f5;",
        message ?? "No Message"
      )
      const data: WebSocketMessage = JSON.parse(message.data as string)
      if (data.type === "carte_vitale" || data.type === "infos") {
        emitMessage({
          data: {
            domain: data.type,
            type: data.action,
            result: data.result,
            payload: data.body,
          },
          client,
        })
      }
    }

    client.onclose = () => {
      // ici, récupération de l'état fermé du socket courant
      console.error(`[WS subscribe] Le socket courant s'est fermé`, {
        route: "wss::createSocketChannel"
      })
      const message = {
        type: "carte_vitale",
        action: "SOCKET_CLOSED",
        body: undefined,
      }
      emitMessage({
        data: {
          domain: message.type,
          type: message.action,
          payload: message.body,
        },
        client,
      })
    }

    const unsubscribe = () => {
      client.close()
    }
    return unsubscribe
  })
}

function* sendMessage(payload: SocketSendMessage) {
  const client: W3CWebSocket = yield call(initSocket)
  send(client, payload)
  return
}

function resetReaderCard() {
  console.log("----RESET NIR READER ---- ")
}

function send(client: W3CWebSocket | undefined, payload: SocketSendMessage) {
  if (client && client.readyState === WEBSOCKET_OPEN_STATE)
    client.send(JSON.stringify(payload))
}

function* waitSocketInOpenState(): any {
  // delai avant de tenter le rétablissement de la connexion WS
  const numberOfRetry = yield select(
    ({ socket }: { socket: SocketStore }) => socket?.numberOfRetryLeft
  )
  const WSS_SOCKET = getWssHost()
  const client = yield new W3CWebSocket(WSS_SOCKET)
  const isOpened = yield staging(client)

  const stopLookingForClient = yield select(
    ({ socket }: GlobalStore) => socket.stop || false
  )

  if (stopLookingForClient) {
    return undefined
  }
  if (isOpened) {
    yield all([
      put(resetNumberOfRetry()),
      put({ type: SOCKET_SUBSCRIBE_CLIENT, payload: client }),
    ])
    return client
  } else if (numberOfRetry > 0) {
    client.close()
    yield all([put(decrementRetryBy(1)), delay(3000)])
    return yield call(waitSocketInOpenState)
  } else {
    console.error(`P.3/ [WS], can't connect to WebSocket`, {
      route: "wss:waitSocketInOpenState"
    })
    return undefined
  }
}

function* initSocket(): any {
  // retourne le websocket courant s'il existe
  // sinon retourne un nouveau ws
  if (!_client || _client.readyState !== WEBSOCKET_OPEN_STATE) {
    console.info(
      "%c ---------INITIALIZE WEBSOCKET----------",
      "background:  #8bc34a"
    )
    _client = yield waitSocketInOpenState()
  }
  return _client
}

function* subscribeWebSocket({
  payload,
}: {
  type: string
  payload: W3CWebSocket
}): any {
  console.info(
    "%c ---------SOCKET LINK CLIENT----------",
    "background: blue; color : white;"
  )
  const socketChannel: EventChannel<any> = yield call(
    createSocketChannel,
    payload
  )
  while (true) {
    try {
      const {
        data,
      }: {
        data: WebSocketAction<Action<DataCarteVitale> | ActionWithoutPayload>
      } = yield take(socketChannel)
      if (data.type === "SOCKET_CLOSED") {
        return yield put(requestSocketNirReader())
      } else {
        yield call(incomingPayload, data)
      }
    } catch (err) {
      console.error(err, {
        route: "wss:subscribeWebSocket"
      })
    }
  }
}

function* patchManageEngineInformation(manageEngine : TerminalInformation["manageengine"], terminalUuid: string) : Generator<Promise<TerminalInformation>, void, void> {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch manageengine information
  if(manageEngine && Object.keys(manageEngine).length > 0) {
    yield request(`${patchTerminalUrl}/${terminalUuid}/manageengine`, {method: "PATCH", payload: manageEngine});
  }
}

function* patchTeamViewerInformation(teamViewer : TerminalInformation["teamviewer"], terminalUuid :string) : Generator<Promise<TerminalInformation>, void, void> {
  const patchTerminalUrl = config.get("terminals.patch");
  // patch terminal information
  if(teamViewer && Object.keys(teamViewer).length > 0){
    yield request(`${patchTerminalUrl}/${terminalUuid}/teamviewer`, {method: "PATCH", payload: teamViewer});
  }
}

function* bulkPatchTerminalsInformation(payload: TerminalInformation, terminalUuid: string | null): Generator<Promise<TerminalInformation> | Generator<Promise<TerminalInformation>, void, void>, void, void> {
  const patchTerminalUrl = config.get("terminals.patch");
  const { manageengine: manageEngine, teamviewer: teamViewer, ...body } = payload;

  if(terminalUuid) {
    yield request(`${patchTerminalUrl}/${terminalUuid}`, {method: "PATCH", payload: body });

    yield patchManageEngineInformation(manageEngine, terminalUuid);

    yield patchTeamViewerInformation(teamViewer, terminalUuid);
  } else {
    console.error("No terminal identity found", {
      route: "wss::bulkPatchTerminalsInformation"
    });
  }

}

function* createOrUpdateTerminalInformation(payload: TerminalInformation) : Generator<Promise<TerminalInformation> | Generator<Promise<TerminalInformation> | Generator<Promise<TerminalInformation>, void, void>, void, void>, any, any> {
  const getTerminalUrl = config.get("terminals.get");
  const postTerminalUrl = config.get("terminals.post");
  const terminalUuid = sessionStorage.getItem("terminal");
  if(!terminalUuid) {
    const terminalInformation = yield request(getTerminalUrl, { method: "GET" });
    if(terminalInformation?.terminal?.guid) {
      sessionStorage.setItem("terminal", terminalInformation.terminal?.guid);
      yield bulkPatchTerminalsInformation(payload, terminalUuid);
    } else {
      const res = yield request(postTerminalUrl, {method: "POST", payload })
      sessionStorage.setItem("terminal", res?.terminal?.guid);
      yield patchManageEngineInformation(payload?.manageengine, res?.terminal?.guid);

      yield patchTeamViewerInformation(payload?.teamviewer, res?.terminal?.guid);
    }
  } else {
    yield bulkPatchTerminalsInformation(payload, terminalUuid);
  }
}

function* sendTerminalInformation({ payload } : any) {
  try {
    const { terminal, manageengine, teamviewer } = payload;
    // FIX to handle ipv6 in ipv4 information
    if(terminal.ipv4_private.length >15) {
      delete terminal.ipv4_private;
    };
    const body = {...terminal, manageengine, teamviewer };

    yield createOrUpdateTerminalInformation(body)
  } catch (error) {
    console.error(error, {
      route: "wss::sendTerminalInformation"
    })
  }
}

function* logTerminalInformation() {
  yield sendMessage({ type: "infos", action: "all_infos", body: {}  })
}

export default function* rootSaga(): GWatcher {
  yield takeLatest(SOCKET_INIT_CARD_VITALE_READER, launchReaderCard)
  yield takeLatest(SOCKET_RESET_CARD_VITALE_READER, resetReaderCard)
  yield takeLatest(SOCKET_CANCEL_CARD_VITALE_READER, cancelReaderCard)
  yield takeLatest(SOCKET_SEND_MESSAGE, sendMessage)
  yield takeLeading(SOCKET_SUBSCRIBE_CLIENT, subscribeWebSocket)
  yield takeLeading(SOCKET_LOG_TERMINAL_INFORMATION, logTerminalInformation)
  yield takeLeading(SOCKET_SEND_TERMINAL_INFORMATION, sendTerminalInformation)
}
