import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "antd"
import { ClientStore } from "../../../types/types"
import { logout } from "../../../services/client/actions"

interface HeaderProps {}

const Header: React.FC = (props: any & HeaderProps) => {
  return (
    <Row>
      <Col span={8}></Col>
      <Col span={8} style={{ textAlign: "center" }}></Col>
      <Col span={8} style={{ textAlign: "right" }}></Col>
    </Row>
  )
}

export const mapStateToProps = ({ client }: { client: ClientStore }) => {
  return {
    admin: !!client.clinic,
    login: !!client.patient,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    patientLogout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
