import { find, first } from "lodash"
import { ClientStore, Doctor, Relative, Patient } from "../../types/types"

import Prospect from "./factory"

const getProspect = (state: any) => state.client.prospect || {}

const getPatient = ({ client }: { client: ClientStore }): Patient =>
  client.patient

const getLoginData = (state: any) => {
  const { prospect } = state.client
  const p = new Prospect(prospect)
  return p.getLoginData()
}

const getProspectNir = (state: any) => state.client?.prospect?.nir

const getPersonnalData = (state: any) => {
  const { prospect } = state.client || {}
  const p = new Prospect(prospect)
  return p.getPersonnalData()
}
const getToken = (state: any): string => {
  const { patient } = state.client || {}
  return patient.token || patient["x-patient-token"] || ""
}

const getPhone = (state: any): string => {
  const { prospect } = state.client || {}
  const { phone = "" } = prospect
  return phone
}

const getClinicId = ({ client }: { client: ClientStore }) => {
  const doctors: Doctor[] | [] = client.doctors
  if (doctors.length > 0) return first(doctors)?.id
  return []
}

const getDoctors = ({ client }: { client: ClientStore }): Doctor[] | null => {
  const doctors: Doctor[] | [] = client.doctors
  return doctors || []
}
const getRelatives = ({ client }: { client: ClientStore }): Relative[] => {
  const relatives: Relative[] | null = client.relatives
  return relatives || []
}
const getXProspectToken = (state: any): string => {
  const { prospect } = state.client || {}
  const { ["x-patient-token"]: token } = prospect
  return token
}

const getXPatientToken = (state: any): string => {
  const { patient } = state.client || {}
  const { ["x-patient-token"]: token } = patient
  return token
}

const getPropspectRelatives = (state: any): any => {
  const { prospect } = state.client || {}
  const { relatives = [] } = prospect
  return relatives
}

export {
  getDoctors,
  getPatient,
  getProspect,
  getProspectNir,
  getLoginData,
  getPersonnalData,
  getPhone,
  getToken,
  getRelatives,
  getPropspectRelatives,
  getXPatientToken,
  getXProspectToken,
  getClinicId,
}
