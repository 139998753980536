import { ArrowRightOutlined } from "@ant-design/icons"
import {
  Form as AntdForm,
  Checkbox,
  FormProps,
  InputProps,
  Row,
  Col,
} from "antd"

import { GenericModal } from "../Modal/Modal"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useForm } from "antd/lib/form/Form"
import { Dayjs } from "dayjs"
import { find } from "lodash"
import React, { useEffect, useState } from "react"
import { Subject } from "rxjs"

import { languages } from "../../../lib/languages"
import { capitalize } from "../../../lib/utils"
import {
  BirthPlace,
  Gender,
  Patient,
  Prospect,
  Relative,
} from "../../../types/new/types/entity"
import {
  FormPageProps,
  GetInputProps,
  InputNames,
  InputObj,
  ItemProps,
} from "../../../types/new/types/props"
import FatButton from "../../generics/FatButton/FatButton"
import { Keyboard } from "../../hocs/withKeyboard"
import PreviousButton from "../../new/Previous/PreviousButton"
import { Button } from "../Button"
import { Buttons } from "../Card/Card"
import { Date, Input, Nir, Password, Phone } from "../Input"
import { Code, CodeInputProps } from "../Input/Code/Index"
import { DateProps } from "../Input/Date/Date"
import { NirInputProps } from "../Input/Nir/Nir"
import NewPassword from "../Input/Password/NewPassword"
import { NoEmailModal } from "../Modal/Modal"
import { Steps } from "../Steps"
import { Information, Title } from "../Title/Title"
import Styles from "./form.module.scss"
import { getNameOfCurrentInput, labelFromName, rules } from "./utils"
import BirthLocation from "components/Input/BirthLocation/Index"
import { FormInstance } from "antd/es/form/Form"

import { getTitleByCountry } from "components/Input/BirthLocation/Index";

import { INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE } from "core/constants";


export const Form: React.FC<FormProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <AntdForm {...rest} layout="vertical">
      {children}
    </AntdForm>
  )
}

export const Item: React.FC<ItemProps> = (props): JSX.Element =>
  props.name ? (
    <AntdForm.Item
      hidden={props.hidden}
      label={!props.labelHidden && labelFromName(props.name)}
      name={props.name}
      rules={[...rules(props.name, props.required ?? true)]}
    >
      {props.children}
    </AntdForm.Item>
  ) : (
    <AntdForm.Item>{props.children}</AntdForm.Item>
  )

const GetInput: React.FC<
  Omit<InputProps, "value"> & {
    value: string | boolean | Dayjs | Gender | Date | undefined
    onChange?: (val: any) => void,
    addFilledCondition?: (isFilled: boolean) => void
    changeTitle: (newTitle: string | Element) => void
    formRef: FormInstance
  }
> = (props) => {
  switch (props.name) {
    case "gender":
      return (
        <div className="gender-wrapped-buttons">
          <FatButton
            clicked={props.value === Gender.MALE}
            big
            onClick={() => props.onChange && props.onChange(0)}
            img={"/icons/homme_picto.svg"}
          >
            <b className={Styles.genderSpan}>{languages.man}</b>
          </FatButton>
          <FatButton
            clicked={props.value === Gender.FEMALE}
            big
            onClick={() => props.onChange && props.onChange(1)}
            img={"/icons/femme_picto.svg"}
          >
            <b className={Styles.genderSpan}>{languages.woman}</b>
          </FatButton>
        </div>
      )

    case "birthdate":
      return <Date {...(props as DateProps)} onChange={props.onChange} />
    case InputNames.BIRTH_LOCATION:
      return <BirthLocation formRef={props.formRef} {...(props as unknown as any)} />
    case "nir":
      return <Nir {...(props as NirInputProps)} />
    case "password":
      return <Password {...(props as InputProps)} />
    case "new-password":
      return (
        <NewPassword
          {...(props as InputProps)}
          style={{ fontSize: "35px" }}
          name={getNameOfCurrentInput(props.name)}
        />
      )
    case "phone":
      return <Phone {...(props as any)} />
    case "code":
      return <Code {...(props as CodeInputProps)} />
    default:
      return <Input {...(props as InputProps)} />
  }
}

export const GetItemForm: React.FC<GetInputProps> = ({
  name,
  visible,
  value,
  disabled = false,
  required = false,
  readOnly = true,
  formRef,
  hiddenLabel = true,
  onChange,
  prospect,
  addFilledCondition,
  changeTitle
}): JSX.Element => {
  return (
    <Item
      key={getNameOfCurrentInput(name)}
      name={getNameOfCurrentInput(name)}
      hidden={!visible}
      required={required}
      labelHidden={hiddenLabel}
    >
      <GetInput
        formRef={formRef}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        required={required}
        addFilledCondition={(isFilled) => addFilledCondition && addFilledCondition(isFilled)}
        changeTitle={(newTitle) => changeTitle && changeTitle(newTitle)}
      />
    </Item>
  )
}

const HiddenInputSwitch: React.FC<{
  inputs: InputObj[]
  prospect?: Partial<Patient | Prospect | Relative>
}> = (props): JSX.Element => {
  const inputsNode = props.inputs.map((i: InputObj, key) => {
    return (
      <div key={key}>
        <GetItemForm
          name={i.name}
          required={i?.required || false}
          visible={false}
          prospect={props.prospect}
        />
      </div>
    )
  })
  return <>{inputsNode}</>
}
const Default: React.FC<{
  onClick: (e: string) => void
  default: { warning?: boolean; value: string; label: string }
}> = (props): JSX.Element => {
  const [checked, setChecked] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <>
      <Checkbox
        checked={checked}
        onChange={(event: CheckboxChangeEvent) => {
          setChecked(event.target.checked)
          if (event.target.checked && props.default.warning)
            setModalVisible(true)
          else if (event.target.checked) props.onClick(props.default.value)
          else props.onClick("")
        }}
      >
        <Information text={props.default.label} />
      </Checkbox>
      <NoEmailModal
        visible={modalVisible}
        onAccept={() => {
          props.onClick(props.default.value)
          setChecked(true)
          setModalVisible(false)
        }}
        onCancel={() => {
          props.onClick("")
          setChecked(false)
          setModalVisible(false)
        }}
      />
    </>
  )
}

export const FormPage = ({
  inputs,
  prospect,
  loading,
  acceptButton,
  hidePrevious,
  cancelText,
  onChange,
  formStepReset,
  setStep,
  ...formProps
}: FormPageProps): JSX.Element => {
  const [formRef] = useForm()
  const [current, setCurrent] = useState(inputs.findIndex(value => value?.type !== "hidden"))
  const keyboardEvent = new Subject<string>()
  const [disableNextButton, setDisableNextButton] = useState(false);

  const [conditionState, setConditionState] = useState({} as any);

  let [myInputs, setMyInputs] = useState(inputs);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkInseeCodeIsSet = () => {
    if (!formRef.getFieldValue(InputNames.INSEE_CODE) || formRef.getFieldValue(InputNames.INSEE_CODE) === "") {
      setDisableNextButton(true);
    } else {
      setDisableNextButton(false)
    }
  }

  const getCurrentLabel = (currentInput: any) => {
    return currentInput && currentInput.keepLabelFormat
      ? currentInput.label
      : typeof currentInput.label === "string"
        ? capitalize(currentInput.label)
        : currentInput.label ||
        capitalize(`${languages.inputYour} ${labelFromName(currentInput.name)}`)
  }

  const getCurrent = (current: number): InputObj => {
    return find(myInputs, (input: InputObj, key: number) => key === current) as InputObj
  }

  const currentInput: InputObj = getCurrent(current)

  const [defaultTitle, setDefaultTitle] = useState(getCurrentLabel(currentInput));

  useEffect(() => {
    setDefaultTitle(getCurrentLabel(currentInput));
  }, [currentInput.name])

  const [currentValue, setCurrentValue] = useState(
    prospect ? (prospect as any)[currentInput.name] ?? undefined : undefined
  )

  useEffect(() => {
    if (formProps.formRef) {
      formProps.formRef.on("reset", (inputs: string[] | undefined) => {
        setTimeout(() => {
          formRef.resetFields(inputs)
          keyboardEvent.next("reset")
          checkChangedValue()
          setCurrent(0)
        }, 500)
      })
      formProps.formRef.on("set", (inputs: any) => {
        formRef.setFieldsValue(inputs)
        checkChangedValue()
        setCurrent(0)
      })
    }
  }, [])

  useEffect(() => {
    const freshValue =
      formRef.getFieldValue(getNameOfCurrentInput(currentInput.name)) ||
      undefined
    setCurrentValue(freshValue)
    document.getElementById(getNameOfCurrentInput(currentInput.name))?.focus()
    if ([
      InputNames.FIRST_BIRTH_FIRSTNAME,
      InputNames.BIRTH_LASTNAME,
      InputNames.FIRSTNAME,
      InputNames.LASTNAME,
      InputNames.BIRTH_LOCATION
    ].includes(currentInput.name as InputNames)) {
      setDisableNextButton(true);
      if (currentInput.name === InputNames.BIRTH_LOCATION && freshValue && freshValue !== "") {
        setDisableNextButton(false);
      } else {
        if (freshValue && !/^[ \-'']*$/g.test(freshValue)) {
          setDisableNextButton(false);
        }
      }
    } else {
      setDisableNextButton(false);
    }
  }, [current])

  let isConditionFilled = false;

  const addFilledCondition = (check: boolean) => {
    isConditionFilled = check;
    setConditionState({ ...conditionState, [currentInput.name]: isConditionFilled });
    if (isConditionFilled) {
      // check if condition is not allready filled
      if (myInputs.indexOf((i: any) => i.name === currentInput.condition?.filled?.name) === -1) {
        myInputs.splice(myInputs.findIndex((i) => i.name === currentInput.name) + 1, 0, currentInput.condition?.filled as InputObj)
        setMyInputs(myInputs);
      }
    } else {
      myInputs.splice(myInputs.findIndex((i) => i.name === currentInput.condition?.filled?.name), 1)
      setMyInputs(myInputs)
    }
  }

  const handlePrev = () => {
    let prev = current - 1
    let criteria = myInputs[prev]
    while (prev >= 0 && criteria?.type === "hidden") {
      prev = prev - 1
      criteria = myInputs[prev]
    }
    if (prev < 0) {
      formRef.resetFields()
      formProps.onCancel()
      return
    }
    else {
      setCurrent(prev)
    }
  }
  const next = () => {
    const next = current + 1
    const index = myInputs.slice(next).findIndex(value => value?.type !== "hidden")
    if (index === -1) {
      formRef.submit()
    }
    else {
      setCurrent(next + index)
    }
  }

  const handleNext = () => {
    formRef
      .validateFields([currentInput.name])
      .then(() => {
        next()
      })
      .catch((errorInfo) => {
        console.error(errorInfo, {
          route: "Form::handleNext::validateFields"
        })
      })
  }
  const checkChangedValue = () => {
    const freshValue = formRef.getFieldValue(currentInput.name) || undefined
    if (currentInput.name === InputNames.BIRTHDATE && !formRef.getFieldValue(InputNames.BIRTH_COUNTRY)) {
      formRef.setFieldValue(InputNames.BIRTH_COUNTRY, `${BirthPlace.FRANCE}`)
    }

    if ([
      InputNames.FIRST_BIRTH_FIRSTNAME,
      InputNames.BIRTH_LASTNAME,
      InputNames.FIRSTNAME,
      InputNames.LASTNAME,
    ].includes(currentInput.name as InputNames)) {
      setDisableNextButton(true);
      if (currentInput.name === InputNames.BIRTH_LOCATION && freshValue && freshValue !== "") {
        setDisableNextButton(false);
      } else {
        if (!/^[ \-'']*$/g.test(freshValue)) {
          setDisableNextButton(false);
        }
      }
    } else {
      setDisableNextButton(false);
    }

    // custom validator
    // need insee code to validate (wich is next question)
    // can't do it with validator
    if (currentInput.name === InputNames.BIRTH_LOCATION) {
      checkInseeCodeIsSet()
    }

    setCurrentValue(freshValue)

    freshValue && currentInput.autoSubmit && handleNext()
  }

  return (
    <div style={{ width: "1200px", margin: "auto" }}>
      <Title text={defaultTitle} size="medium" />
      {hidePrevious ?? (
        <PreviousButton
          text={
            !myInputs[current - 1]
              ? cancelText || languages.previous
              : languages.previous
          }
          onClick={handlePrev}
        />
      )}
      <Form
        validateTrigger="onSubmit"
        form={formRef}
        onValuesChange={checkChangedValue}
        {...(formProps as FormProps)}
        initialValues={{
          ...prospect,
        }}
      >
        {/*  Here is our current input of the form. We provide to it the keyboard */}
        <Keyboard
          form={formRef}
          value={currentValue}
          event={keyboardEvent}
          onChange={(value: string) => {
            onChange && onChange(value, currentInput.name)
            checkChangedValue()
          }}
          options={{
            type: getNameOfCurrentInput(currentInput.name),
            inputName: getNameOfCurrentInput(currentInput.name),
          }}
        >
          <GetItemForm
            formRef={formRef}
            name={currentInput.name}
            required={currentInput?.required || false}
            visible={true}
            prospect={prospect}
            onChange={onChange}
            addFilledCondition={(isChecked: boolean) => addFilledCondition(isChecked)}
            changeTitle={(newTitle: string | Element) => setDefaultTitle(newTitle)}
          />
        </Keyboard>
        {/* Hide all other inputs to maintain them in the form */}
        <HiddenInputSwitch
          inputs={myInputs.filter((input) => input.name !== currentInput.name)}
          prospect={prospect}
        />
        <Buttons>
          {
            currentInput.condition && currentInput.condition.type === "checkbox" && (<div>
              <Checkbox checked={conditionState[currentInput.name]} onClick={() => { addFilledCondition(!conditionState[currentInput.name]) }}>
                <Information text={currentInput?.condition?.label as string} style={{ margin: "0px" }} />
              </Checkbox>
            </div>
            )
          }
          {
            currentInput.name === InputNames.BIRTH_LOCATION && (
              <div style={{ position: "relative" }}>
                <Checkbox checked={formRef.getFieldValue(InputNames.BIRTH_COUNTRY) !== `${BirthPlace.FRANCE}`} onClick={() => { setIsModalOpen(true) }}>
                  <Information text={[INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE].includes(window.location.pathname) ? languages.relativeNotBornInFrance : languages.notBornInFrance} style={{ margin: "0px" }} />
                </Checkbox>
                <GenericModal title={languages.chooseYourNationality} visible={isModalOpen} closable onClose={() => setIsModalOpen(false)}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {
                      formRef.getFieldValue(InputNames.BIRTH_COUNTRY) !== `${BirthPlace.FRANCE}` ?
                        <FatButton img={"images/france_icon.svg"} onClick={() => { setIsModalOpen(false); setCurrentValue(""); formRef.setFieldValue(InputNames.BIRTH_LOCATION, ""); formRef.setFieldValue(InputNames.BIRTH_COUNTRY, `${BirthPlace.FRANCE}`); formRef.setFieldValue(InputNames.INSEE_CODE, ""); setDefaultTitle(getTitleByCountry(window.location.pathname, `${BirthPlace.FRANCE}`)); checkChangedValue() }}>{languages.french}</FatButton> :
                        (
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", gap: "40px" }}>
                            <FatButton img={"images/foreign_icon.svg"} onClick={() => { setIsModalOpen(false); setCurrentValue(""); formRef.setFieldValue(InputNames.BIRTH_LOCATION, ""); formRef.setFieldValue(InputNames.BIRTH_COUNTRY, `${BirthPlace.FOREIGN}`); setDefaultTitle(getTitleByCountry(window.location.pathname, `${BirthPlace.FOREIGN}`)); formRef.setFieldValue(InputNames.INSEE_CODE, ""); checkChangedValue() }}>{languages.foreign}</FatButton>
                            <FatButton img={"images/unknown_icon.svg"} onClick={() => { setIsModalOpen(false); setCurrentValue("Inconnu"); formRef.setFieldValue(InputNames.BIRTH_LOCATION, "Inconnu"); formRef.setFieldValue(InputNames.BIRTH_COUNTRY, `${BirthPlace.UNKNOWN}`); formRef.setFieldValue(InputNames.INSEE_CODE, "99999"); setDefaultTitle(getTitleByCountry(window.location.pathname, `${BirthPlace.FRANCE}`)); checkChangedValue() }}>{languages.unknown}</FatButton>
                          </div>
                        )
                    }
                  </div>
                </GenericModal>
              </div>
            )
          }
          {currentInput.default && (
            <Default
              default={currentInput.default}
              onClick={(defaultValue) => {
                formRef.setFieldsValue({ [currentInput.name]: defaultValue })
                setCurrentValue(defaultValue)
                if (defaultValue.length) handleNext()
              }}
            />
          )}
          {/* If the current input is the last one, we display the button[type=submit] */}
          {!myInputs[current + 1] || (myInputs[current + 1].type === "hidden" && !myInputs[current + 2]) ? (
            <Item>
              <Button htmlType="submit" disabled={disableNextButton || loading} loading={loading}>
                {acceptButton !== undefined ? acceptButton : languages.finish}
              </Button>
            </Item>
          ) : (
            <Button
              icon={<ArrowRightOutlined />}
              key={current}
              onClick={handleNext}
              disabled={disableNextButton}
            >
              {languages.next}
            </Button>
          )}
        </Buttons>
      </Form>
      {!hidePrevious && <Steps array={myInputs.filter(value => value?.type !== "hidden")} current={myInputs[current - 1]?.type === 'hidden' ? current - myInputs.slice(0, current + 1).filter(value => value?.type === "hidden").length : current - myInputs.slice(0, current + 1).filter(value => value?.type === "hidden").length} />}
    </div>
  )
}
