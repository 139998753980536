import {
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_REQUESTING,
  ADMIN_LOGOUT,
} from "./constants"
import { ResponseAdmin, AdminStore, ResponseError } from "../../types/types"

const initialState: any = {
  success: false,
  loading: false,
}

interface AdminAction {
  type: string
  payload: ResponseAdmin | ResponseError
}

const reducer = function adminReducer(
  state = initialState,
  action: AdminAction
): AdminStore {
  let newState = { ...state }
  switch (action.type) {
    case ADMIN_LOGOUT:
      return { ...newState, loading: false, success: false }
    case ADMIN_LOGIN_REQUESTING:
      return { ...newState, loading: true }
    case ADMIN_LOGIN_SUCCESS:
      newState = {
        ...state,
        success: true,
        loading: false,
      }
      return newState
    case ADMIN_LOGIN_ERROR:
      newState = {
        ...state,
        message: (action.payload as ResponseError).message,
        success: false,
        loading: false,
      }
      return newState
    default:
      return newState
  }
}

export default reducer
