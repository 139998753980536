import { all, put, select, takeLatest } from "redux-saga/effects"
import { handleApiErrors } from "../../../lib/api-errors"
import { push } from "redux-first-history";
import {
  getDoctors,
  getRelatives,
  getToken,
} from "../../../services/client/selector"
import { Doctor } from "../../../types/types"
import actions from "./actions"
import { SET_RELATIVE_PAGE } from "core/constants";
import {
  INIT_SURVEY,
  QUESTION_DOCTORS,
  QUESTION_PATIENT,
  QUESTION_RELATIVES,
  SET_QUESTION,
  SET_QUESTION_SAGA,
  SUBMIT_SURVEY_REQUEST,
} from "./constants"
import { ConsultationPayload, Reply } from "../types"
import { DOCTOR_LIST_SUCCESS } from "../../../services/doctor/constants"
import { INSERT_RELATIVE_SUCCESS } from "../../../services/relative/constants"
import {
  LOGOUT,
  SET_PATIENT,
  SET_RELATIVES,
} from "../../../services/client/constants"

import { PATH } from "../models"

import { Gender } from "../../../types/types"

import { pregnant_or_breastfeeding } from "../models"

import {
  getSurveyId,
  getCurrentPatientGender,
  isPregnantQuestionSet,
  getCurrentSelectedPatientId,
  getCurrentPatientAge,
} from "./selector"
import { isNil, omitBy, take } from "lodash"

async function createConsultationApi(
  payload: ConsultationPayload,
  accessToken: string
) {
  const orderUrl = "/api/consultations/v1"
  return await fetch(orderUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": accessToken,
    },
    body: JSON.stringify(payload),
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error
    })
}

function* surveyFlow({ type, payload }: { type: string; payload: any }): any {
  const doctors = yield select(getDoctors)
  const accessToken: string = yield select(getToken)

  const chosen_clinic_id = payload.chosen_doctor_id
    ? doctors.find((doctor: Doctor) => doctor.id === payload.chosen_doctor_id)
      .clinic_id
    : null
  try {
    const response = yield createConsultationApi(
      omitBy(
        {
          ...payload,
          chosen_clinic_id,
        },
        isNil
      ),
      accessToken
    )

    if (response.status === "ok") yield put(actions.success())
    else yield put(actions.error(""))
  } catch (error) {
    yield put(actions.error(error))
  }
}

function* createDoctorsQuestion({ type, payload }: any) {
  if (payload) yield put({ type: QUESTION_DOCTORS, payload })
}

function* createRelativesQuestion({ type, payload }: any): any {
  if (payload.relative) {
    const relatives = yield select(getRelatives)
    yield put({
      type: QUESTION_RELATIVES,
      payload: [...relatives],
    })
  } else if (payload.relatives)
    yield put({ type: QUESTION_RELATIVES, payload: payload.relatives })
  else yield put({ type: QUESTION_RELATIVES, payload: [] })
}

function* createPatientQuestion({ type, payload }: any) {
  if (payload.customer)
    yield all([
      put({ type: QUESTION_PATIENT, payload: payload?.customer }),
      put(actions.setSlots()),
    ])
}

function* reinit() {
  yield put({ type: INIT_SURVEY })
}

function* manageSurveyQuestions(): any {
  const currentQuestionPath = yield select(getSurveyId)
  if ([PATH.patient, PATH.relative, PATH.inform].includes(currentQuestionPath)) {
    const currentPatientGender = yield select(getCurrentPatientGender)
    if (![Gender.MALE, Gender.FEMALE].includes(currentPatientGender) && currentQuestionPath === PATH.relative) {
      yield put(push(SET_RELATIVE_PAGE))
    }
    if ([Gender.MALE, Gender.FEMALE].includes(currentPatientGender))
      yield displayPregnantQuestion(currentPatientGender)
    const currentCustomerId = yield select(getCurrentSelectedPatientId)
    if (currentCustomerId !== undefined)
      yield modifyTitleQuestion(currentCustomerId)
  }
}

function* modifyTitleQuestion(customerId: number) {
  if (customerId === 0) {
    yield put(actions.for("patient"))
    // customer is the main patient
  } else {
    // customer is a relative
    yield put(actions.for("relative"))
  }
}

export function* displayPregnantQuestion(gender: Gender): any {
  const isQuestionPregnantSet = yield select(isPregnantQuestionSet)

  const patientAge = yield select(getCurrentPatientAge)
  if (gender === Gender.FEMALE && patientAge >= 10 && patientAge < 60) {
    if (!isQuestionPregnantSet) {
      yield put(actions.create(pregnant_or_breastfeeding))
    }
  } else {
    if (isQuestionPregnantSet) {
      yield put(actions.remove(PATH.pregnant_or_breastfeeding))
    }
  }
}

function* setQuestion({ payload }: any) {
  yield put(actions.set(payload))
  yield put(actions.next())
}

function* surveyWatcher() {
  yield takeLatest(SET_QUESTION_SAGA, setQuestion)
  yield takeLatest(SUBMIT_SURVEY_REQUEST, surveyFlow)
  yield takeLatest(DOCTOR_LIST_SUCCESS, createDoctorsQuestion)
  yield takeLatest(INSERT_RELATIVE_SUCCESS, createRelativesQuestion)
  yield takeLatest(SET_RELATIVES, createRelativesQuestion)
  yield takeLatest(SET_PATIENT, createPatientQuestion)
  yield takeLatest(SET_QUESTION, manageSurveyQuestions)
  yield takeLatest(LOGOUT, reinit)
}

export default surveyWatcher
