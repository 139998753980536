import { Button, Space } from "antd"
import Form from "antd/lib/form"
import React from "react"

import { PasswordInput } from "../Input/Input"

import { languages } from "../../../lib/languages"
import { buttonLayout, formLayout } from "../../../styles/constants"
import { CheckAdminPasswordProps } from "../../../types/Form"

const CheckAdminPassword = (props: CheckAdminPasswordProps) => {
  return (
    <div>
      <div className="title-card">{languages.panel_for_secretary}</div>
      {props.error?.text && (
        <div className="error-card">{props.error.text}</div>
      )}
      <Form
        {...formLayout}
        onFinish={(event) => {
          props.onCheckAdminPassword(event.password)
          props.onSubmit(event.password)
        }}
      >
        <Form.Item
          name="password"
          label={languages.password}
          rules={[
            {
              required: true,
              message: languages.inputYour + languages.password,
            },
          ]}
        >
          <PasswordInput visible={false} />
        </Form.Item>
        <Form.Item>
          <div className="wrapped-buttons space-around">
            <Button
              {...buttonLayout}
              disabled={props.loading}
              loading={props.loading}
              style={{ float: "right" }}
              type="primary"
              shape="round"
              htmlType="submit"
            >
              {languages.continue}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CheckAdminPassword
