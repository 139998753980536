import dayjs, { Dayjs } from "dayjs"
import { omit } from "lodash"

import { complyPatient, DATE_FORMAT } from "../../lib/utils"
import { AuthType, ByType, WithType } from "../../pages/Authentication/Auth"
import { CreateAccountFullPayloadType, CreateAccountPayloadType, Prospect, RegisterRequest, ResponseNirBeta, RetrieveAccountByNameAndNir, RetrieveAccountByNameAndPhoneType } from "../../types/types"
import { CREATE_ACCOUNT, CREATE_ACCOUNT_ERROR, CREATE_ACCOUNT_SUCCESS, LOGIN_SUCCESS, MANUAL_AUTHENTICATION, RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR, RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE, RETRIEVE_ACCOUNT_BY_NAME_AND_NIR, RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE, RETRIEVE_ACCOUNT_ERROR, RETRIEVE_ACCOUNT_PREVIOUS_STEP } from "./constants"

export const authActions = {
  submit: (_by: ByType, _with: WithType) => {
    if (_by.type === "PHONE") {
      if (_with.type === "BIRTHDATE")
        return retrieveAccountByBirthdateAndPhone({
          birthdate: _with.value,
          phone: _by.value,
        })
      if (_with.type === "LASTNAME")
        return retrieveAccountByNameAndPhone({
          lastname: _with.value,
          phone: _by.value,
        })
    }
    if (_by.type === "NIR") {
      if (_with.type === "BIRTHDATE")
        return retrieveAccountByBirthdateAndNir({
          birthdate: _with.value,
          nir: _by.value,
        })
      if (_with.type === "LASTNAME")
        return retrieveAccountByNameAndNir({
          lastname: _with.value,
          nir: _by.value,
        })
    }
  },
}

export const loginSuccess = (payload: any) => ({
  type: LOGIN_SUCCESS,
  payload,
})

const dateFormattedForApi = (
  date: undefined | string | Dayjs
): string | undefined => {
  if (!date) return date
  if (typeof date === "string") return date
  else if (date.isValid && date.isValid()) {
    return date.format("DD/MM/YYYY")
  } else return "unknown date"
}

export const createAccountWorkflow = (payload: Prospect) => {
  // Format as API wants
  let format = {
    sex: payload.gender,
    ...payload,
    birthdate: dateFormattedForApi(payload.birthdate as any),
  }

  return {
    type: CREATE_ACCOUNT,
    payload: omit(format, "gender"),
  }
}

export const createAccountError = () => ({
  type: CREATE_ACCOUNT_ERROR,
})

export const createAccountSuccess = () => ({
  type: CREATE_ACCOUNT_SUCCESS,
})

export const retrieveAccountByNameAndPhone = (
  payload: RetrieveAccountByNameAndPhoneType
) => ({
  type: RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE,
  payload,
})

export const retrieveAccountByNameAndNir = (
  payload: RetrieveAccountByNameAndNir
) => ({
  type: RETRIEVE_ACCOUNT_BY_NAME_AND_NIR,
  payload,
})
export const retrieveAccountByBirthdateAndNir = (payload: {
  birthdate: string
  nir: string
}) => ({
  type: RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR,
  payload,
})
export const retrieveAccountByBirthdateAndPhone = (payload: {
  birthdate: string
  phone: string
}) => ({
  type: RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE,
  payload,
})

export const retrieveAccountError = (
  error: string = "Erreur lors de la récupération du compte"
) => ({
  type: RETRIEVE_ACCOUNT_ERROR,
  error: error,
})

export const manualRetrieveAccount = (payload: AuthType) => ({
  type: MANUAL_AUTHENTICATION,
  payload,
})

export const previousStep = () => ({
  type: RETRIEVE_ACCOUNT_PREVIOUS_STEP,
})
