import { take, fork, put, call, takeLatest } from "redux-saga/effects"
import {
  ADMIN_LOGIN_REQUESTING,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGOUT,
} from "./constants"
import { adminLoginError, adminLoginSuccess } from "./actions"
import { ResponseAdmin } from "../../types/types"
import { handleApiErrors } from "../../lib/api-errors"
import { setClinic } from "../client/actions"

const adminLoginUrl = "/api/pharmacies/login"
const adminLogoutUrl = "/api/pharmacies/logout"

async function adminLoginApi(email: string, password: string) {
  return fetch(adminLoginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
    body: JSON.stringify({ email, password }),
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .catch((error) => {
      throw error
    })
}
async function adminLogoutApi() {
  return fetch(adminLogoutUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
  })
}

function* logoutFlow() {
  yield call(adminLogoutApi)
  yield put(setClinic(null))
}

function* adminFlow({ email, password }: any) {
  try {
    const response: ResponseAdmin = yield call(adminLoginApi, email, password)
    yield put(adminLoginSuccess(response))
    if (response.pharmacy) yield put(setClinic(response.pharmacy))
  } catch (error) {
    // error? send it to redux
    yield put(adminLoginError(error))
  }
}

function* adminWatcher() {
  yield takeLatest(ADMIN_LOGIN_REQUESTING, adminFlow)
  yield takeLatest(ADMIN_LOGOUT, logoutFlow)
}

export default adminWatcher
