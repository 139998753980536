import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux";
import { Carousel, Col, Row, Modal } from "antd"
import dayjs from 'dayjs';
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { ConsultationStore, UpdateConsultationProps } from "./services/types"
import { Title } from "../../components/generics"
import PreviousButton from "../../components/new/Previous/PreviousButton"
import { languages } from "../../lib/languages"
import { ClientStore, Consultation, Doctor, DoctorCategory } from "types/types"
import styles from "./styles.module.scss"
import { Reply, Value } from "pages/Survey/types";
import { UiArrow, UiCarousel } from "pages/Survey/generics/Ui";
import { splitCarouselPages } from "pages/Survey/utils";
import { SURVEY_PAGE } from "core/constants";
import Types from "./services/constants";


const ActionModal = ({
  open,
  handleCancel,
  changePratitioner,
  cancelAppointment
}: {
  open: boolean,
  handleCancel: () => void;
  changePratitioner: () => void;
  cancelAppointment: () => void;
}) => {
  return (
    <Modal open={open} onCancel={handleCancel} footer={null} closable={false} style={{ minWidth: "95%" }}>
      <div className="card-contenant max-large-card" style={{ minHeight: "80vh" }}>
        <PreviousButton
          text={languages.previous}
          onClick={handleCancel}
        />
        <Col style={{ marginTop: "150px" }}>
          <div>
            <Row style={{ marginTop: "30px" }}>
              <Title longTitle={true} value={languages.whatDoYouWantToDo}></Title>
            </Row>
          </div>
          <div>
            <Row style={{ marginTop: "50px", marginBottom: "50px", gap: "30px" }} justify="center">
              <AppointmentButton buttonStyle="changePractitioner" onClick={changePratitioner} />
              <AppointmentButton buttonStyle="cancelAppointment" onClick={cancelAppointment} />
            </Row>
          </div>
        </Col>
      </div>
    </Modal>
  )
}

const ConsultationButton = ({
  consultation,
  changePratitioner,
  cancelAppointment
}: {
  consultation: Consultation
  changePratitioner: (consultation_id: number) => void
  cancelAppointment: (consultation_id: number) => void
}) => {
  const { scheduled_date, doctor_name, patient_name, category_id } = consultation
  const doctorMention = category_id === DoctorCategory.GENERALIST ? `Dr. ${doctor_name}` : `${doctor_name}`
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const date = dayjs(scheduled_date).format('DD/MM/YY');
  const time = dayjs(scheduled_date).format('HH[h]mm');

  return (
    <>
      <div className={styles.CurrentConsultationsButton}>
        <div className={styles.editIcon} onClick={showModal}><img src="icons/edit_icon.svg" /></div>
        <div className={styles.appointmentContent}>
          <div className={styles.appointmentDoctor}>{doctorMention}</div>
          <div className={styles.appointmentDatetime}>
            <div>{date}</div>
            <div>{time}</div>
            <div>({patient_name})</div>
          </div>
        </div>
      </div>
      {isModalOpen &&
        <ActionModal
          open={isModalOpen}
          handleCancel={handleCancel}
          changePratitioner={() => changePratitioner(consultation.id)}
          cancelAppointment={() => cancelAppointment(consultation.id)}
        />
      }
    </>

  );
};


const PickADoctor = ({ doctors, previous, onSelectDoctor }:
  { doctors: Doctor[], previous: () => void, onSelectDoctor: (id: string) => void }) => {
  const replies = doctors.map((doctor) => {
    return {
      value: doctor.id,
      title: `${doctor.category_id === DoctorCategory.GENERALIST ? "Dr" : doctor.firstname?.[0].toUpperCase()}. ${doctor.lastname} `,
    }
  })

  return (
    <div className="card-contenant max-large-card">
      <PreviousButton
        text={languages.previous}
        onClick={previous}
      />
      <Title longTitle={true} value={languages.selectADoctor}></Title>
      <UiCarousel
        items={replies}
        selected={[]}
        onSelect={(r: Reply) => onSelectDoctor(r.value as string)}
        unSelect={(r: Reply) => null}
      />
    </div>
  )
}
interface item {
  value: string;
  text: string;
  icon: string;
}

const items: item[] = [
  {
    value: "newAppointment",
    text: languages.newRDV,
    icon: "icons/more_icon.svg"
  },
  {
    value: "changePractitioner",
    text: languages.changeDoctor,
    icon: "icons/reload_icon.svg"
  },
  {
    value: "cancelAppointment",
    text: languages.cancelConsultation,
    icon: "icons/cancel_icon.svg"
  }
]

const AppointmentButton = ({
  onClick,
  buttonStyle = "newAppointment"
}: {
  onClick: () => void;
  buttonStyle?: "newAppointment" | "changePractitioner" | "cancelAppointment";
}) => {
  const { text, icon } = items.find(item => item.value === buttonStyle) as item

  return (
    <div className={styles.newAppointmentButton} onClick={onClick}
      style={{ ...(buttonStyle === "changePractitioner" && { background: "#BEE8F5" }) }}
    >
      <div className={styles.newAppointmentIcon}><img src={icon} /></div>
      <div className={styles.newAppointment} style={{ ...(buttonStyle === "cancelAppointment" && { color: "#EE7564" }) }}>
        {text}
      </div>
    </div>
  );
};


const PageRender = ({
  page,
  handleChangePratitioner,
  updateConsultation,
  handleNewAppointment,
}: {
  page: Consultation[];
  handleChangePratitioner: (id: number) => void;
  updateConsultation: (props: UpdateConsultationProps) => void;
  handleNewAppointment: () => void;
}) => {
  return (
    <div style={{ display: "flex", gap: "30px", justifyContent: "center", alignItems: "center" }}>
      {page.map((c: Consultation, key: number) =>
        <ConsultationButton
          changePratitioner={handleChangePratitioner}
          cancelAppointment={(consultationId: number) => {
            updateConsultation({
              consultationId,
              cancel: true,
            })
          }}
          key={key}
          consultation={c}
        />)}
      {page.length < 3 && <AppointmentButton onClick={handleNewAppointment} />}
    </div>
  )
}
const HANDLE_OLD_CONSULT = "HANDLE_OLD_CONSULT"
const CHANGE_DOCTOR = "CHANGE_DOCTOR"

interface CurrentConsultationProps extends ConsultationStore {
  firstname: string
}

function CurrentConsultations({ updateConsultation, consultations, doctors, firstname }: CurrentConsultationProps) {
  const ref = useRef<any>()
  const [hide, setHide] = useState({ left: true, right: consultations.length <= 2 })
  const [step, setStep] = useState(HANDLE_OLD_CONSULT)
  const [consultToChange, setConsultToChange] = useState<Consultation | null>()

  const pages = splitCarouselPages(consultations, 3)
  const navigate = useNavigate()

  useEffect(() => {
    if (consultations.length === 0) {
      navigate(SURVEY_PAGE)
    }
  }, [consultations, navigate])

  const pageLength = (() => {
    if (pages.length <= 0) return 0
    const lastPage = pages[pages.length - 1]
    if (lastPage.length === 3) {
      return pages.length + 1
    }
    return pages.length
  })()

  const onBeforeChange = (from: number, to: number): void => {
    let _hide = hide
    if (to <= 0) setHide((_hide = { ..._hide, left: true }))
    else setHide((_hide = { ..._hide, left: false }))
    if (to === pageLength - 1) setHide((_hide = { ..._hide, right: true }))
    else setHide((_hide = { ..._hide, right: false }))
  }

  const handleChangePratitioner = (consultationId: number) => {
    const consultation = consultations.find(c => c.id === consultationId)
    setConsultToChange(consultation)
    setStep(CHANGE_DOCTOR)
  }

  if (step === CHANGE_DOCTOR)
    return <PickADoctor
      previous={() => setStep(HANDLE_OLD_CONSULT)}
      doctors={doctors.filter(d => parseInt(d.id) !== (consultToChange?.chosen_doctor_id))}
      onSelectDoctor={(doctorId: string) => {
        updateConsultation({
          consultationId: consultToChange?.id as number,
          chosen_doctor_id: parseInt(doctorId),
        })
      }}
    />

  return (
    <div className="card-contenant max-large-card">
      <PreviousButton
        text={languages.logout}
        reset
      />
      <Title longTitle={true} value={`${firstname}, ${languages.modifyOrAddAnAppointment}`}></Title>
      <Row style={{ marginTop: "30px", alignItems: "center", justifyContent: "center" }}>
        <Col span={2}>
          <UiArrow side="left" onClick={() => ref?.current?.prev()} hidden={hide.left} />
        </Col>
        <Col span={20} style={{ alignItems: "center" }}>
          <Carousel
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            beforeChange={onBeforeChange}
            ref={ref}
            dots={false}
            initialSlide={0}
          >
            {pages.map((page, key) =>
              <PageRender
                key={key}
                page={page}
                updateConsultation={updateConsultation}
                handleChangePratitioner={handleChangePratitioner}
                handleNewAppointment={() => navigate(SURVEY_PAGE)}
              />
            )}
            {pageLength > pages.length &&
              <div style={{ display: "flex", gap: "30px", flexDirection: "row", alignSelf: "center" }}>
                <AppointmentButton onClick={() => navigate(SURVEY_PAGE)} />
              </div>
            }
          </Carousel>
        </Col>
        <Col span={2}>
          <UiArrow side="right" onClick={() => ref?.current?.next()} hidden={hide.right} />
        </Col>
      </Row>
    </div>)
}

export default connect(
  ({ clientConsultations, client }: { clientConsultations: Partial<ConsultationStore>, client: Partial<ClientStore> }) => {
    return (
      {
        ...clientConsultations,
        firstname: client.patient.firstname
      }
    )
  },
  (dispatch: any): Partial<ConsultationStore> => ({
    updateConsultation: (data: UpdateConsultationProps) => dispatch({ type: Types.UPDATE_CONSULTATION, payload: data }),
  }))(withHeaderAndFooter(withCard(CurrentConsultations)));